import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Spinner, Table } from "react-bootstrap";
import { errorNotification, successNotification } from "../utils/notification";
import useUsersData from '../store/UsersStore';
import apiService from "../service/api.service";
import Swal from "sweetalert2";
import useEditUserData from "../store/EditUserStore";


export default function UsersTable({
  options,
  getUserLogs
}) {
  const [isLoading, setIsLoading] = useState(false);

  const setEditUser = useEditUserData((state) => state.setSelectedData);


  const zs_setUsersData = useUsersData((state) => state.setSelectedData);
  const zs_getUsersData = useUsersData((state) => state.selectedData);


  const handleDelete = async (id) => {
    try {
      const acceptModal = await Swal.fire({
        title: 'Emin misin?',
        text: "Bu yapacağın işlem geri alınamaz.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet, silmek istiyorum!'
      });

      if (acceptModal.isConfirmed) {
        try {
          await apiService.deleteItem("admin/delete", id);
          // burada isteği at ve tamamla
          Swal.fire(
            'Başarılı!',
            'İlgili içerik silinmiştir.',
            'success'
          )
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata oluştu...',
            text: 'Something went wrong!',
          })
        }
      }
      const getData = await apiService.getByQuery("admin");
      zs_setUsersData(getData.data);
      successNotification("Kullanıcı başarıyla silinmiştir.");
    } catch (err) {
      errorNotification("Bir hata oluştu.");
    }
  }

  // eslint-disable-next-line default-case
  return (
    <>
      <Table
        bordered={options && options.bordered}
        borderless={false}
        size={options && options.size}
        hover={options && options.hover}
        className="mb-0"
      >

        <thead>
          <tr>
            <th>Kullanıcı - <span className="text-danger ">E-Posta</span></th>
          </tr>
        </thead>
        <tbody  >
          {zs_getUsersData.map((item, index) => (
            <tr style={{ verticalAlign: "middle" }} key={index} >
              <td>
                {item.fullname} <br /> <small><strong>{item.email} </strong></small>
              </td>


              <td style={{ textAlign: "end" }}>
                <ButtonGroup aria-label="Basic example">
                  <Button variant="light" className="btn-icon" size="sm" onClick={() => setEditUser(item)}>
                    <i className="ri-edit-circle-line"></i>
                  </Button>
                  <Button
                    variant={"primary"}
                    className="btn-icon"
                    size="sm"
                    onClick={() => getUserLogs(item.id)}
                  >
                    <i
                      className="ri-file-list-line"
                    ></i>
                  </Button>

                  <Button
                    variant="danger"
                    className="btn-icon"
                    size="sm"
                    onClick={() => handleDelete(item.id)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
    </>
  );
}
