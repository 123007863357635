import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import WaitingNewsTable from "./WaitingNewsTable.jsx";
import { Link } from "react-router-dom";





export default function WaitingNewsList() {

  const setIsSeenAll = async () => {
    alert("Tümünü okundu işaretleme işlemi henüz yapılmadı.")
  }

  const [triggerNewsList, setTriggerNewsList] = useState(false);

  const _handleTriggerNewsList = () => {
    setTriggerNewsList(!triggerNewsList);
  }


  return (
    <Col md="12" xl="8">

      <Card className="">
        <Card.Header>
          <div className="d-flex w-100 justify-content-between">
            <div className="title">
              Bekleyen Haberler
            </div>
            <div className="button">
              <Link onClick={() => setIsSeenAll()} className="mx-2">Tümünü Okundu İşaretle</Link>
              <Link onClick={() => _handleTriggerNewsList()}>Yenile</Link>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <WaitingNewsTable
            triggerNewsList={triggerNewsList}
            setIsSeenAll={setIsSeenAll}>

          </WaitingNewsTable>
        </Card.Body>
      </Card>
    </Col>
  );
}
