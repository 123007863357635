import React, { useEffect, useState } from 'react'
import { Alert, Card, Col, Row, } from 'react-bootstrap'
import useWaitingNewPreviewStore from '../../store/WaitingNewPreviewStore';
import { errorNotification } from '../../utils/notification';
import apiService from '../../service/api.service';
import parse from "html-react-parser";
import { Link } from 'react-router-dom';
import timeHelper from '../../utils/timeHelper';
import WaitingNewSettingsCard from './WaitingNewSettingsCard';


export default function WaitingNewsTable() {

  const zs_useWaitingNewPreviewStore = useWaitingNewPreviewStore((state) => state.setSelectedData);
  const zs_getWaitingNewPreviewStore = useWaitingNewPreviewStore((state) => state.selectedData);



  const [isLoading, setIsLoading] = useState(false);


  if (zs_getWaitingNewPreviewStore == null || isLoading) {
    return (
      <>

        <Col md="12" xl="4">
          <WaitingNewSettingsCard></WaitingNewSettingsCard>

          <Card className="card-one">
            <Card.Header>Haberi Önizle</Card.Header>
            <Card.Body>
              <Alert variant="primary" className="alert-outline d-flex align-items-center mb-2">
                <i className="ri-information-line"></i> Önizlemek için bir haber seçiniz.
              </Alert>
            </Card.Body>
          </Card>
        </Col>
      </>
    )
  }



  return (
    <>
      <Col md="12" xl="4">
        <WaitingNewSettingsCard></WaitingNewSettingsCard>

        <Card className="">
          <Card.Header>Haberi Önizle</Card.Header>

          <Card.Body>
            <Row>
              <Col md="12">
                <img src={zs_getWaitingNewPreviewStore.img_url} className='img-fluid' alt="" style={{ borderRadius: 10 }} />
              </Col>
            </Row>
            <Row>
              <Col md="12" className='mt-3'>
                <small>{timeHelper(zs_getWaitingNewPreviewStore.createdAt)}</small>
              </Col>
              <Col md="12" className='mt-3'>
                <h5>{zs_getWaitingNewPreviewStore.title}</h5>
              </Col>
              <Col md="12" className='mt-1'>
                <Link to={zs_getWaitingNewPreviewStore.source_url} target='_blank'><small>{zs_getWaitingNewPreviewStore.source_url}</small></Link>
              </Col>
            </Row>
            <Row>
              <Col md="12" className='mt-1'>
                <small>{zs_getWaitingNewPreviewStore.meta_desc}</small>
                <br />
              </Col>
            </Row>

            <Row>
              <Col md="12" className='mt-1'>
                <p>{parse(zs_getWaitingNewPreviewStore.description)}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  )
}
