import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Alert, Badge, Button, ButtonGroup, Card, Col, Form, Modal, Nav, Row, Spinner, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import Select, { components } from "react-select";
import apiService from "../../service/api.service";
import axios from "axios";
import { errorNotification, successNotification } from "../../utils/notification";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Ungrouppeds from "../../components/CustomerGroups/Ungrouppeds";
import toast from "react-hot-toast";
import timeHelper from "../../utils/timeHelper";

export default function Index() {
    const { itemid } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [callList, setCallList] = useState(false);
    const [busyCard, setBusyCard] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [selectOptions, setSelectOptions] = useState();
    const [selectedGroup, setSelectedGroup] = useState();


    // Modal
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = async () => {
        try {
            const response = await apiService.get(`groups/only-groups`);
            console.log(itemid);
            setSelectOptions(
                response.data
                    .filter(group => group.id !== data?.id) // Bu satırı ekleyin
                    .map((group) => {
                        return {
                            value: group.id,
                            label: `${group.title} - ${group.meta_desc}`,
                        };
                    })
            );
        } catch (e) {
            console.log(e)
            errorNotification("\n Bir hata oluştu konsola bakınız.")
        } finally {
            setModalShow(true)
        }
    };


    const breadcrumbData = [
        {
            title: "Müşteri Grupları",
            link: "/customers-groups",
        },
        {
            title: "Müşteri Grubunu Düzenle",
            link: null,
        },
    ];

    const handleCheckboxChange = (user, isChecked) => {
        if (isChecked) {
            setSelectedUsers(prevSelected => [...prevSelected, user]);
        } else {
            setSelectedUsers(prevSelected => prevSelected.filter(u => u.id !== user.id));
        }
    }

    const toggleSelectAll = (isChecked) => {
        if (isChecked) {
            setSelectedUsers(data.users);
        } else {
            setSelectedUsers([]);
        }
    }


    const handleTransferUser = async (user, fullname, group_id, callApi = true) => {
        try {
            setBusyCard(true);
            const response = await apiService.post(`user/set-group`, {
                group_id: group_id,
                customer_id: user,
            });
            console.log(response);
            if (!callApi) return successNotification(`${fullname} başarıyla taşındı!`);
        } catch (error) {
            console.log(error);
        } finally {
            setBusyCard(false);
            if (!callApi) return;
            await apiCall();
        }
    }

    const handleTransferUserTotal = async () => {
        try {
            if (selectedUsers.length <= 0) {
                console.log("0 veri var");
                errorNotification("lütfen seçim yapın")
            }
            if (!selectedGroup) return errorNotification("\n Lütfen taşınacak grubu seçiniz!");
            const transferUserstoGroup = async () => {
                for (const user of selectedUsers) {
                    await handleTransferUser(user.id, user.fullname, selectedGroup.value, false)
                }
            }

            toast.promise(
                transferUserstoGroup(),
                {
                    loading: () => {
                        return "Müşteriler taşınıyor."
                    },
                    success: () => {
                        setSelectedUsers([]);
                        apiCall();
                        return 'Müşteriler başarıyla gruptan taşınmıştır!';
                    },
                    error: (err) => {
                        return err.message;
                    },
                },
                {
                    position: "bottom-center"
                }
            ).then(() => {
                console.log("İşlem tamam, api call atalım..")
            }).catch((error) => {
                console.log(error);
            });


        } catch (error) {
            console.log(error);
        } finally {
            setBusyCard(false);

        }
    }


    const handleDropUser = async (user, fullname, callApi = true) => {
        try {
            setBusyCard(true);
            const response = await apiService.delete(`user/delete-group`, {
                customer_id: user,
            });
            console.log(response);
            if (!callApi) return successNotification(`${fullname} başarıyla gruptan silindi`);
        } catch (error) {
            console.log(error);
        } finally {
            setBusyCard(false);
            if (!callApi) return;
            await apiCall();
        }
    }



    const handleDropUserTotal = async () => {
        try {
            if (selectedUsers.length <= 0) {
                console.log("0 veri var");
                errorNotification("lütfen seçim yapın")
            }
            const deleteUsersfromGroups = async () => {
                for (const user of selectedUsers) {
                    await handleDropUser(user.id, user.fullname, false)
                }
            }

            toast.promise(
                deleteUsersfromGroups(),
                {
                    loading: () => {
                        return "Müşteriler gruptan siliniyor."
                    },
                    success: () => {
                        setSelectedUsers([]);
                        apiCall();
                        return 'Müşteriler başarıyla gruptan silindi!';
                    },
                    error: (err) => {
                        return err.message;
                    },
                },
                {
                    position: "bottom-center"
                }
            ).then(() => {
                console.log("İşlem tamam, api call atalım..")
            }).catch((error) => {
                console.log(error);
            });


        } catch (error) {
            console.log(error);
        } finally {
            setBusyCard(false);
            // apiCall()

        }
    }

    const openTransferModal = async () => {
        try {
            if (selectedUsers.length <= 0) return errorNotification("\n Lütfen seçim yapın!");
            await handleModalShow();
        } catch (err) {
            console.log(err);
        }
    }

    const apiCall = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.get(`groups/detail/${itemid}`);
            setData(response.data);
            setFormValues({
                title: response.data.title,
                meta_desc: response.data.meta_desc,
            })
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeleteGroup = async () => {
        try {
            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu grup silinecek ve içerisindeki kullanıcılar grupsuz kalacak!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Hayır, vazgeçtim',
                confirmButtonText: 'Evet, devam edelim'
            });
            if (!acceptModal.isConfirmed) {
                return;
            }
            await apiService.deleteItem(`groups/delete`, data.id);
            await navigate("/customers-groups")
            successNotification("Grup başarıyla silinmiştir, müşteriler genele aktarıldı.")
        } catch (error) {
            console.log(error);
        }
    }
    const [formValues, setFormValues] = useState({
        title: "",
        meta_desc: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
    };


    const editGroup = async () => {
        if (!formValues.title.trim()) {
            errorNotification("\n Grup adı girmeniz gerekiyor.")
            return;
        }
        try {
            await apiService.post(`groups/update/${data.id}`, formValues);
            await apiCall();
            setOpenEdit(false);
            return successNotification("\n Grup başarıyla düzenlendi!");

            // const promise = await toast.promise()
        } catch (error) {
            console.log(error);
            return errorNotification(`\n ${error.message}`)
        }

    };


    useEffect(() => {
        apiCall()
    }, []);

    useEffect(() => {
        console.log(selectedUsers);
    }, [selectedUsers])

    useEffect(() => {
        console.log("hook from child component")
        apiCall();
        setCallList(false);
    }, [callList])

    useEffect(() => {
        if (selectedUsers.length <= 0) {
            handleModalClose()
        }
    }, [selectedUsers])

    if (isLoading) return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={"Müşteri Grubunu Düzenle"}
                >
                </Beadcrumb>
                <Row>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Row>
                <Footer />

            </div>
        </React.Fragment >
    )
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex justify-content-between align-items-center">
                    <Beadcrumb
                        data={breadcrumbData}
                        title={"Müşteri Grubunu Düzenle"}
                    >

                    </Beadcrumb>
                    <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                        <Button variant="white" className="" onClick={() => setOpenEdit(!openEdit)}>Düzenle</Button>
                        <Button variant="danger" className="" onClick={handleDeleteGroup}>Grubu Sil</Button>
                    </div>
                </div>

                <Row>
                    <Col md="8">
                        <Card className="mb-2">
                            <Card.Body>
                                <Col xl>
                                    <h2 className="main-title fs-4 mb-1">{data?.title}</h2>
                                    <p className="text-secondary mb-0">{data?.meta_desc}</p>
                                </Col>
                            </Card.Body>
                        </Card>
                        <Card >
                            <Card.Header className="d-block">
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                    <div> Grupta Olan Müşteriler</div>
                                    <div> <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
                                        <Form.Control
                                            type="search"
                                            placeholder="Müşteri Ara"
                                            className="me-2"
                                            aria-label="Müşteri Ara"
                                            value={searchTerm}
                                            onChange={e => setSearchTerm(e.target.value)}
                                        />
                                    </Form>
                                    </div>
                                </div>

                            </Card.Header>

                            <Card.Body>
                                <Table className="mb-0" hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={data.users && (
                                                        selectedUsers.length <= 0 ?
                                                            false :
                                                            selectedUsers.length === data.users.length
                                                    )}
                                                    onChange={e => toggleSelectAll(e.target.checked)}
                                                />
                                            </th>
                                            <th scope="col">ID</th>
                                            <th scope="col">Müşteri Adı ve Soyadı</th>
                                            <th scope="col">E-Posta Adresi</th>
                                            <th scope="col">Telefon Numarası</th>
                                            <th scope="col">Durumu</th>
                                            <th scope="col">Onay</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.users?.filter(user =>
                                            user.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                            user.phone.includes(searchTerm)
                                        ).map((user, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={selectedUsers.some(u => u.id === user.id)}
                                                        onChange={e => handleCheckboxChange(user, e.target.checked)}
                                                    />
                                                </td>
                                                <th scope="row">{user.id}</th>
                                                <td><Link to={`/customers/detail/${user.id}`}>{user.fullname}</Link></td>
                                                <td>{user.email}</td>
                                                <td>{user.phone}</td>
                                                <td>
                                                    {user.is_active ? <Badge variant="success">Aktif</Badge> : <Badge className="bg-danger">Kapalı</Badge>}
                                                </td>
                                                <td>
                                                    {user.is_verified ? <Badge className="bg-dark">Onaylı</Badge> : <Badge className="bg-warning">Onaysız</Badge>}
                                                </td>
                                                <td>
                                                    <div className="text-danger" style={{ cursor: 'pointer' }} onClick={() => {
                                                        handleDropUser(user.id)
                                                    }}>Kaldır</div>
                                                </td>
                                                <td>
                                                    <div className="text-primary" style={{ cursor: 'pointer' }} onClick={() => {
                                                        setSelectedUsers([user])
                                                        handleModalShow()
                                                    }}>Değiştir</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </Card.Body>
                            <Card.Footer>
                                {selectedUsers.length > 0 && (
                                    <Row className="mt-3">
                                        <Col md="12">
                                            <div className="mb-1"><div>Toplam Seçili <span className="text-primary ff-numerals fw-bold">{selectedUsers.length}</span></div></div>
                                            <div className="mb-3">{selectedUsers.map((item) => (
                                                <small>{item.fullname},</small>
                                            ))}</div>
                                            <Button size="sm" variant="primary" disabled={busyCard} onClick={handleDropUserTotal} style={{ marginRight: 10 }}>Gruptan Kaldır</Button>
                                            <Button size="sm" variant="success" disabled={busyCard} onClick={openTransferModal} style={{ marginRight: 10 }}>Grubunu Değiştir</Button>
                                            <Button size="sm" variant="danger" disabled={busyCard} onClick={() => setSelectedUsers([])}>Vazgeç</Button>
                                        </Col>
                                    </Row>
                                )}
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md="4">
                        {openEdit &&
                            <Card className="mb-3">
                                <Card.Body>
                                    <div className="mb-3">
                                        <Form.Label >Grup Adı</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Grubun adı"
                                            name="title"
                                            value={formValues.title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Form.Label >Grup Açıklaması</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            type="text"
                                            placeholder="Kısa açıklama"
                                            name="meta_desc"
                                            value={formValues.meta_desc}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-between">
                                    <Button variant="secondary" size="sm" onClick={() => setOpenEdit(false)}>Vazgeç</Button>
                                    <Button variant="success" size="sm" onClick={editGroup}>Düzenle</Button>
                                </Card.Footer>
                            </Card>}
                        <Ungrouppeds currentGroup={data.id} setCallList={setCallList}></Ungrouppeds>
                    </Col>
                </Row>
                <Footer />
            </div>
            <Modal className="modal-event" show={modalShow} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Müşterilerin Grubunu Taşı</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <Table className="mb-0" hover size="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">Müşteri Adı ve Soyadı</th>
                                    <th scope="col">Telefon Numarası</th>

                                </tr>
                            </thead>
                            <tbody>
                                {selectedUsers.filter(user =>
                                    user.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    user.phone.includes(searchTerm)
                                ).map((user, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                checked={selectedUsers.some(u => u.id === user.id)}
                                                onChange={e => handleCheckboxChange(user, e.target.checked)}
                                            />
                                        </td>
                                        <td><Link to={`/customers/detail/${user.id}`}>{user.fullname}</Link></td>
                                        <td>{user.phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div>
                        <Select
                            options={selectOptions}
                            onChange={(selectedOption) => {
                                setSelectedGroup(selectedOption)
                                console.log(selectedOption)
                            }
                            }
                            value={selectedGroup}
                            isSearchable={true} placeholder="Taşınacak Grubu Seçiniz" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" className="btn-white" onClick={handleModalClose}>
                        Vazgeç
                    </Button>
                    <Button variant="success" onClick={handleTransferUserTotal}>
                        Taşı
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    );
}
