import React, { useState } from 'react'

import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap'
import { errorNotification, successNotification } from '../utils/notification';
import apiService from '../service/api.service';
import usePriceCategoryStore from '../store/CategoryStore';


export default function CategoriesSidebarSingleEdit({ item, handleEditCategory }) {
    const setCategoryRenewData = usePriceCategoryStore((state) => state.setSelectedData);

    const [sameCategory, setSameCategory] = useState(false);
    const [formValues, setFormValues] = useState({
        title: item?.title,
        description: item?.description,
    });


    const _handleSubmit = async (e) => {
        e.preventDefault();
        try {

            const emptyFields = [];

            if (emptyFields.title === "") {
                emptyFields.push("Kategori Adı");
            }
            if (emptyFields.description === "") {
                emptyFields.push("Kategori Açıklaması");
            }

            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;

            }
            const generatedData = {
                description: formValues.description,
                title: formValues.title,
            };

            await apiService.put("news-category/update", item.id, generatedData);
            const getData = await apiService.getByQuery("news-category/by-query");
            setCategoryRenewData(getData.data);
            setFormValues({
                title: "",
                description: ""
            });
            handleEditCategory();
            successNotification("Kategori başarıyla düzenlendi.");
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Card className='mb-2' style={{ border: "none", backgroundColor: "#fafafa", padding: 0, marginTop: 25 }}>
            <Card.Body style={{ padding: 5 }}>
                <h6 style={{ marginBottom: 0 }}>Mevcut kategoriyi düzenle</h6>
                {sameCategory && <div className="mt-3 mb-3">
                    <Alert variant='danger' dismissible={() => {
                        setSameCategory(false)
                    }}>
                        <h6> Bu veri zaten mevcut! </h6>
                        <code>
                            {JSON.stringify(sameCategory)}
                        </code>
                    </Alert>
                </div>}
                <Form className='mt-3 mb-3' onSubmit={(e) => e.preventDefault()}>
                    <Row>
                        <Col md="8">
                            <Form.Label >Kategori Adı</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Kategori "
                                value={formValues.title}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, title: e.target.value })
                                }
                            />
                        </Col>

                        <Col md="12 mt-2">
                            <Form.Label >Kategori Açıklaması</Form.Label>
                            <Form.Control
                                as="textarea" rows="3"
                                placeholder="Kategori açıklaması"
                                value={formValues.description}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, description: e.target.value })
                                }
                            />
                        </Col>



                        <Col md="12" className="mt-3">
                            <div className="d-flex justify-content-end">
                                <div className="mx-2">
                                    <Button variant="warning" disabled={false} size='sm' onClick={handleEditCategory} >
                                        Vazgeç
                                    </Button>
                                </div>
                                <Button variant="success" onClick={_handleSubmit} size='sm' disabled={false}>
                                    Güncelle
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}