import React, { useState } from 'react';
import { Button, Image, Spinner, Alert, Row, Col } from 'react-bootstrap';
import apiService from '../service/api.service';
const ImageUpload = ({ onUploadSuccess, imgUrl, isPdf = false }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [preview, setPreview] = useState(imgUrl || null);


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('image', file);

            setLoading(true);
            setError(null);
            try {
                const response = await apiService.uploadImage("upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                onUploadSuccess(response.data.image_url);
                setLoading(false);
            } catch (err) {
                setError("Error uploading the image");
                setLoading(false);
            }
        }
    };

    const handleFileChangePDF = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('bulletin', file);

            setLoading(true);
            setError(null);
            try {
                const response = await apiService.uploadImage("bulletins/upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                onUploadSuccess(response.data.image_url);
                setLoading(false);
            } catch (err) {
                setError("Error uploading the image");
                setLoading(false);
            }
        }
    };

    const handleRemove = () => {
        setPreview(null);
        onUploadSuccess(null);
    };
    if (isPdf === true) return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                <>
                    {preview ? (
                        <div className=''>
                            <Row>
                                <Col md="12">

                                    <div className='d-flex'>
                                        <div className=''>
                                            <Alert variant='success'>
                                                Bülten başarıyla yüklenmiştir
                                            </Alert>
                                            <Button variant="danger" size='sm' onClick={handleRemove}>Vazgeç/Sil</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <>
                            <Button variant="info" size='sm' as="label">
                                PDF Yükle
                                <input type="file" hidden onChange={handleFileChangePDF} />
                            </Button>
                        </>

                    )
                    }
                </>
            )}
        </div >
    )
    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                <>
                    {preview ? (
                        <div className=''>
                            <Row>
                                <Col md="12">

                                    <div className='d-flex'>
                                        <Image src={preview} thumbnail className='mb-3' style={{ marginRight: 20 }} width={255} />
                                        <div className=''>
                                            <Alert>
                                                Görsel başarıyla yüklenmiştir
                                            </Alert>
                                            <Button variant="danger" size='sm' onClick={handleRemove}>Vazgeç/Sil</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <>
                            <Button variant="primary" size='sm' as="label">
                                Görsel Yükle
                                <input type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </>

                    )
                    }
                </>
            )}
        </div >
    );
};

export default ImageUpload;
