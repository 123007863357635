/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Card, Col, Nav, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import useCustomerData from '../../store/CustomerStore';
import APIService from "../../service/api.service";
import { useParams } from "react-router-dom";
import WatchlistTable from "../../components/WatchlistTable";
import PushNotification from "../../components/Notification/PushNotification";
import ActivityLogsTable from "../../components/ActivityLogsTable";
import UserSettings from "../../components/UserSettings/UserSettings";
import UserDetailSettings from "../../components/UserSettings/UserDetailSettings";

export default function Index() {
    const { userid } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [watchlistData, setWatchlistData] = useState([]);

    const [activeTab, setActiveTab] = useState("watchlist");

    const [userLoaded, setUserLoaded] = useState(false);


    const zs_setCustomerData = useCustomerData((state) => state.setSelectedData);
    const zs_getCustomerData = useCustomerData((state) => state.selectedData);

    const [fcmToken, setFcmToken] = useState(null);


    const checkFCMToken = async () => {
        try {
            console.log(zs_getCustomerData);
            const response = await APIService.getDetailById("user/get-fcm-token", zs_getCustomerData.uuid);
            console.log(response, "from check fcm token")
            setFcmToken(response.data.token);
        } catch (error) {
            console.log(error);
        }
    }


    const getCustomerData = async () => {
        setIsLoading(true);
        setUserLoaded(false);
        try {
            const response = await APIService.getDetailById("user/detail", userid);
            zs_setCustomerData(response.data);
            setUserLoaded(true);
        } catch (error) {
            console.log(error);
            setUserLoaded(false);

        } finally {
            setIsLoading(false);
        }
    }


    const getWatchlistData = async () => {
        setIsLoading(true);
        setUserLoaded(false);

        try {
            const response = await APIService.getDetailById("user/watchlist", userid);
            setWatchlistData(response.data);
            setUserLoaded(true);

        } catch (error) {
            console.log(error);
            setUserLoaded(false);

        } finally {
            setIsLoading(false);
        }
    }


    const _handleChangeTab = (tab) => {
        setActiveTab(tab);
    }

    useEffect(() => {
        getCustomerData();
        getWatchlistData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (userLoaded && zs_getCustomerData) {
            checkFCMToken();
        }
    }, [userLoaded]);

    const breadcrumbData = [
        {
            title: "Müşteriler",
            link: "/customers",
        },
        {
            title: "Listele",
            link: "/customers",
        },
        {
            title: `Müşteri Detayları`,
            link: null,
        },
    ];


    if (isLoading || !zs_getCustomerData) {
        return (
            <>Yükleniyor</>
        )
    }



    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={zs_getCustomerData.fullname}
                >

                </Beadcrumb>
                <Row className="g-3 ">
                    <Col md="12" lg="12" >


                    </Col>


                </Row>

                <Row className="row-cols-sm-auto g-4 g-md-5 g-xl-4 g-xxl-5">
                    {[
                        {
                            "icon": "ri-user-line",
                            "text": zs_getCustomerData.fullname,
                            "label": "Adı Soyadı"
                        }, {
                            "icon": "ri-mail-line",
                            "text": zs_getCustomerData.email,
                            "label": "E-Posta Adresi"
                        }
                    ].map((profileItem, index) => (
                        <Col key={index}>
                            <div className="profile-item">
                                <i className={profileItem.icon}></i>
                                <div className="profile-item-body">
                                    <p>{profileItem.text}</p>
                                    <span>{profileItem.label}</span>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <Nav className="nav-line mt-5">
                            <Nav.Link onClick={() => _handleChangeTab("watchlist")} className={(activeTab === "watchlist" ? "active" : "")}>İzleme Listesi</Nav.Link>
                            {fcmToken && <Nav.Link onClick={() => _handleChangeTab("notification")} className={(activeTab === "notification" ? "active" : "")} >Bildirim İşlemleri</Nav.Link>}
                            <Nav.Link onClick={() => _handleChangeTab("user")} className={(activeTab === "user" ? "active" : "")}>Kullanıcı Ayarları</Nav.Link>
                            <Nav.Link onClick={() => _handleChangeTab("activityLogs")} className={(activeTab === "activityLogs" ? "active" : "")}>Kullanıcı Hareketleri</Nav.Link>
                        </Nav>
                    </Col>
                </Row>
                <Row>

                    {
                        // Aktif Taba hangisi ise ona göre render et
                        activeTab === "watchlist" && (
                            <Col md={12}>
                                <Card className="mt-3">
                                    <Card.Body>
                                        <WatchlistTable
                                            rows={watchlistData}
                                            options={{ hover: true, size: "sm", bordered: false }}
                                        ></WatchlistTable>
                                    </Card.Body>
                                </Card>
                            </Col>

                        )

                    }
                    {
                        // Aktif Taba hangisi ise ona göre render et
                        activeTab === "notification" && (
                            <>
                                <Col md={6}>
                                    <PushNotification></PushNotification>
                                </Col>
                            </>
                        )

                    }
                    {
                        // Aktif Taba hangisi ise ona göre render et
                        activeTab === "user" && (
                            <>
                                <Col md={6} lg={4}>
                                    <UserDetailSettings></UserDetailSettings>
                                </Col>
                                <Col md={6} lg={4}>
                                    <UserSettings></UserSettings>
                                </Col>
                            </>
                        )
                    }
                    {
                        // Aktif Taba hangisi ise ona göre render et
                        activeTab === "activityLogs" && (
                            <Col md={12}>
                                <Card className="mt-3">
                                    <Card.Body>
                                        <ActivityLogsTable
                                            uuid={zs_getCustomerData.uuid}
                                            options={{ hover: true, size: "sm", bordered: false }}
                                        ></ActivityLogsTable>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }
                </Row >


                <Footer />
            </div >
        </React.Fragment >
    );
}
