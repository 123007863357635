import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Col, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import PushNotification from "../../components/PushNotification";
import PushNotificationWithData from "../../components/PushNotificationWithData";

export default function Index() {
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

  }, [])


  const breadcrumbData = [

    {
      title: "Toplu Bildirim",
      link: null,
    },
  ];




  if (isLoading) {
    return (
      <>Yükleniyor</>
    )
  }


  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Beadcrumb
          data={breadcrumbData}
          title={"Toplu Bildirim"}
        ></Beadcrumb>
        <Row className="g-3 ">
          <Col md="12" xl="6">
            <PushNotification></PushNotification>
          </Col>
          <Col md="12" xl="6">
            <PushNotificationWithData></PushNotificationWithData>
          </Col>



        </Row>

        <Footer />
      </div>
    </React.Fragment >
  );
}
