import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, ButtonGroup, Card, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import { Link } from "react-router-dom";
import { errorNotification, successNotification } from "../../utils/notification";
import toast from "react-hot-toast";
import timeHelper from "../../utils/timeHelper";
import Swal from "sweetalert2";
import Select from "react-select";
import EasyEdit from 'react-easy-edit';


export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [options, setOptions] = useState([]);
    const [loadingFormArea, setLoadingFormArea] = useState(false);


    const [formValues, setFormValues] = useState({
        product_id: "",
        size: "",
        leverage: "",
    });




    const [exactly_currency, setExactly_currency] = useState("");


    const handleSaveItem = async (item, value, type) => {
        try {
            switch (type) {
                case "size":
                    await apiService.put(`prices-spesifications/update`, item, {
                        product_id: item,
                        size: value,
                    });
                    successNotification("Fiyat başarıyla düzenlenmiştir.")
                    return;
                    break;

                case "leverage":
                    await apiService.put(`prices-spesifications/update`, item, {
                        product_id: item,
                        leverage: value,
                    });
                    successNotification("Fiyat başarıyla düzenlenmiştir.")
                    return;
                    break;

                case "exactly_currency":
                    await apiService.put(`prices-spesifications/update`, item, {
                        product_id: item,
                        exactly_currency: value,
                    });
                    successNotification("Fiyat başarıyla düzenlenmiştir.")
                    return;
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.log(error);
            errorNotification(error.message)
        } finally {
            setLoadingFormArea(false)
        }
    }


    const cancel = () => { }


    const fetchApi = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.get(`prices-spesifications?page=1&limit=99999`);
            setData(response.data.data);
        } catch (error) {
            console.error("Error fetching popups:", error);
        } finally {
            setIsLoading(false);
        }
    }



    const fetchPrices = async () => {
        setIsLoading(true);

        try {
            const response = await apiService.get(`prices-spesifications/empty-prices?page=1&limit=99999`);
            console.log(response)
            setOptions(
                response.data
                    .map((price) => {
                        return {
                            value: price.id,
                            label: `${price.title}`,
                        };
                    })
            );
        } catch (e) {
            console.log(e)
            errorNotification("\n Bir hata oluştu konsola bakınız.")
        } finally {
            setIsLoading(false);
        }
    };


    const handleAddItem = async () => {
        try {

            const emptyFields = [];


            if (formValues.product_id === "") {
                emptyFields.push("Ürün");
            }

            if (formValues.size === "") {
                emptyFields.push("Sözleşme Büyüklüğü");
            }
            if (formValues.leverage === "") {
                emptyFields.push("Kaldıraç");
            }

            if (exactly_currency === "") {
                emptyFields.push("Parite Sonuç Kuru");
            }


            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;
            }
            const payload = {
                product_id: formValues.product_id,
                size: formValues.size,
                leverage: formValues.leverage,
                exactly_currency
            }
            await apiService.post(`prices-spesifications/new`, payload);
            successNotification("Fiyat başarıyla eklenmiştir.")
            await fetchPrices();
            await fetchApi();
        } catch (error) {
            console.log(error);
            errorNotification(error.message)
        } finally {
            setLoadingFormArea(false)
        }


    }
    // Input alanına girilen TEXT değerini büyük harflerle yazma fonksiyonu
    const handleFilterText = (e) => {
        if (typeof e.target.value === 'string') {
            return e.target.value.toUpperCase()
        } else {
            return '';
        }

    };

    // react-select kütüphanesi ile label ile gelen son 3 harfini al
    const handleFilterTextSelect = (e) => {
        setExactly_currency(e.label.slice(-3).toUpperCase());
    }

    const handleDeleteContent = async (itemid) => {
        try {
            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu içerik silinecektir.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Hayır, vazgeçtim',
                confirmButtonText: 'Evet, devam edelim'
            });
            if (!acceptModal.isConfirmed) {
                return;
            }
            await apiService.deleteItem(`prices-spesifications/delete`, itemid);
            successNotification("İçerik başarıyla silinmiştir.")
            fetchApi();
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchApi();
        fetchPrices();
    }, []);


    if (isLoading) return "Yükleniyor";

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Fiyat Özellikleri", link: "/prices-spesifications" },
                        { title: "Listele", link: null }
                    ]}
                    title={"Listele"}
                />
                <Row className="mb-3">
                    <Col lg="12" >
                        <Card>
                            <Card.Header>
                                Yeni Özellik Ekle
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col md="2">
                                        <Form.Label>
                                            Ürün Seçiniz
                                        </Form.Label>
                                        <Select
                                            options={options}
                                            isSearchable={true}
                                            onChange={async (e) => {
                                                handleFilterTextSelect(e);
                                                setFormValues({ ...formValues, product_id: e.value })
                                            }}
                                            placeholder="Ürün seçiniz"

                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Kaldıraç
                                        </Form.Label>
                                        <input type="number" className="form-control" placeholder="Kaldıraç (Ör. 200)"

                                            onChange={async (e) => {
                                                setFormValues({ ...formValues, leverage: e.target.value })
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label>
                                            Sözleşme Büyüklüğü
                                        </Form.Label>
                                        <input type="number" className="form-control" placeholder="Sözleşme Büyüklüğü (Ör. 100000)"
                                            onChange={async (e) => {
                                                setFormValues({ ...formValues, size: e.target.value })
                                            }}

                                        />
                                    </Col>
                                    <Col md="2">
                                        <Form.Label>
                                            Parite Sonuç Kuru
                                        </Form.Label>
                                        <input type="text" className="form-control" placeholder=" Parite Sonuç Kuru"
                                            value={exactly_currency}
                                            maxLength={3}
                                            onChange={(e) => {
                                                handleFilterText(e)
                                                setExactly_currency(e.target.value?.toUpperCase())
                                            }}

                                        />
                                        <small>Burada <span className="text-danger">TRY</span>,<span className="text-danger">USD</span> gibi değerler girebilirsiniz.</small>
                                    </Col>
                                </Row>

                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-end">
                                {loadingFormArea && <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="md"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="visually-hidden">Loading...</span>
                                </Button>}
                                {!loadingFormArea && <Button size="md" variant="success"
                                    onClick={() => { handleAddItem() }}>

                                    Ekle
                                </Button>}
                            </Card.Footer>
                        </Card>


                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="p-0">
                            <Card.Header> Ürün Özellikleri</Card.Header>
                            <Card.Body>
                                <Table className="mb-0 text-center text-sm" hover size="sm"  >
                                    <thead>
                                        <tr>
                                            <th>Ürün</th>
                                            <th>Parite Sonuç Kuru</th>
                                            <th>Kaldıraç Oranı</th>
                                            <th>Sözleşme Büyüklüğü</th>
                                            <th>Oluşturulma Tarihi</th>
                                            <th>Güncelleme</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.map((item, index) => (
                                            <tr key={index} style={{ verticalAlign: 'middle', fontSize: 14 }} >
                                                <td className="fw-semibold">{item.price?.product_name} <br /> <small className="text-muted">
                                                    {item.price?.title} {item.price?.id}</small> </td>
                                                <td className="fw-semibold">
                                                    <EasyEdit
                                                        type="text"
                                                        onSave={(value) => handleSaveItem(item.id, value, "exactly_currency")}
                                                        onCancel={cancel}
                                                        saveButtonLabel="Kaydet"
                                                        cancelButtonLabel="Vazgeç"
                                                        placeholder={item.exactly_currency}
                                                    />
                                                </td>
                                                <td className="fw-semibold">
                                                    <EasyEdit
                                                        type="number"
                                                        onSave={(value) => handleSaveItem(item.id, value, "leverage")}
                                                        onCancel={cancel}
                                                        saveButtonLabel="Kaydet"
                                                        cancelButtonLabel="Vazgeç"
                                                        placeholder={item.leverage}
                                                    />
                                                </td>
                                                <td>
                                                    <EasyEdit
                                                        type="number"
                                                        onSave={(value) => handleSaveItem(item.id, value, "size")}
                                                        onCancel={cancel}
                                                        saveButtonLabel="Kaydet"
                                                        cancelButtonLabel="Vazgeç"
                                                        placeholder={item.size}
                                                    />
                                                </td>
                                                <td>{timeHelper(item.createdAt)}</td>
                                                <td>{(item.updatedAt ? timeHelper(item.updatedAt) : "-")}</td>
                                                <td>
                                                    <ButtonGroup className=" text-end">

                                                        <Button size="md" variant="danger"
                                                            onClick={() => handleDeleteContent(item.id)}>
                                                            <i class="ri-delete-bin-5-line"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Footer />
            </div>
        </React.Fragment>
    );
}
