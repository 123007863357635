import React, { useEffect, useState } from 'react';
import apiService from '../service/api.service'; // API servisinizi buradan alın
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

export default function CustomerSearch() {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = async (query) => {
        setIsLoading(true);
        try {
            const response = await apiService.getByQuery(`user/by-search?text=${query}`);
            console.log(response.data);
            setOptions(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleOnSelect = (item) => {
        // Seçilen öğeyi işle
        window.location.replace('/customers/detail/' + item.id);
    }
    const filterBy = () => true;
    return (
        <div className=" me-auto ">
            <div style={{
                width: '400px',
            }}>
                <AsyncTypeahead
                    filterBy={filterBy}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey="fullname"
                    minLength={3}
                    onSearch={handleSearch}
                    highlightOnlyResult={true}
                    options={options}
                    delay={250}
                    onChange={(selected) => handleOnSelect(selected[0])}
                    placeholder="Kullanıcı arama (Ad Soyad, Telefon, E-posta)"
                    renderMenuItemChildren={(option) => (
                        <div onClick={() => handleOnSelect(option)}>
                            <div className="d-flex align-items-center">
                                <div className={"avatar-initial bg-secondary"} style={{
                                    height: '24px',
                                    marginRight: '10px',
                                    width: '24px',
                                }}>{option.fullname[0]}</div>
                                <div>
                                    <div>
                                        {option.fullname}
                                    </div>
                                    <div className='text-muted'>
                                        {option.phone} - <small>{option.email}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>

        </div>
    )
}
