import React from "react";

// Dashboard

import MainDashboard from "../dashboard/MainDashboard";

// Pages - Sayfalar Kullanılan ####################################################
import Analysis from "../pages/Analysis";
import News from "../pages/News";
import WaitingNews from "../pages/News/Waiting";
import Prices from "../pages/Prices";
import Users from "../pages/Users";
import Customers from "../pages/Customers";
import Banners from "../pages/Banners";
import BannersDetail from "../pages/Banners/detail";
import CustomerDetail from "../pages/Customers/detail";
import Notifications from "../pages/Notifications";
import Signals from "../pages/Signals";
import NewSignal from "../pages/Signals/new";
import EditSignal from "../pages/Signals/edit";

import CustomerGroups from "../pages/CustomerGroups";
import CustomerGroupsEdit from "../pages/CustomerGroups/edit";

import Announcements from "../pages/Announcements";
import NewAnnouncement from "../pages/Announcements/new";
import EditAnnouncement from "../pages/Announcements/edit";
import StoryAnnouncement from "../pages/Announcements/story";

import Popup from "../pages/Popup";
import NewPopup from "../pages/Popup/new";
import EditPopup from "../pages/Popup/edit";

import Bulletins from "../pages/Bulletins";
import NewBulletin from "../pages/Bulletins/new";
import EditBulletin from "../pages/Bulletins/edit";

import PricesSpesifications from "../pages/PricesSpesifications";
import ScheduledEvents from "../pages/ScheduledEvents";

const protectedRoutes = [
  // First route for opened

  { path: "/", element: <MainDashboard /> },
  { path: "/analysis", element: <Analysis /> },
  { path: "/news", element: <News /> },
  { path: "/waiting-news", element: <WaitingNews /> },
  { path: "/prices", element: <Prices /> },
  { path: "/users", element: <Users /> },
  { path: "/customers", element: <Customers /> },
  { path: "/banners", element: <Banners /> },
  { path: "/banners/category/:itemid", element: <BannersDetail /> },
  { path: "/customers/detail/:userid", element: <CustomerDetail /> },
  { path: "/notifications/new", element: <Notifications /> },
  { path: "/signals", element: <Signals /> },
  { path: "/signals/new", element: <NewSignal /> },
  { path: "/signals/edit/:itemid", element: <EditSignal /> },

  { path: "/customers-groups", element: <CustomerGroups /> },
  { path: "/customers-groups/edit/:itemid", element: <CustomerGroupsEdit /> },

  { path: "/announcements", element: <Announcements /> },
  { path: "/announcements/new", element: <NewAnnouncement /> },
  { path: "/announcements/edit/:itemid", element: <EditAnnouncement /> },
  { path: "/announcements/story/:itemid", element: <StoryAnnouncement /> },
  { path: "/popup", element: <Popup /> },
  { path: "/popup/new", element: <NewPopup /> },
  { path: "/popup/edit/:itemid", element: <EditPopup /> },

  { path: "/bulletins", element: <Bulletins /> },
  { path: "/bulletins/new", element: <NewBulletin /> },
  { path: "/bulletins/edit/:itemid", element: <EditBulletin /> },

  { path: "/prices-spesifications", element: <PricesSpesifications /> },
  { path: "/scheduled-notifications", element: <ScheduledEvents /> },
  // { path: "/bulletins/new", element: <NewBulletin /> },
  // { path: "/bulletins/edit/:itemid", element: <EditBulletin /> },
];

export default protectedRoutes;
