import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap'
import { errorNotification, successNotification } from '../utils/notification';
import apiService from '../service/api.service';
import Select from "react-select";
import Tags from "@yaireo/tagify/dist/react.tagify";
import usePriceCategoryStore from '../store/CategoryStore';


export default function NewPriceSidebar({ handleCategories, showCategories, _handleFilter }) {
    const [busyComponent, setBusyComponent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const categories = usePriceCategoryStore((state) => state.selectedData);


    // Yeni oluştur sidebar ? 
    const [createNew, setCreateNew] = useState(false);



    const [selectOptions, setSelectOptions] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const hiddenFileInput = useRef(null);
    const hiddenFileInput2 = useRef(null);
    const [apiImageUrl, setApiImageUrl] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);


    const [previewImage2, setPreviewImage2] = useState(null);
    const [apiImageUrl2, setApiImageUrl2] = useState(null);

    const handleCreateNew = () => setCreateNew(!createNew);
    const handleCancelNew = () => setCreateNew(false);



    const clearFormValues = () => {
        setFormValues(clearValues);
        setApiImageUrl(null);
        setApiImageUrl2(null);
        handleCancelNew();
    }

    const handlePhotoSubmit = async (event) => {
        event.preventDefault();
        if (selectedFile) {
            setLoading(true);
            const formData = new FormData();
            formData.append("image", selectedFile);
            try {
                const response = await apiService.uploadImage("upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                successNotification();
                setPreviewImage(null);
                setApiImageUrl(response.data.image_url);
            } catch (error) {
                errorNotification(
                    `\nDosya yüklenirken bir hata oluştu.\n${error.toString()}`
                );
            }
            setLoading(false);
        } else {
            errorNotification("\nLütfen görsel seçiniz.");
        }
    };

    const handlePhotoSubmit2 = async (event) => {
        event.preventDefault();
        if (selectedFile2) {
            setLoading2(true);
            const formData = new FormData();
            formData.append("image", selectedFile2);
            try {
                const response = await apiService.uploadImage("upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                successNotification();
                setPreviewImage2(null);
                setApiImageUrl2(response.data.image_url);
            } catch (error) {
                errorNotification(
                    `\nDosya yüklenirken bir hata oluştu.\n${error.toString()}`
                );
            }
            setLoading2(false);
        } else {
            errorNotification("\nLütfen görsel seçiniz.");
        }
    };

    const handleDeleteImage = async (event) => {
        event.preventDefault();
        if (apiImageUrl) {
            setLoading(true);
            try {
                await apiService.delete("upload/delete", {
                    path: apiImageUrl.split("/").pop(),
                });
                successNotification();
                setPreviewImage(null);
                setApiImageUrl(null);
            } catch (error) {
                errorNotification(
                    `\nGörsel silinirken bir hata oluştu.\n${error.toString()}`
                );
            } finally {
                setLoading(false);
            }
        } else {
            errorNotification("\nLütfen görsel seçiniz.");
        }
    };
    const handleDeleteImage2 = async (event) => {
        event.preventDefault();
        if (apiImageUrl2) {
            setLoading2(true);
            try {
                await apiService.delete("upload/delete", {
                    path: apiImageUrl2.split("/").pop(),
                });
                successNotification();
                setPreviewImage2(null);
                setApiImageUrl2(null);

            } catch (error) {
                errorNotification(
                    `\nGörsel silinirken bir hata oluştu.\n${error.toString()}`
                );
            } finally {
                setLoading2(false);
            }
        } else {
            errorNotification("\nLütfen görsel seçiniz.");
        }
    };

    const clearValues = {
        title: "",
        product_name: "",
        meta_currency_name: "",
        category_id: null,
        tags: null,
        img_url: null,
        cross_img_url: null,
    };

    const [formValues, setFormValues] = useState({
        title: "",
        product_name: "",
        meta_currency_name: "",
        category_id: null,
        tags: null,
        img_url: null,
        cross_img_url: null,
    });

    const handleSubmit = async () => {
        console.log(formValues, " from sidebar");
        const emptyFields = [];
        if (formValues.title === "") {
            emptyFields.push("Ürün Adı");
        }
        if (formValues.category === "") {
            emptyFields.push("Kategori");
        }
        if (formValues.summary === "") {
            emptyFields.push("Özet");
        }
        if (formValues.content === "") {
            emptyFields.push("Açıklama");
        }
        if (formValues.category === null) {
            emptyFields.push("Kategori");
        }
        if (formValues.type === null) {
            emptyFields.push("Analiz Durumu");
        }
        if (apiImageUrl === null) {
            emptyFields.push("Öne çıkan görsel");
        }
        // Diğer alanlar için de kontrolü yapabilirsiniz

        if (emptyFields.length > 0) {
            errorNotification(
                `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
            );
        } else {
            try {
                setBusyComponent(true);
                const generatedData = {
                    category_id: formValues.category_id.value,
                    title: formValues.title,
                    tags: formValues.tags,
                    product_name: formValues.product_name,
                    meta_currency_name: formValues.meta_currency_name,
                    img_url: apiImageUrl,
                    cross_img_url: apiImageUrl2 ? apiImageUrl2 : null,
                };

                await new Promise((resolve) => setTimeout(resolve, 2000));
                await apiService.post("prices/new", generatedData);
                setBusyComponent(false);
                successNotification();
                _handleFilter();
                clearFormValues();

            } catch (error) {
                errorNotification(`\nBir hata oluştu.\n${error.toString()}`);
            } finally {
                setBusyComponent(false);
            }
        }
    };



    const handleImgChange = (event) => {
        const fileUploaded = event.target.files[0];
        setSelectedFile(fileUploaded)
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(fileUploaded);
    }

    const handleImgChange2 = (event) => {
        const fileUploaded2 = event.target.files[0];
        setSelectedFile2(fileUploaded2)
        const reader2 = new FileReader();
        reader2.onloadend = () => {
            setPreviewImage2(reader2.result);
        };
        reader2.readAsDataURL(fileUploaded2);
    }


    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleClick2 = event => {
        hiddenFileInput2.current.click();
    };


    const renewCategories = () => {
        const CategoryOptions = categories;
        setSelectOptions(
            CategoryOptions.map((category) => {
                return {
                    value: category.id,
                    label: category.title,
                };
            })
        );
    }

    useEffect(() => {
        renewCategories();
    }, []);

    useEffect(() => {
        renewCategories();
        console.log("testingg..123")
    }, [categories]);


    return (
        <Card className=" mb-3 p-3" >
            <div className='d-flex justify-content-between align-items-center'>
                <h5 style={{ marginBottom: 0 }}>Yeni Fiyat Ekle</h5>
                <div>
                    {createNew ? <Button variant="danger" size='sm' onClick={handleCancelNew} >
                        Vazgeç
                    </Button> : <Button variant='success' size='sm' onClick={handleCreateNew}>
                        <i className="ri-add-fill"></i>
                    </Button>}

                </div>
            </div>
            <Card.Body style={!createNew ? { display: 'none', transition: '.2s all' } : { display: "block", transition: '.2s all' }} >
                {/* handleCreateNew */}
                <div style={!createNew ? { filter: "blur(3px)", pointerEvents: "none" } : { filter: "blur(0px)" }}>
                    <Row className="mb-3 d-flex px-0 py-2">
                        <Col md={6} className={`d-flex align-items-center `} >
                            {previewImage && !apiImageUrl && !loading && (
                                <>
                                    <div>
                                        <img src={previewImage ? previewImage : "https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick} />
                                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                                    </div>
                                    <div style={{ marginLeft: 15 }}>
                                        Önizleme
                                        <br />
                                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit}><strong>Görseli Yükle</strong></div>
                                    </div>
                                </>
                            )}

                            {apiImageUrl && !previewImage && !loading && (
                                <>
                                    <div>
                                        <img
                                            src={apiImageUrl ? apiImageUrl : "https://via.placeholder.com/65x65"}
                                            width={55}
                                            className="img-fluid"
                                            style={{ borderRadius: "50%", outline: '2px solid green' }} alt=""
                                            onClick={() => (errorNotification("Görseli silebilirsiniz."))} />
                                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        Resim yüklendi.
                                        <br />
                                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handleDeleteImage}><strong className="text-danger">Kaldır</strong></div>
                                    </div>
                                </>
                            )}

                            {!apiImageUrl && !previewImage && !loading && (
                                <>
                                    <div>
                                        <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick} />
                                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                                    </div>
                                    <div style={{ marginLeft: 15 }}>
                                        Önizleme
                                        <br />
                                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit}><strong>Görseli Yükle</strong></div>
                                    </div>
                                </>
                            )}

                            {loading && (
                                <>

                                    <div className="d-flex">
                                        <div>
                                            <Spinner animation="border" role="status" size="lg" >
                                                <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={() => (errorNotification("Lütfen bekleyiniz.."))} />
                                            </Spinner>
                                            <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                                        </div>
                                        <div style={{ marginLeft: 15 }}>
                                            Önizleme
                                            <br />
                                            <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit}><strong>Görseli Yükle</strong></div>
                                        </div>
                                    </div>

                                </>
                            )}



                        </Col>
                        <Col md={6} className={`d-flex align-items-center `} >
                            {previewImage2 && !apiImageUrl2 && !loading2 && (
                                <>
                                    <div>
                                        <img src={previewImage2 ? previewImage2 : "https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick2} />
                                        <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                                    </div>
                                    <div style={{ marginLeft: 15 }}>
                                        Önizleme
                                        <br />
                                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit2}><strong>Görseli Yükle</strong></div>
                                    </div>
                                </>
                            )}

                            {apiImageUrl2 && !previewImage2 && !loading2 && (
                                <>
                                    <div>
                                        <img
                                            src={apiImageUrl2 ? apiImageUrl2 : "https://via.placeholder.com/65x65"}
                                            width={55}
                                            className="img-fluid"
                                            style={{ borderRadius: "50%", outline: '2px solid green' }} alt=""
                                            onClick={() => (errorNotification("Görseli silebilirsiniz."))} />
                                        <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        Resim yüklendi.
                                        <br />
                                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handleDeleteImage2}><strong className="text-danger">Kaldır</strong></div>
                                    </div>
                                </>
                            )}

                            {!apiImageUrl2 && !previewImage2 && !loading2 && (
                                <>
                                    <div>
                                        <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick2} />
                                        <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                                    </div>
                                    <div style={{ marginLeft: 15 }}>
                                        Önizleme
                                        <br />
                                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit2}><strong>Görseli Yükle</strong></div>
                                    </div>
                                </>
                            )}


                            {loading2 && (
                                <>

                                    <div className="d-flex">
                                        <div>
                                            <Spinner animation="border" role="status" size="lg" >
                                                <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={() => (errorNotification("Lütfen bekleyiniz.."))} />
                                            </Spinner>
                                            <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                                        </div>
                                        <div style={{ marginLeft: 15 }}>
                                            Önizleme
                                            <br />
                                            <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit2}><strong>Görseli Yükle</strong></div>
                                        </div>
                                    </div>

                                </>
                            )}



                        </Col>
                    </Row>
                    <Row className='py-1'>

                        <Col md={8}>
                            <div className="mb-3">
                                <Form.Label>Ürün Adı</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ürün Adı"
                                    value={formValues.title}
                                    onChange={(e) =>
                                        setFormValues({ ...formValues, title: e.target.value })
                                    }
                                />
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="mb-3">
                                <Form.Label>Kategori</Form.Label>
                                <Select
                                    options={selectOptions}
                                    placeholder=" Seçiniz"
                                    defaultValue={null}
                                    value={formValues.category_id}
                                    isClearable={true}
                                    onChange={(selectedOption) =>
                                        setFormValues({ ...formValues, category_id: selectedOption })
                                    }
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label>Çapraz Adı <strong>(Ör: BTCUSD)</strong></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ürün Adı"
                                    value={formValues.product_name}
                                    onChange={(e) =>
                                        setFormValues({ ...formValues, product_name: e.target.value })

                                    }
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Form.Label>Meta Adı (Ör: BTCUSD<strong className="text-danger">v</strong>)</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="MetaTrader'daki adı."
                                    value={formValues.meta_currency_name}
                                    onChange={(e) =>
                                        setFormValues({ ...formValues, meta_currency_name: e.target.value })
                                    }
                                />
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className="mb-3">
                                <Form.Label>Etiketler</Form.Label>
                                <Tags
                                    className="w-100"
                                    placeholder="Alakalı etiketleri giriniz."
                                    value={formValues.tags}
                                    onChange={(values) => {
                                        let tags = values.detail.tagify
                                            .getCleanValue()
                                            .map((value) => Object.values(value).toString());
                                        setFormValues((formValues) => ({
                                            ...formValues,
                                            tags: tags.join(","),
                                        }));
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row >
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex justify-content-between'>

                            <div>
                                {apiImageUrl && (
                                    <Button variant="primary" onClick={handleSubmit}>
                                        Yayınla
                                    </Button>
                                )}
                            </div>
                        </div>

                    </div>

                </Row>


            </Card.Body>
        </Card>
    )
}
