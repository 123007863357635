import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { Card, Col, Placeholder, Row } from "react-bootstrap";
import apiService from '../service/api.service';
import Twilio from "../components/Dashboard/Twilio";
import RegisteredUserTotal from "../components/Dashboard/RegisteredUser";
import VerifiedUser from "../components/Dashboard/VerifiedUser";
import LiveSignals from "../components/Dashboard/LiveSignals";
import CustomersTable from "../components/CustomersTable";
import PushNotificationWithData from "../components/PushNotificationWithData";

export default function WebsiteAnalytics() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);



  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };


  switchSkin(skin);


  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">İzleme Paneli</Link>
              </li>
            </ol>
            <h4 className="main-title mb-0">Hoş geldin!</h4>
          </div>
        </div>


        <Row className="g-3 ">
          <Col md="6" xl="3">
            <Twilio></Twilio>
          </Col>
          <Col md="6" xl="3">
            <RegisteredUserTotal></RegisteredUserTotal>
          </Col>
          <Col md="6" xl="3">
            <VerifiedUser></VerifiedUser>
          </Col>
        </Row>


        <Row className="g-3 mt-3">
          <Col md="6" xl="6">
            <LiveSignals></LiveSignals>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
