import React, { useEffect, useState } from 'react'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'
import apiService from '../../service/api.service';

export default function Twilio() {

    const [twilioBalance, setTwilioBalance] = useState(false);

    const getTwilioBalance = async () => {
        try {
            const result = await apiService.get("reports/twilio-balance");
            console.log(result);
            setTwilioBalance(result.data);
        } catch (error) {
            console.log(error);
            setTwilioBalance(false);
        }
    }

    useEffect(() => {
        getTwilioBalance();
    }, []);
    return (
        <Card className="card-one card">
            <Card.Body>
                <Row>
                    {!twilioBalance ? (
                        <Col xs="12">
                            <Placeholder xs="6" animation="glow"></Placeholder>
                            <div>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs="7" />
                                    <Placeholder xs="4" />
                                    <Placeholder xs="6" />
                                    <Placeholder xs="8" />
                                </Placeholder>
                            </div>
                        </Col>
                    ) :
                        <Col xs="12">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="service-image">
                                    <img src="https://www.twilio.com/docs/static/dist/img/559bc7cf.svg" className="img-fluid mb-2" alt="" />
                                </div>
                                <div>
                                    <h4 className="card-value mb-1">{twilioBalance.balance} {twilioBalance.currency}</h4>
                                </div>
                            </div>
                            <span className="d-block text-muted fs-11 ff-secondary lh-4">Kalan bakiye bilgisidir, anlık olarak verilir.</span>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}
