import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Spinner, Table } from "react-bootstrap";
import timeHelper from "../utils/timeHelper"
import useStore from '../store/ModalStore';
import usePriceCategoryStore from "../store/CategoryStore";
import { errorNotification } from "../utils/notification";




export default function PricesTable({
  rows,
  options,
  onPromotedChange,
  onDeleteTrigger,
  isRenewData,
  handleStatusChange
}) {

  const setShowModal = useStore((state) => state.setShowModal);
  const setSelectedData = useStore((state) => state.setSelectedData);
  const [state, setState] = useState(rows);
  const categories = usePriceCategoryStore((state) => state.selectedData);

  const handleEditClick = (rowData) => {
    setSelectedData(rowData); // Seçilen veriyi Zustand üzerinden güncelleyin
    setShowModal(true); // Zustand üzerinden modal'ı açın
  };




  const getCategory = (categoryId) => {
    return categories.find((item) => item.id === categoryId) || "-";
  };


  // eslint-disable-next-line default-case
  return (
    <>
      {isRenewData && <Spinner animation="border" role="status" style={{ zIndex: 99, position: 'absolute', top: "45%", left: "45%" }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>}
      <Table
        bordered={options && options.bordered}
        borderless={false}
        size={options && options.size}
        hover={options && options.hover}
        className="mb-0"
        style={isRenewData ? { filter: "blur(5px)", pointerEvents: "none", position: 'relative', transition: '0.2s all', zIndex: 10 } : { filter: "blur(0px)", transition: '0.2s all' }}
      >

        <thead>
          <tr>
            <th>Logo</th>
            <th>Çapraz - <span className="text-danger ">Meta Adı</span></th>
            <th>Kategori</th>
            <th>Etiketler</th>
            <th>Öne Çıkan?</th>
          </tr>
        </thead>
        <tbody  >
          {rows.map((item, index) => (
            <tr className={`${item.is_promoted && "table-success"}`} style={{ verticalAlign: "middle" }} key={index} >
              <td >
                {item.img_url && (
                  <img width={25} src={item.img_url} alt="Prices" />
                )}
                {item.cross_img_url && (
                  <img width={25} src={item.cross_img_url} alt="Prices" />
                )}
              </td>

              <td>
                {item.product_name} - <span className="text-danger "><small>{item.meta_currency_name}</small></span> <br /> Ürün Adı: <strong>{item.title} </strong>
              </td>
              <td>
                <u style={{ textDecoration: '2px solid underline green' }}>
                  {getCategory(item.category_id).title}
                </u>
              </td>

              <td>{item.tags}</td>
              <td width={100}>
                {item.is_promoted ? (
                  <Badge bg="primary">ÖNE ÇIKAN <i className="ri-rocket-fill text-warning"></i></Badge>
                ) : (
                  <Badge bg="light">NORMAL</Badge>
                )}
              </td>
              <td style={{ textAlign: "end" }}>
                <ButtonGroup aria-label="Basic example">
                  <Button variant="light" className="btn-icon" size="sm" onClick={() => handleEditClick(item)}>
                    <i className="ri-edit-circle-line"></i>
                  </Button>
                  <Button
                    variant={item.is_active ? "success" : "warning"}
                    className="btn-icon"
                    size="sm"
                    onClick={() => {

                      handleStatusChange(index)
                    }}
                  >
                    <i
                      className={
                        item.is_active ? "ri-eye-off-line" : "ri-eye-line"
                      }
                    ></i>
                  </Button>
                  <Button
                    variant={item.is_promoted ? "primary" : "outline-primary"}
                    className="btn-icon"
                    size="sm"
                    onClick={() => {
                      if (item.is_active) {
                        onPromotedChange(index);
                      } else {
                        errorNotification("Öne çıkarılan ürünler aktif olmalıdır.")
                      }
                    }}
                  >
                    <i
                      className={
                        item.is_promoted ? "ri-rocket-line" : "ri-rocket-line"
                      }
                    ></i>
                  </Button>

                  <Button
                    variant="danger"
                    className="btn-icon"
                    size="sm"
                    onClick={() => onDeleteTrigger(index)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
    </>
  );
}
