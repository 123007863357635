import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, ButtonGroup, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import CustomerGroupsCard from "../../components/CustomerGroups/CustomerGroupsCard";
import { Link } from "react-router-dom";
import { errorNotification, successNotification } from "../../utils/notification";
import toast from "react-hot-toast";
import timeHelper from "../../utils/timeHelper";
import Swal from "sweetalert2";

export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchAnnouncements = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.get(`announcements/all`);
            setData(response.data.data);
        } catch (error) {
            console.error("Error fetching announcements:", error);
        } finally {
            setIsLoading(false);
        }
    }


    const setStatusforContent = async (itemid, is_active) => {
        try {

            const response = await apiService.put(`announcements/update`, itemid, {
                is_active: !is_active,
            });

            fetchAnnouncements();
            if (response.is_active) {
                successNotification(`\n İçerik başarıyla aktif edilmiştir.`)
            } else {
                successNotification(`\n İçerik başarıyla deaktif edilmiştir. `)

            }
        } catch (error) {
            console.log(error);
            errorNotification(`\n ${error.message}`)
        } finally {
            console.log("finals")
        }
    }

    const handleDeleteContent = async (itemid) => {
        try {
            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu içerik silinecektir.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Hayır, vazgeçtim',
                confirmButtonText: 'Evet, devam edelim'
            });
            if (!acceptModal.isConfirmed) {
                return;
            }
            await apiService.deleteItem(`announcements/delete`, itemid);
            successNotification("İçerik başarıyla silinmiştir.")
            fetchAnnouncements();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchAnnouncements();
    }, []);


    if (isLoading) return "Yükleniyor";

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Duyuru & Kampanya", link: "/announcements" },
                        { title: "Listele", link: null }
                    ]}
                    title={"Listele"}
                />
                <Row className="mb-3">
                    <Col>
                        <Link to={"/announcements/new"} className="btn btn-success" >Yeni Oluştur</Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Table className="mb-0" hover >
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>ID</th>
                                            <th>Hikaye</th>
                                            <th>Başlık</th>
                                            <th>Tipi</th>
                                            <th>Açıklama</th>
                                            <th>Oluşturulma</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index} style={{ verticalAlign: 'middle', }} >
                                                <td>
                                                    {item.is_promoted ?
                                                        <Link style={{
                                                            background: 'blue',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            margin: "auto",
                                                            marginRight: 10,
                                                            borderRadius: 3,
                                                            cursor: 'pointer'
                                                        }}
                                                            className="shadow-sm"
                                                            to={`/announcements/story/${item.id}`}
                                                        >
                                                            <i class="ri-rocket-2-line" style={{ padding: 3, color: '#fff', fontWeight: 'bold' }}></i>
                                                        </Link>
                                                        :
                                                        <Link style={{
                                                            background: 'transparent',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            margin: "auto",
                                                            marginRight: 10,
                                                            borderRadius: 3,
                                                            cursor: 'pointer'
                                                        }}
                                                            className="shadow-sm"
                                                            to={`/announcements/story/${item.id}`}
                                                        >
                                                            <i class="ri-rocket-2-line" style={{ padding: 3, color: '#313131' }}></i>
                                                        </Link>
                                                    }
                                                </td>
                                                <th scope="row d-flex align-items-center justify-content-center w-100">
                                                    <Badge className="bg-light">
                                                        #{item.id}
                                                    </Badge>
                                                    <img src={item.img_url} alt="" width={45} style={{ borderRadius: 5, marginLeft: 10 }} />

                                                </th>
                                                <td>
                                                    <div style={{ position: 'relative' }}>
                                                        <img src={item.story_img} alt="" width={45} style={{ marginLeft: 10 }} />
                                                        <div style={{ position: 'absolute', bottom: "-5px" }}>
                                                            <img src={item.small_img} alt="" className="shadow-sm" width={45} style={{ borderRadius: "100%", marginLeft: 10, border: '3px solid #fff' }} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="fw-semibold">{item.title}</td>
                                                <td >
                                                    {item.type === "KAMPANYA" ?
                                                        <Badge className="bg-success">
                                                            {item.type}
                                                        </Badge> :
                                                        <Badge>
                                                            {item.type}
                                                        </Badge>
                                                    }
                                                </td>
                                                <td >{item.description.slice(0, 35)}</td>
                                                <td>{timeHelper(item.createdAt)}</td>
                                                <td>
                                                    <ButtonGroup>
                                                        <Link to={`/announcements/edit/${item.id}`} className="btn btn-primary btn-md">
                                                            <i class="ri-settings-2-line"></i>
                                                        </Link>
                                                        <Button size="md" onClick={() => setStatusforContent(item.id, item.is_active)} variant={item.is_active ? "danger" : "success"}>
                                                            {item.is_active ? <i className="ri-eye-off-line"></i> : <i className="ri-eye-line"></i>}
                                                        </Button>
                                                        <Button size="md" variant="danger"
                                                            onClick={() => handleDeleteContent(item.id)}>
                                                            <i class="ri-delete-bin-5-line"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Footer />
            </div>
        </React.Fragment>
    );
}
