import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Alert, Badge, Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import Select, { components } from "react-select";
import apiService from "../../service/api.service";
import axios from "axios";
import { errorNotification, successNotification } from "../../utils/notification";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function Index() {
    const { itemid } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [sendNotification, setSendNotification] = useState(true);

    const [processResult, setProcessResult] = useState(null);

    const getItemData = async () => {
        try {
            const response = await apiService.get(`signals/detail/${itemid}`);
            console.log(response.data);
            setData(response.data);
            setFormValues({
                product_id: response.data.data.product_id,
                type: response.data.data.type,
                entry_price: response.data.data.entry_price,
                tp: response.data.data.tp,
                sl: response.data.data.sl
            })
            setProcessResult(response.data.resultData.result)
        } catch (error) {
            console.log(error);
        }
    }

    const [formValues, setFormValues] = useState({
        product_id: "",
        product_name: "",
        entry_price: "",
        tp: "",
        sl: "",
        type: "",
        notification_title: "",
        notification_body: "",
    });


    const getPriceList = async () => {
        try {
            setIsLoading(true);
            const result = await apiService.get("prices/by-query");
            setPriceList(result.data);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getPriceList();
        getItemData();
    }, []);

    const options = priceList.map(item => ({
        value: item.id,
        label: item.meta_currency_name,
        data: item
    }));


    const CustomOption = ({ data, innerProps }) => (
        <div {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: 10, paddingBottom: 5 }}>
            <div style={{ marginRight: 10, display: 'flex' }}>
                <img src={data.data.img_url} alt={data.data.product_name} width="20" height="20" />
                {data.data.cross_img_url && <img src={data.data.cross_img_url} alt={data.label} width="20" height="20" />}
            </div>
            <div>
                <div>
                    {data.data.product_name}
                </div>
                <div className="text-muted">
                    {data.data.title}
                </div>
            </div>
        </div>
    );

    const selectOptions = [
        {
            value: "BUY",
            label: "BUY"
        },
        {
            value: "SELL",
            label: "SELL"
        }
    ]

    const selectOptionsResult = [
        {
            value: "SUCCESS",
            label: "BAŞARILI"
        },
        {
            value: "FAILED",
            label: "BAŞARISIZ"
        }
    ]


    const breadcrumbData = [
        {
            title: "Sinyaller",
            link: "/signals",
        },
        {
            title: "Listele",
            link: "/signals",
        },
        {
            title: "Düzenle",
            link: null,
        },
    ];

    if (isLoading) {
        return "Yükleniyor";
    }



    const handleGetItemPrice = async () => {
        if (formValues.product_name === "" || formValues.product_name === undefined || formValues.product_name === null) {
            errorNotification("Lütfen ürün seçiniz.")
        }
        const getLivePrice = await axios.get(`https://socket.gkmapp.com/price?parity=${formValues.product_name}`);
        if (getLivePrice.data.data) {
            setFormValues({ ...formValues, entry_price: getLivePrice.data.data.P });
        } else {
            setFormValues({ ...formValues, entry_price: "Bulunamadı!" });
        }
    }

    const handleChangeResult = async (value) => {
        try {
            const response = await apiService.put("signals/update-signal-result", data.data.id, {
                result: value === "SUCCESS" ? "SUCCESS" : "FAILED",
                result_type: value === "SUCCESS" ? "TP" : "SL",
            })
            console.log(response);
            setProcessResult(value);
            successNotification("\nİşlem durumu başarıyla değiştirilmiştir")
        } catch (e) {
            console.log(e);
            errorNotification("\nBir sorun oluştu konsolu kontrol ediniz.")

        }
    }


    const handleSubmit = async () => {
        try {

            /// Form verilerini kontrol et.
            const emptyFields = [];
            if (formValues.entry_price === "") {
                emptyFields.push("Giriş fiyatı");
            }
            if (sendNotification && formValues.notification_title === "") {
                emptyFields.push("Bildirim başlığı");
            }
            if (formValues.product_id === "") {
                emptyFields.push("Ürün");
            }

            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;
            }


            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu sinyal yayınlanacaktır.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Yayınla!'
            });
            if (acceptModal.isConfirmed) {

                const generatedData = {
                    entry_price: formValues.entry_price,
                    product_id: formValues.product_id,
                    sl: formValues.sl,
                    tp: formValues.tp,
                    type: formValues.type.value ? formValues.type.value : formValues.type,
                    is_active: true,
                }
                try {
                    await apiService.put("signals/update", data.data.id, generatedData);
                    navigate('/signals', { replace: true });
                    successNotification(`\n Başarıyla analiz yayınlandı`);
                } catch (e) {
                    console.log(e);
                    errorNotification(`Sinyal paylaşılırken bir hata oluştu!`)
                }
            }
        } catch (err) {
            console.log(err);

        } finally {
            console.log("form datası hazır.")
        }
    }

    return (
        <React.Fragment>
            {console.log(data)}
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={"Sinyal İçeriğini Düzenle"}
                >

                </Beadcrumb>
                <Form onSubmit={(e) => e.preventDefault}>
                    <Row className="g-3 ">
                        <Col md="8">
                            <Card className="card-one">
                                <Card.Header>
                                    <Card.Title as="h6">Sinyal İçeriğini Düzenle</Card.Title>
                                    <Nav className="nav-icon nav-icon-sm ms-auto">
                                        <Nav.Link><i className="ri-refresh-line"></i></Nav.Link>
                                        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Form.Label >Ürün Seçiniz</Form.Label>
                                                <Select
                                                    options={options}
                                                    components={{
                                                        Option: CustomOption,
                                                    }}
                                                    value={
                                                        formValues.product_id ? (
                                                            options.find(
                                                                (option) => option.value === formValues.product_id
                                                            )
                                                        ) : null
                                                    }
                                                    onChange={async (e) => {
                                                        setFormValues({ ...formValues, product_id: e.value, product_name: e.label })
                                                    }}
                                                    placeholder="Ürün seçiniz"

                                                />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Form.Label >Tip Seçiniz</Form.Label>
                                                <Select
                                                    options={selectOptions}
                                                    placeholder="BUY / SELL"
                                                    defaultValue={null}
                                                    value={
                                                        formValues.type ? (
                                                            selectOptions.find(
                                                                (option) => option.value === formValues.type
                                                            )
                                                        ) : null
                                                    }
                                                    onChange={(selectedOption) =>
                                                        setFormValues({ ...formValues, type: selectedOption })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Giriş Fiyatı <small style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={handleGetItemPrice} >Fiyatı Çek</small></Form.Label>
                                                <Form.Control type="text" placeholder="Giriş Fiyatı" value={formValues.entry_price} onChange={(e) => {
                                                    setFormValues({ ...formValues, entry_price: e.target.value })
                                                }} />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Kar Al</Form.Label>
                                                <Form.Control type="text"
                                                    value={formValues.tp}
                                                    onChange={(e) => {
                                                        setFormValues({ ...formValues, tp: e.target.value })
                                                    }}
                                                    placeholder="Kar Al Noktası" />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Form.Label htmlFor="">Zarar Durdur</Form.Label>
                                                <Form.Control type="text"
                                                    value={formValues.sl}
                                                    onChange={(e) => {
                                                        setFormValues({ ...formValues, sl: e.target.value })
                                                    }}
                                                    placeholder="Zarar Durdur Noktası" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-end text-end">
                                    <Button variant="warning" onClick={handleSubmit}>İçeriği Güncelle</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                        {data.resultData && (
                            <Col md="4">
                                <Card className="card-one">
                                    <Card.Header>
                                        <Card.Title as="h6"> İşlem Sonucu</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            {processResult === "SUCCESS" ?
                                                <Col md="12">
                                                    <Alert variant="success">
                                                        Bu işlem kar al ile sonuçlanmıştır.  <br /><strong>İşlem Başarılı</strong>
                                                    </Alert>
                                                </Col>
                                                :
                                                <Col md="12">
                                                    <Alert variant="danger">
                                                        Bu işlem zarar durdur ile sonuçlanmıştır.  <br /><strong>İşlem Başarısız</strong>
                                                    </Alert>
                                                </Col>
                                            }
                                            <Col>
                                                <div className="mb-3">
                                                    <Form.Label >İşlem Sonucu</Form.Label>
                                                    <Select
                                                        options={selectOptionsResult}
                                                        placeholder="BAŞARILI / BAŞARISIZ"
                                                        value={
                                                            processResult ? (
                                                                selectOptionsResult.find(
                                                                    (option) => option.value === processResult
                                                                )
                                                            ) : null

                                                        }
                                                        onChange={(selectedOption) => {
                                                            handleChangeResult(selectedOption.value)
                                                        }
                                                        }
                                                    />
                                                </div></Col>
                                        </Row>
                                    </Card.Body>

                                </Card>
                            </Col>
                        )}

                    </Row>
                </Form>
                <Footer />
            </div>
        </React.Fragment >
    );
}
