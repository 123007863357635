import React, { useState } from 'react'
import useCustomerData from '../../store/CustomerStore';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { errorNotification, successNotification } from '../../utils/notification';
import apiService from '../../service/api.service';



export default function PushNotification() {

    const zs_setCustomerData = useCustomerData((state) => state.setSelectedData);
    const zs_getCustomerData = useCustomerData((state) => state.selectedData);

    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        title: "",
        body: ""
    });



    const _handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {

            const emptyFields = [];

            if (emptyFields.title === "") {
                emptyFields.push("Başlık");
            }

            if (emptyFields.description === "") {
                emptyFields.push("İçerik");
            }

            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;

            }


            formValues.userid = zs_getCustomerData.id;
            await apiService.post("notification/pushNotification", formValues);
            setFormValues({
                title: "",
                body: ""
            });
            successNotification("Bildirim başarıyla gönderildi.");
        } catch (err) {
            console.log(err);
            errorNotification(err.response.data.message);
        } finally {
            setIsLoading(false);
        }
    }



    return (
        <Card className="mt-3">
            <Card.Header>
                Bildirim Gönder
            </Card.Header>
            <Card.Body>
                <div>
                    <Alert variant={"light"} className='alert-outline' style={{ display: 'flex', alignItems: 'center' }}>
                        <i class="ri-notification-line"></i> Bu alandan telefonlara push notification gönderebilirsiniz.
                    </Alert>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-3">
                            <Form.Label>Başlık</Form.Label>
                            <Form.Control type="text" placeholder="Bildirim Başlığı"
                                value={formValues.title}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, title: e.target.value })
                                } />
                        </div>
                        <div className="mb-3">
                            <Form.Label >İçerik</Form.Label>
                            <Form.Control as="textarea" rows="2" placeholder="Bildirimi içeriği giriniz.."
                                value={formValues.body}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, body: e.target.value })
                                }></Form.Control>
                        </div>
                        <div className="d-flex justify-content-end">
                            {isLoading && <Button variant="light" disabled={true} className="mx-2"> Gönderiliyor..</Button>}
                            {!isLoading && <Button variant="primary" onClick={_handleSubmit} className="mx-2"> Gönder</Button>}
                        </div>
                    </form>
                </div>
            </Card.Body>
        </Card >
    )
}
