import { useEffect } from "react";
import { Badge, Button, ButtonGroup, Card, Col, Form, Modal, Row, Nav, Spinner, Table } from "react-bootstrap";
import apiService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import Swal from "sweetalert2";

export default function RenderTables({ data, fetchApi }) {

    function convertToReadableDate(timestamp) {
        const { _seconds } = timestamp;
        const date = new Date(_seconds * 1000);
        return date.toLocaleDateString("tr-TR") + " " + date.toLocaleTimeString("tr-TR");
    }

    const deleteEventTrigger = async (id) => {

        Swal.fire({
            title: 'Etkinliği iptal etmek istediğinize emin misiniz?',
            text: "Bu işlem geri alınamaz!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Evet, iptal et!',
            cancelButtonText: 'Hayır'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await apiService.deleteRequest(`firebase/delete-event/${id}`);
                    console.log(response)
                    successNotification("Etkinlik başarıyla iptal edildi");
                    fetchApi();
                }
                catch (error) {
                    console.log(error)
                    errorNotification("Etkinlik iptal edilirken bir hata oluştu");
                }
            }
        })
    }


    return (
        <div>
            {Object.keys(data).map(day => (
                <div key={day} className="card card-one mb-3">
                    <div className="card-body">
                        <h3>{day}</h3>
                        <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Zamanlanmış Tarih</th>
                                    <th>Bildirim</th>
                                    <th>Durum</th>
                                    <th>Detay</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data[day].map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="date w-100">
                                                {convertToReadableDate(item.scheduledTime)}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="title">
                                                {item.title}
                                            </div>
                                            <div className="description w-75" >
                                                <small className="text-muted " > {item.body} </small>
                                            </div>
                                        </td>
                                        <td>
                                            {item.sent ?
                                                <Badge bg="success"> Gönderildi </Badge>
                                                :
                                                <Badge bg="warning"> Bekliyor </Badge>
                                            }
                                        </td>
                                        <td>
                                            <div className="id">
                                                <small>{item.id}</small>
                                            </div>
                                            <code>{JSON.stringify(item.data)}</code>
                                        </td>
                                        <td>
                                            <Button variant="danger" onClick={() => deleteEventTrigger(item.id)} className="btn-icon"><i className="ri-delete-bin-6-line"></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            ))}
        </div>
    );
}
