import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Col, Form, Modal, Row, Nav, Spinner, Table } from "react-bootstrap";
import apiService from "../../service/api.service";
import ReactDatePicker from "react-datepicker";
import tr from 'date-fns/locale/tr';
import "../../assets/css/react-datepicker.min.css";
import { errorNotification, successNotification } from "../../utils/notification";
import timeHelper from "../../utils/timeHelper";
import Select from "react-select";

// function formatDate(date) {

//     console.log(date);
//     const year = date.getFullYear();
//     const month = date.getMonth() + 1;
//     const day = date.getDate();


//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     const seconds = "00";


//     return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours}:${minutes}:${seconds}`;
// }


export default function CreateScheduledNotification({
    fetchApi
}) {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [contentData, setContentData] = useState(null);
    const [selectedContentData, setSelectedContentData] = useState(null);
    const [selectedContentTitle, setSelectedContentTitle] = useState(null);

    const [isLoadingContent, setIsLoadingContent] = useState("Seçiniz");
    const [startDate, setStartDate] = useState(new Date());


    const [formData, setFormData] = useState({
        title: "",
        body: "",
        data: null,
        scheduledTime: null,
    });

    const selectOptions = [
        { value: null, label: 'Düz Bildirim' },
        { value: 'youtube', label: 'Youtube' },
        { value: 'duyurular', label: 'Kampanya & Duyurular' },
        { value: 'analiz', label: 'Analiz' },
        { value: 'sinyal', label: 'Sinyal' },
        { value: 'bulten', label: 'Bülten' },
    ];


    const handleSelectContentData = (obj) => {
        setSelectedContentData(obj);
        if (selectedContentTitle === null) {
            setFormData({
                ...formData, data: {
                    duz: "1"
                }
            });
        }
        setFormData({
            ...formData, data: {
                [selectedContentTitle]: obj.value.toString()
            }
        });
    }

    const handleDataChange = async (obj) => {
        setFormData({
            title: "",
            body: "",
            data: null,
            scheduledTime: null,
        })
        switch (obj.value) {
            case null:
                setContentData(null)
                setSelectedContentData(null)

                setFormData({
                    title: "",
                    body: "",
                    data: null,
                    scheduledTime: null,
                })
                break;

            case "youtube":
                setIsLoadingContent("Yükleniyor..");
                setContentData(null)
                setSelectedContentData(null)
                setSelectedContentTitle(obj.value)
                setFormData({
                    title: "Canlı Yayındayız 🔴",
                    body: "Günlük piyasa incelemeleri ve teknik analizler Serdar Keskin ile şimdi canlı yayında!",
                    data: {
                        youtube: "1"
                    },
                    scheduledTime: null,
                })
                setIsLoadingContent("Seçiniz");
                break;

            case "analiz":
                setIsLoadingContent("Yükleniyor..");
                setContentData(null)
                setSelectedContentData(null)
                setSelectedContentTitle(obj.value)
                const analyse = await apiService.get("analyses?limit=50");
                setContentData(analyse.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.title}`,
                    };
                }));
                setIsLoadingContent("Seçiniz");
                break;

            case "duyurular":
                setIsLoadingContent("Yükleniyor..");
                setContentData(null)
                setSelectedContentData(null)
                setSelectedContentTitle(obj.value)
                const announcements = await apiService.get("announcements?limit=9999999");
                setContentData(announcements.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.title}`,
                    };
                }));
                setIsLoadingContent("Seçiniz");
                break;

            case "sinyal":
                setIsLoadingContent("Yükleniyor..");
                setContentData(null)
                setSelectedContentData(null)
                setSelectedContentTitle(obj.value)


                const signals = await apiService.get("signals/get-live?limit=50");
                const resultedSignals = await apiService.get("signals/get-resulted?limit=50");
                const liveOptions = signals.data.data.map(item => ({
                    value: item.id,
                    label: `${item.priceDetail.product_name} Sinyali - ${timeHelper(item.createdAt)}`
                }));

                const resultedOptions = resultedSignals.data.data.map(item => ({
                    value: item.id,
                    label: `${item.priceDetail.product_name} Sinyali - ${timeHelper(item.createdAt)}`
                }));

                const groupedOptions = [
                    {
                        label: "CANLI SİNYALLER",
                        options: liveOptions
                    },
                    {
                        label: "GEÇMİŞ SİNYALLER",
                        options: resultedOptions
                    }
                ];

                setContentData(groupedOptions);
                setIsLoadingContent("Seçiniz");
                break;

            case "bulten":
                setIsLoadingContent("Yükleniyor..");
                setContentData(null)
                setSelectedContentData(null)
                setSelectedContentTitle(obj.value)

                const bulletins = await apiService.get("bulletins");
                console.log(bulletins)
                setContentData(bulletins.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: `${item.title}`,
                    };
                }));
                setIsLoadingContent("Seçiniz");
                break;
            default:
                break;
        }

    }

    const handleSubmitEvent = async () => {
        if (!formData.title || !formData.body || !startDate) {
            errorNotification("Lütfen tüm alanları doldurunuz");
            return;
        }

        const { title, body, data } = formData;


        if (data === null) {
            const postData = {
                title,
                body,
                data: {
                    duz: "1"
                },
                scheduledTime: startDate
            }

            try {
                const response = await apiService.post(`firebase/schedule-event`, postData);
                console.log(response)
                successNotification("Etkinlik başarıyla planlandı");
                fetchApi();
                setFormData({
                    title: "",
                    body: "",
                    data: null,
                    scheduledTime: null,
                })
                setIsLoadingContent("Yükleniyor..");
                setContentData(null)
                setSelectedContentData(null)
                return;
            }
            catch (error) {
                errorNotification("Etkinlik planlanırken bir hata oluştu");
            }
            return;
        }

        const postData = {
            title,
            body,
            data,
            scheduledTime: startDate
        }

        try {
            const response = await apiService.post(`firebase/schedule-event`, postData);
            console.log(response)
            successNotification("Etkinlik başarıyla planlandı");
            fetchApi();
            setFormData({
                title: "",
                body: "",
                data: null,
                scheduledTime: null,

            })
            setIsLoadingContent("Yükleniyor..");
            setContentData(null)
            setSelectedContentData(null)
        }
        catch (error) {
            errorNotification("Etkinlik planlanırken bir hata oluştu");
        }


    }
    return (
        <Card className="">
            <Card.Header>
                Yeni Etkinlik Planla
            </Card.Header>
            <Card.Body>
                <div className="row">

                    <div className="col-md-12">
                        <div className="mb-3">
                            <Form.Label>Başlık</Form.Label>
                            <Form.Control placeholder="Bildirim Başlığı"
                                value={formData.title}
                                onChange={(e) => {
                                    setFormData({ ...formData, title: e.target.value })
                                }}
                            ></Form.Control>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="mb-3">
                            <Form.Label>İçerik</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Bildirim İçeriği"
                                value={formData.body}
                                onChange={(e) => {
                                    setFormData({ ...formData, body: e.target.value })
                                }}
                            ></Form.Control>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-3">
                            <Form.Label>İçerik</Form.Label>
                            <Select
                                options={selectOptions}

                                onChange={(e) => handleDataChange(e)}
                                isSearchable={false} />

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3">
                            <Form.Label>İçerik</Form.Label>
                            <Select
                                isDisabled={!contentData}
                                placeholder={isLoadingContent}
                                value={selectedContentData}
                                onChange={(e) => handleSelectContentData(e)}
                                options={contentData ? contentData : []}
                                isSearchable={false} />
                        </div>
                    </div>
                </div>
                <div className="mb-2">
                    <Form.Label>Planlanan Tarih</Form.Label>
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        locale={tr}
                        timeIntervals={5}
                        timeCaption="Saat/Dakika"
                        className="form-control"
                        dateFormat="yyyy-MM-dd HH:mm"
                    />
                </div>
                <div className="mt-3 d-flex justify-content-end">
                    <Button size="" className="w-50" onClick={handleSubmitEvent}>
                        Etkinliği Kaydet
                    </Button>
                </div>
            </Card.Body>
        </Card>
    )
}
