import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, ButtonGroup, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import useCustomersData from '../../store/CustomersStore';
import useEditUserData from '../../store/EditUserStore';
import APIService from "../../service/api.service";
import CustomersTable from "../../components/CustomersTable";
import CustomersFilteredData from "../../components/CustomersFilteredData";
import CustomersFilter from "../../components/CustomersFilter";
import apiService from "../../service/api.service";
import { useDebounce } from 'use-debounce';
import { errorNotification } from "../../utils/notification";


export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [isHandleNewUser, setIsHandleNewUser] = useState(false);
    const [logsData, setLogsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isRenewData, setIsRenewData] = useState(false);
    const [show, setShow] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);




    const [resultActive, setResultActive] = useState(false);
    const [filteredOpened, setFilteredOpened] = useState(false);

    const [data, setData] = useState(null);


    const haveEditUser = useEditUserData((state) => state.selectedData);

    const zs_setCustomersData = useCustomersData((state) => state.setSelectedData);
    const zs_getCustomersData = useCustomersData((state) => state.selectedData);


    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleNewUser = () => setShow(true);
    const handleClose = () => setShow(false);

    const getCustomersData = async () => {
        setIsLoading(true);
        setIsRenewData(true);
        try {
            const response = await APIService.getByLimit("user", currentPage, null);
            zs_setCustomersData(response.data.data);
            setData(response.data);
            setIsRenewData(false);

        } catch (error) {
            console.log(error);
            setIsRenewData(false);

        } finally {
            setIsLoading(false);
            setIsRenewData(false);

        }
    }




    const renderPageButtons = () => {
        const buttons = [];
        const totalPages = data?.totalPages;
        const buffer = 1; // Seçili olan sayfanın etrafında kaç sayfa göstermek istediğinizi belirtir.

        let previousWasDots = false;

        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || (i >= currentPage - buffer && i <= currentPage + buffer)) {
                buttons.push(
                    <Button key={i} onClick={() => handlePageClick(i)} active={currentPage === i} type="button" variant="primary">
                        {i}
                    </Button>
                );
                previousWasDots = false;
            } else if (!previousWasDots) {
                buttons.push(
                    <Button key={i} type="button" variant="primary">
                        ...
                    </Button>
                );
                previousWasDots = true;
            }
        }
        return buttons;
    };

    const [formValues, setFormValues] = useState({
        fullname: "",
        email: "",
        password: "",
        repassword: "",
        phone: "",
        country_code: 90,
    });

    const handleEmailValidate = async (email) => {
        // E-posta adresi kontrolü burada gerçekleştirilecek
        // Geçerli e-posta adresi kontrolü örneği
        const isValidEmail = /^\S+@\S+\.\S+$/.test(email);
        if (isValidEmail) {
            const response = await apiService.getByQuery("user/by-query", `email=${email}`);
            if (response.data.length > 0) {
                console.log("Bu email zaten kayıtlı.")
                setEmailError("Bu email zaten kayıtlı.")
                return;
            } else {
                setEmailError(false);
            }
        } else {
            setEmailError('Geçerli bir e-posta adresi girin.');
        }
    };

    const [debouncedEmail] = useDebounce(formValues.email, 250);

    useEffect(() => {
        getCustomersData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);
    useEffect(() => {
        getCustomersData();
        setIsRenewData(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRenewData]);
    useEffect(() => {
        handleEmailValidate(debouncedEmail);
    }, [debouncedEmail]);


    const breadcrumbData = [
        {
            title: "Müşteriler",
            link: "/customers",
        },
        {
            title: "Listele",
            link: null,
        },
    ];


    if (isLoading || !zs_getCustomersData) {
        return (
            <>
                {isRenewData && <Spinner animation="border" role="status" style={{ zIndex: 99, position: 'absolute', top: "50%", left: "50%" }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            </>
        )
    }



    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await apiService.post("user/create-customer", formValues);
            console.log(result);
            setShow(false);
            setCurrentPage(1);
            await getCustomersData();
        } catch (error) {
            errorNotification(error.message);
        } finally {
            console.log("final")
        }
    }

    const validatePhoneNumber = async (inputValue) => {
        // Buradaki numaranın içerisinde sadece sayısal harfler yer alabilir, eğer farklı bir şey varsa çıkar
        let phone = inputValue.replace(/[^0-9]/g, '');
        console.log(phone);

        // Eğer numara "5" ile başlamıyorsa hata ver
        if (phone.charAt(0) !== "5") {
            // alert("Telefon numarası 5 ile başlamalıdır.")
            console.log("Telefon numarası 5 ile başlamalıdır.")
            setPhoneError("Telefon numarası 5 ile başlamalıdır.")
            return;
        }

        // Eğer numara 10 haneden küçükse veya büyükse hata ver

        if (phone.length < 10 || phone.length > 10) {
            // alert("Telefon numarası 10 haneli olmalıdır.")
            console.log("Telefon numarası 10 haneli olmalıdır.")
            setPhoneError("Telefon numarası 10 haneli olmalıdır. Örn: 5338889911")
        }
        else {
            console.log("doğru")
            const response = await apiService.getByQuery("user/by-query", `phone=${phone}`);

            if (response.data.length > 0) {
                // alert("Bu telefon numarası zaten kayıtlı.")
                console.log("Bu telefon numarası zaten kayıtlı.")
                setPhoneError("Bu telefon numarası zaten kayıtlı.")
                return;
            } else {
                setPhoneError(false);
            }
        }
    };

    const createPassword = () => {
        // 8-16 karakter arası random şifre oluştur
        let password = Math.random().toString(36).slice(-8);
        setFormValues({ ...formValues, password: password, repassword: password })
        setPasswordError(false);
    }
    const checkPassword = (password) => {
        if (password.length < 8 || password.length > 16) {
            // alert("Şifre 8-16 karakter arası olmalıdır.")
            console.log("Şifre 8-16 karakter arası olmalıdır.")
            setPasswordError("Şifre 8-16 karakter arası olmalıdır.")
        } else {
            setPasswordError(false)
        }
    }


    return (
        <React.Fragment>
            {console.log(currentPage, " sayfa numarası")}
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={"Müşteriler"}
                >

                </Beadcrumb>
                <Row className="g-3">
                    <Col md="12" lg="12" >
                        <h4>İşlemler</h4>
                        <hr />
                    </Col>

                    <Col md="12" lg="12" >
                        <Button variant="outline-dark" onClick={handleNewUser} className="mb-3" > <i class="ri-add-line" style={{ verticalAlign: 'middle' }}></i> Yeni Kullanıcı Oluştur</Button>
                    </Col>
                    <hr />
                </Row>
                <Modal show={show} size="lg" onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Yeni Kullanıcı Oluştur</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Form onSubmit={e => e.preventDefault()}>
                                <Row className="">
                                    <Col md={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label>Müşteri Adı ve Soyadı</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Ahmet Yılmaz"
                                                value={formValues.fullname}
                                                onChange={(e) =>
                                                    setFormValues({ ...formValues, fullname: e.target.value })

                                                }
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label>Müşteri E-Posta Adresi</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="ahmet@gmail.com"
                                                value={formValues.email}
                                                onChange={(e) => {
                                                    setFormValues({ ...formValues, email: e.target.value })
                                                }
                                                }
                                                isValid={!emailError && formValues.email}
                                                isInvalid={emailError}
                                            />
                                            {emailError && <div>
                                                <small className="text-danger">{emailError}</small>
                                            </div>}
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label className="d-flex justify-content-between">
                                                <div>
                                                    Müşteri Şifresi
                                                </div>
                                                <div className="text-primary" style={{
                                                    cursor: 'pointer',
                                                    fontWeight: 'bold'
                                                }}
                                                    onClick={() => {
                                                        createPassword()
                                                    }}>
                                                    Oluştur
                                                </div>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="8-16 karater arası"
                                                value={formValues.password}
                                                onChange={(e) => {
                                                    setFormValues({ ...formValues, password: e.target.value })
                                                    checkPassword(e.target.value)
                                                }

                                                }
                                                isInvalid={passwordError}
                                                isValid={!passwordError && formValues.password}
                                            />
                                            {passwordError && <div>
                                                <small className="text-danger">{passwordError}</small>
                                            </div>}
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label>Müşteri Şifresi Tekrarı</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="8-16 karater arası"
                                                value={formValues.repassword}
                                                onChange={(e) =>
                                                    setFormValues({ ...formValues, repassword: e.target.value })

                                                }
                                                isValid={!passwordError && formValues.repassword && formValues.password === formValues.repassword}
                                                isInvalid={passwordError || formValues.repassword && formValues.password !== formValues.repassword}
                                            />

                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label>Müşteri Telefon Numarası</Form.Label>
                                            <Form.Control
                                                type="phone"
                                                placeholder="(5XXXXXXXXX) formatta giriniz."
                                                maxLength={10}
                                                minLength={10}
                                                value={formValues.phone}
                                                onChange={(e) => {
                                                    setFormValues({ ...formValues, phone: e.target.value })
                                                    validatePhoneNumber(e.target.value)
                                                }
                                                }
                                                isInvalid={phoneError}
                                                isValid={!phoneError && formValues.phone}
                                            />
                                            {phoneError && <div>
                                                <small className="text-danger">{phoneError}</small>
                                            </div>}
                                        </div>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <div className="mb-3">
                                            <Form.Label style={{ opacity: 0 }}>Müşteriyi Oluştur</Form.Label>
                                            <div className="w-100">
                                                <Button variant="primary" onClick={e => handleFormSubmit(e)} className="w-100">
                                                    Oluştur
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
                <Row className="g-3 ">
                    <Col md="12" xl="12">
                        <Card className=" mb-3" style={{
                            border: resultActive ? "1px solid #ade8f4" : "1px solid #feedca"
                        }}>
                            <Card.Header className="d-flex justify-content-between align-items-center" style={{
                                backgroundColor: '#ade8f4'
                            }}>
                                <div>
                                    <strong>Filtreleme Seçenekleri</strong>
                                </div>
                                <div>
                                    <Button
                                        variant={filteredOpened ? "danger" : "success"}
                                        onClick={() => setFilteredOpened(!filteredOpened)}
                                    >
                                        {filteredOpened ? "Kapat" : "Aç"}
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body style={{
                                display: filteredOpened ? "block" : "none",
                                margin: '0px',
                                padding: '10px'
                            }}>
                                <CustomersFilter setIsRenewData={setIsRenewData} isRenewData={isRenewData} setResultActive={setResultActive} ></CustomersFilter>
                                <hr />
                                <CustomersFilteredData setIsRenewData={setIsRenewData} isRenewData={isRenewData}></CustomersFilteredData>
                            </Card.Body>
                        </Card>

                    </Col>

                    <Col md="12" lg="12" >
                        <h3>Müşteriler</h3>
                    </Col>

                    <Col md="12" lg="12" >
                        <Card>
                            <Card.Header className="d-flex justify-content-end">
                                <ButtonGroup className="me-2" aria-label="First group">
                                    {renderPageButtons()}
                                </ButtonGroup>
                            </Card.Header>
                            <Card.Body style={{
                                margin: '0px',
                                padding: '10px'
                            }}>

                                <CustomersTable show={true} setIsRenewData={setIsRenewData} isRenewData={isRenewData}></CustomersTable>
                            </Card.Body>
                        </Card>
                    </Col>


                </Row>

                <Footer />
            </div>
        </React.Fragment >
    );
}
