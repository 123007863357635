import { create } from "zustand";

const useStore = create((set) => ({
  showModal: false,
  selectedData: null,
  setShowModal: (show) => set(() => ({ showModal: show })),
  setSelectedData: (data) => set(() => ({ selectedData: data })),
}));

export default useStore;
