import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, ButtonGroup, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import { Link } from "react-router-dom";
import { errorNotification, successNotification } from "../../utils/notification";
import toast from "react-hot-toast";
import timeHelper from "../../utils/timeHelper";
import Swal from "sweetalert2";

export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchPopups = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.get(`bulletins`);
            setData(response.data.data);
        } catch (error) {
            console.error("Error fetching popups:", error);
        } finally {
            setIsLoading(false);
        }
    }


    const setStatusforContent = async (itemid, is_active) => {
        try {


            const response = await apiService.put(`notification/update`, itemid, {
                is_active: !is_active,
            });

            fetchPopups();
            if (response.is_active) {
                successNotification(`\n İçerik başarıyla aktif edilmiştir.`)
            } else {
                successNotification(`\n İçerik başarıyla deaktif edilmiştir. `)

            }
        } catch (error) {
            console.log(error);
            errorNotification(`\n ${error.message}`)
        } finally {
            console.log("finals")
        }
    }

    const handleDeleteContent = async (itemid) => {
        try {
            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu içerik silinecektir.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Hayır, vazgeçtim',
                confirmButtonText: 'Evet, devam edelim'
            });
            if (!acceptModal.isConfirmed) {
                return;
            }
            await apiService.deleteItem(`bulletins/delete`, itemid);
            successNotification("İçerik başarıyla silinmiştir.")
            fetchPopups();
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchPopups();
    }, []);


    if (isLoading) return "Yükleniyor";

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Bülten Yönetimi", link: "/bulletins" },
                        { title: "Listele", link: null }
                    ]}
                    title={"Listele"}
                />
                <Row className="mb-3">
                    <Col>
                        <Link to={"/bulletins/new"} className="btn btn-success" >Yeni Oluştur</Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Table className="mb-0" hover >
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Başlık</th>
                                            <th>Bülten</th>
                                            <th>Açıklama</th>
                                            <th>Oluşturulma Tarihi</th>
                                            <th>Güncelleme</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index} style={{ verticalAlign: 'middle', }} >

                                                <th scope="row d-flex align-items-center justify-content-center w-100">
                                                    <Badge className="bg-light">
                                                        #{item.id}
                                                    </Badge>

                                                </th>

                                                <td className="fw-semibold">{item.title}</td>
                                                <td className="fw-semibold"><Link to={item.url} target="_blank">Bülteni Görüntüle</Link></td>
                                                <td >{item.short_desc}</td>
                                                <td>{timeHelper(item.createdAt)}</td>
                                                <td>{timeHelper(item.updatedAt)}</td>
                                                <td>
                                                    <ButtonGroup className=" text-end">
                                                        <Link to={`/bulletins/edit/${item.id}`} className="btn btn-primary btn-md">
                                                            <i class="ri-search-2-line"></i>
                                                        </Link>
                                                        <Button size="md" variant="danger"
                                                            onClick={() => handleDeleteContent(item.id)}>
                                                            <i class="ri-delete-bin-5-line"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Footer />
            </div>
        </React.Fragment>
    );
}
