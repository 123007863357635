import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import apiService from "../service/api.service";
import { errorNotification, successNotification } from "../utils/notification";

import "react-quill/dist/quill.snow.css";
import usePriceCategoryStore from "../store/CategoryStore";

export default function EditPriceModal({
  data,
  closeModal,
  handleRenewData,
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isActive, setIsActive] = useState(data?.is_active);
  const [busyModal, setBusyModal] = useState(false);
  const categories = usePriceCategoryStore((state) => state.selectedData);


  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const [apiImageUrl, setApiImageUrl] = useState(data?.img_url);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);


  const [previewImage2, setPreviewImage2] = useState(null);
  const [apiImageUrl2, setApiImageUrl2] = useState(data.cross_img_url ? data.cross_img_url : null);





  const handlePhotoSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      try {
        const response = await apiService.uploadImage("upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        successNotification();
        setPreviewImage(null);
        setApiImageUrl(response.data.image_url);
      } catch (error) {
        errorNotification(
          `\nDosya yüklenirken bir hata oluştu.\n${error.toString()}`
        );
      }
      setLoading(false);
    } else {
      errorNotification("\nLütfen görsel seçiniz.");
    }
  };

  const handlePhotoSubmit2 = async (event) => {
    event.preventDefault();
    if (selectedFile2) {
      setLoading2(true);
      const formData = new FormData();
      formData.append("image", selectedFile2);
      try {
        const response = await apiService.uploadImage("upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        successNotification();
        setPreviewImage2(null);
        setApiImageUrl2(response.data.image_url);
      } catch (error) {
        errorNotification(
          `\nDosya yüklenirken bir hata oluştu.\n${error.toString()}`
        );
      }
      setLoading2(false);
    } else {
      errorNotification("\nLütfen görsel seçiniz.");
    }
  };

  const handleDeleteImage = async (event) => {
    event.preventDefault();
    if (apiImageUrl) {
      setLoading(true);
      try {
        await apiService.delete("upload/delete", {
          path: apiImageUrl.split("/").pop(),
        });
        successNotification();
        setPreviewImage(null);
        setApiImageUrl(null);
      } catch (error) {
        errorNotification(
          `\nGörsel silinirken bir hata oluştu.\n${error.toString()}`
        );
      } finally {
        setLoading(false);
      }
    } else {
      errorNotification("\nLütfen görsel seçiniz.");
    }
  };
  const handleDeleteImage2 = async (event) => {
    event.preventDefault();
    if (apiImageUrl2) {
      setLoading2(true);
      try {
        await apiService.delete("upload/delete", {
          path: apiImageUrl2.split("/").pop(),
        });
        successNotification();
        setPreviewImage2(null);
        setApiImageUrl2(null);
      } catch (error) {
        errorNotification(
          `\nGörsel silinirken bir hata oluştu.\n${error.toString()}`
        );
      } finally {
        setLoading2(false);
      }
    } else {
      errorNotification("\nLütfen görsel seçiniz.");
    }
  };

  const [formValues, setFormValues] = useState({
    title: data.title,
    product_name: data.product_name,
    meta_currency_name: data.meta_currency_name,
    category_id: data.category_id,
    tags: data.tags,
    img_url: apiImageUrl,
    cross_img_url: apiImageUrl2 ? apiImageUrl2 : null,
  });

  const handleSubmit = async () => {
    console.log(formValues);
    const emptyFields = [];
    if (formValues.title === "") {
      emptyFields.push("Ürün Adı");
    }
    if (formValues.category === "") {
      emptyFields.push("Kategori");
    }
    if (formValues.summary === "") {
      emptyFields.push("Özet");
    }
    if (formValues.content === "") {
      emptyFields.push("Açıklama");
    }
    if (formValues.category === null) {
      emptyFields.push("Kategori");
    }
    if (formValues.type === null) {
      emptyFields.push("Analiz Durumu");
    }
    if (apiImageUrl === null) {
      emptyFields.push("Öne çıkan görsel");
    }
    // Diğer alanlar için de kontrolü yapabilirsiniz

    if (emptyFields.length > 0) {
      errorNotification(
        `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
      );
    } else {
      try {
        setBusyModal(true);
        const generatedData = {
          category_id: formValues.category_id.value,
          title: formValues.title,
          tags: formValues.tags,
          is_promoted: 0,
          img_url: apiImageUrl,
          cross_img_url: apiImageUrl2,
        };

        await new Promise((resolve) => setTimeout(resolve, 2000));
        await apiService.put("prices/update", data.id, generatedData);
        setBusyModal(false);
        successNotification();
        closeModal(false);
        handleRenewData(true);
      } catch (error) {
        errorNotification(`\nBir hata oluştu.\n${error.toString()}`);
      } finally {
        setBusyModal(false);
      }
    }
  };



  const handleImgChange = (event) => {
    const fileUploaded = event.target.files[0];
    setSelectedFile(fileUploaded)
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(fileUploaded);
  }

  const handleImgChange2 = (event) => {
    const fileUploaded2 = event.target.files[0];
    setSelectedFile2(fileUploaded2)
    const reader2 = new FileReader();
    reader2.onloadend = () => {
      setPreviewImage2(reader2.result);
    };
    reader2.readAsDataURL(fileUploaded2);
  }


  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleClick2 = event => {
    hiddenFileInput2.current.click();
  };

  const renewCategories = () => {
    const CategoryOptions = categories;
    setSelectOptions(
      CategoryOptions.map((category) => {
        return {
          value: category.id,
          label: category.title,
        };
      })
    );
  }

  useEffect(() => {
    renewCategories();
  }, []);

  useEffect(() => {
    renewCategories();
    console.log("testingg..123")
  }, [categories]);

  useEffect(() => {
    setApiImageUrl(apiImageUrl);
  }, [apiImageUrl]);

  return (
    <Modal
      show={true}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      size="md"
      centered
    >
      <div style={{ position: "relative" }}>
        {busyModal && (
          <Spinner
            animation="border"
            variant="primary"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              filter: "blur(0px)",
              zIndex: 22,
            }}
          />
        )}

        <form
          onSubmit={(e) => e.preventDefault}
          style={{
            filter: busyModal ? "blur(3px)" : "",
            animation: busyModal ? "0.2s all" : "",
            zIndex: busyModal ? 2 : "",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "1rem" }}>
              {data.title} | Güncelle
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row className="mb-3 d-flex">
              <Col md={6} className={`d-flex align-items-center  p-2`} >
                {previewImage && !apiImageUrl && !loading && (
                  <>
                    <div>
                      <img src={previewImage ? previewImage : "https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick} />
                      <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      Önizleme
                      <br />
                      <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit}><strong>Görseli Yükle</strong></div>
                    </div>
                  </>
                )}

                {apiImageUrl && !previewImage && !loading && (
                  <>
                    <div>
                      <img
                        src={apiImageUrl ? apiImageUrl : "https://via.placeholder.com/65x65"}
                        width={55}
                        className="img-fluid"
                        style={{ borderRadius: "50%", outline: '2px solid green' }} alt=""
                        onClick={() => (errorNotification("Görseli silebilirsiniz."))} />
                      <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      Resim yüklendi.
                      <br />
                      <div className="text-bold" style={{ cursor: "pointer" }} onClick={handleDeleteImage}><strong className="text-danger">Kaldır</strong></div>
                    </div>
                  </>
                )}

                {!apiImageUrl && !previewImage && !loading && (
                  <>
                    <div>
                      <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick} />
                      <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      Önizleme
                      <br />
                      <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit}><strong>Görseli Yükle</strong></div>
                    </div>
                  </>
                )}

                {loading && (
                  <>

                    <div className="d-flex">
                      <div>
                        <Spinner animation="border" role="status" size="lg" >
                          <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={() => (errorNotification("Lütfen bekleyiniz.."))} />
                        </Spinner>
                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleImgChange} />
                      </div>
                      <div style={{ marginLeft: 15 }}>
                        Önizleme
                        <br />
                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit}><strong>Görseli Yükle</strong></div>
                      </div>
                    </div>

                  </>
                )}



              </Col>
              <Col md={6} className={`d-flex align-items-center p-2`} >
                {previewImage2 && !apiImageUrl2 && !loading2 && (
                  <>
                    <div>
                      <img src={previewImage2 ? previewImage2 : "https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick2} />
                      <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      Önizleme
                      <br />
                      <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit2}><strong>Görseli Yükle</strong></div>
                    </div>
                  </>
                )}

                {apiImageUrl2 && !previewImage2 && !loading2 && (
                  <>
                    <div>
                      <img
                        src={apiImageUrl2 ? apiImageUrl2 : "https://via.placeholder.com/65x65"}
                        width={55}
                        className="img-fluid"
                        style={{ borderRadius: "50%", outline: '2px solid green' }} alt=""
                        onClick={() => (errorNotification("Görseli silebilirsiniz."))} />
                      <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      Resim yüklendi.
                      <br />
                      <div className="text-bold" style={{ cursor: "pointer" }} onClick={handleDeleteImage2}><strong className="text-danger">Kaldır</strong></div>
                    </div>
                  </>
                )}

                {!apiImageUrl2 && !previewImage2 && !loading2 && (
                  <>
                    <div>
                      <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={handleClick2} />
                      <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      Önizleme
                      <br />
                      <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit2}><strong>Görseli Yükle</strong></div>
                    </div>
                  </>
                )}


                {loading2 && (
                  <>

                    <div className="d-flex">
                      <div>
                        <Spinner animation="border" role="status" size="lg" >
                          <img src={"https://via.placeholder.com/65x65"} width={55} className="img-fluid" style={{ borderRadius: "50%" }} alt="" onClick={() => (errorNotification("Lütfen bekleyiniz.."))} />
                        </Spinner>
                        <input type="file" ref={hiddenFileInput2} style={{ display: 'none' }} onChange={handleImgChange2} />
                      </div>
                      <div style={{ marginLeft: 15 }}>
                        Önizleme
                        <br />
                        <div className="text-bold" style={{ cursor: "pointer" }} onClick={handlePhotoSubmit2}><strong>Görseli Yükle</strong></div>
                      </div>
                    </div>

                  </>
                )}



              </Col>
            </Row>
            <Row>

              <Col md={8}>
                <div className="mb-3">
                  <Form.Label>Ürün Adı</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ürün Adı"
                    value={formValues.title}
                    onChange={(e) =>
                      setFormValues({ ...formValues, title: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>Kategori</Form.Label>
                  <Select
                    options={selectOptions}
                    placeholder="Kategori Seçiniz"
                    value={selectOptions.find(
                      (option) => option.value === formValues.category_id
                    )}
                    onChange={(selectedOption) =>
                      setFormValues({ ...formValues, category_id: selectedOption })
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label>Çapraz Adı <strong>(Ör: BTCUSD)</strong></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ürün Adı"
                    value={formValues.product_name}
                    onChange={(e) =>
                      setFormValues({ ...formValues, product_name: e.target.value })

                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label>Meta Adı (Ör: BTCUSD<strong className="text-danger">v</strong>)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="MetaTrader'daki adı."
                    value={formValues.meta_currency_name}
                    onChange={(e) =>
                      setFormValues({ ...formValues, meta_currency_name: e.target.value })
                    }
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Form.Label>Etiketler</Form.Label>
                  <Tags
                    className="w-100"
                    placeholder="Alakalı etiketleri giriniz."
                    defaultValue={formValues.tags}
                    onChange={(values) => {
                      let tags = values.detail.tagify
                        .getCleanValue()
                        .map((value) => Object.values(value).toString());
                      setFormValues((formValues) => ({
                        ...formValues,
                        tags: tags.join(","),
                      }));
                    }}
                  />
                </div>
              </Col>

            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end">

            <div>
              {apiImageUrl ? (
                <Button variant="primary" onClick={handleSubmit}>
                  Güncelle
                </Button>
              ) : (
                <Button variant="primary" disabled={true}>
                  Yayınla
                </Button>
              )}
            </div>
          </Modal.Footer>
        </form>
      </div>
    </Modal >
  );
}
