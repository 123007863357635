import React from "react";
import { Link } from "react-router-dom";

const Beadcrumb = ({ data, title }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/">Anasayfa</Link>
            </li>
            {data.map((item, index) => (
              <li
                className={`breadcrumb-item ${
                  item.link !== "" ? "active" : ""
                }`}
                key={index}
                caria-current="page"
              >
                {/* item.link && `/${item.link}` */}
                <Link to={`${item.link !== null ? item.link : ""}`}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ol>
          <h4 className="main-title mb-0">{title}</h4>
        </div>
      </div>
    </>
  );
};

export default Beadcrumb;
