import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import apiService from '../service/api.service';
import Select from "react-select";
import useCustomersFilteredData from '../store/CustomersStoreFiltered';

export default function CustomersFilter({
    setResultActive,
    isRenewData,
    setIsRenewData
}) {


    const zs_setCustomersFilteredData = useCustomersFilteredData((state) => state.setSelectedData);
    const zs_getCustomersFilteredData = useCustomersFilteredData((state) => state.selectedData);
    const zs_DataisLoadingCustomersFilteredData = useCustomersFilteredData((state) => state.setRenewData);


    const [selectOptions, setSelectOptions] = useState();
    const [renewData, setRenewData] = useState(false);


    const [formValues, setFormValues] = useState({
        fullname: "",
        phone: "",
        email: "",
        is_verified: null,
    });

    const _handleFilter = async () => {
        // setIsRenewData(true)
        setResultActive(true)
        zs_DataisLoadingCustomersFilteredData(true);
        const queryParams = new URLSearchParams();
        for (const [key, value] of Object.entries(formValues)) {
            if (value !== null && value !== "") {
                queryParams.append(key, value);
            }
        }

        const query = queryParams.toString();
        console.log(query);
        try {
            // const leads = await getLeadsByParameters(query);
            const response = await apiService.getByQuery("user/by-query", query);
            console.log(response.data, " response filtered page");
            zs_setCustomersFilteredData(response.data);
            zs_DataisLoadingCustomersFilteredData(false);

        } catch (err) {
            console.log(err);
            zs_DataisLoadingCustomersFilteredData(false);

        } finally {
            zs_DataisLoadingCustomersFilteredData(false);

            // setIsRenewData(false);
        }
    }


    const onayOptions = [
        {
            value: "1",
            label: "Onaylı"
        },
        {
            value: "0",
            label: "Onaysız"
        }

    ]


    const resetForm = () => {
        setFormValues({
            fullname: "",
            phone: "",
            email: "",
            is_verified: null,
        });
        setRenewData(true);
        zs_setCustomersFilteredData(null)
    };

    useEffect(() => {
        if (isRenewData) {
            _handleFilter();
        }
        console.log("use effect triggered from filter")
    }, [isRenewData]);

    return (
        <Form onSubmit={(e) => e.preventDefault()}>
            <Row>

                <Col md="2" className='mt-2'>
                    <Form.Label >Müşteri Adı</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Müşteri Adı"
                        value={formValues.fullname}
                        onChange={(e) =>
                            setFormValues({ ...formValues, fullname: e.target.value })
                        }
                    />
                </Col>
                <Col md="2" className='mt-2'>
                    <Form.Label >Telefon</Form.Label>
                    <Form.Control
                        value={formValues.phone}
                        onChange={(e) =>
                            setFormValues({ ...formValues, phone: e.target.value })
                        }
                        type="text" placeholder="Telefon Numarası" />
                </Col>
                <Col md="2" className='mt-2'>
                    <Form.Label >E-Posta Adresi</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="E-Posta Adresi"
                        value={formValues.email}
                        onChange={(e) =>
                            setFormValues({ ...formValues, email: e.target.value })
                        }
                    />
                </Col>

                <Col md="3" className='mt-2'>
                    <Form.Label >Onay</Form.Label>
                    <Select options={onayOptions}
                        onChange={(selectedOption) =>
                            setFormValues((prevFormValues) => ({
                                ...prevFormValues,
                                is_verified: selectedOption.value
                            }))
                        }
                        value={
                            formValues.is_verified ? (
                                onayOptions.find(
                                    (option) => option.value === formValues.is_verified
                                )
                            ) : null
                        }
                        isSearchable={false} placeholder="Kategori" />
                </Col>

                <Col md="12" className="mt-3">
                    <div className="d-flex justify-content-end">
                        <div className="mx-2">
                            <Button variant="light" onClick={resetForm} disabled={false} >
                                Temizle
                            </Button>
                        </div>
                        <Button variant="primary" onClick={_handleFilter} disabled={false}>
                            Filtrele
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}
