import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { errorNotification, successNotification } from '../utils/notification';
import apiService from '../service/api.service';
import Select from "react-select";



export default function PushNotificationWithData({
    extraStyles,
    title
}) {

    const [isLoading, setIsLoading] = useState(false);
    const [contentData, setContentData] = useState([]);
    const [suggestedText, setSuggestedText] = useState("");
    const [suggestedBody, setSuggestedBody] = useState("");
    const [selectedContentData, setSelectedContentData] = useState();

    const [formValues, setFormValues] = useState({
        title: suggestedText,
        description: suggestedBody,
        type: ""
    });

    useEffect(() => {
        setFormValues({ title: suggestedText ? suggestedText : "" })
    }, [suggestedText])

    useEffect(() => {
        setFormValues({ description: suggestedBody ? suggestedBody : "" })
    }, [suggestedBody])

    const selectOptions = [
        {
            value: "analiz",
            label: "Analiz"
        },
        {
            value: "haber",
            label: "Haber"
        },
        {
            value: "youtube",
            label: "Youtube Bildirimi"
        },
    ]


    const _handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {

            const emptyFields = [];

            if (formValues.title === "") {
                emptyFields.push("Başlık");
            }

            if (formValues.description === "") {
                emptyFields.push("İçerik");
            }

            if (formValues.type.value === undefined) {
                emptyFields.push("Kategori");
            }

            if (selectedContentData === null) {
                emptyFields.push("İçerik");
            }

            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;
            }



            console.log(formValues, selectedContentData)
            console.log(selectedContentData);
            const data = {
                ...formValues,
                content: selectedContentData
            }

            await apiService.post("firebase/push-with-data", data);
            successNotification("Bildirim başarıyla gönderildi.");
            setFormValues({
                title: "",
                description: "",
                type: null,
                selectedContentData: null,

            });
            setIsLoading(false);

        } catch (err) {
            console.log(err);
            errorNotification(err.response.data.message);
        } finally {
            setIsLoading(false);
        }
    }

    const handleAutoTitle = () => {
        setSuggestedText("Canlı Yayındayız 🔴")
    }

    const getItemsForSelectedCategory = async (item) => {
        setContentData([]);

        switch (item.value) {
            case "canli-sinyal":
                setSelectedContentData(null);

                const liveSignal = await apiService.get("signals/get-live?limit=9999");
                console.log(liveSignal.data.data);
                setContentData(liveSignal.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: `[CANLI S.] ${item.priceDetail.product_name} - TP (${item.tp}) / SL (${item.sl})`,
                    };
                }));
                break;

            case "gecmis-sinyal":
                setSelectedContentData(null);
                const resulted = await apiService.get("signals/get-resulted?limit=9999");
                setContentData(resulted.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: `[GEÇMİŞ S.] ${item.priceDetail.product_name} - TP (${item.tp}) / SL (${item.sl})`,
                    };
                }));
                break;

            case "analiz":
                setSelectedContentData(null);

                const analyse = await apiService.get("analyses?limit=9999");
                setContentData(analyse.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: `[ANALİZ] - ${item.title}`,
                    };
                }));
                break;


            case "haber":
                setSelectedContentData(null);

                const news = await apiService.get("news?limit=9999");
                setContentData(news.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: `[HABER] - ${item.title}`,
                    };
                }));
                break;

            case "youtube":
                setContentData(
                    [
                        {
                            value: 1,
                            label: `Youtube Bildirimi Yolla`,
                        }
                    ]
                );
                setSuggestedBody("Günlük piyasa incelemeleri ve teknik analizler Serdar Keskin ile şimdi canlı yayında!")
                break;



            default:
                return;
                break;
        }
    }


    return (
        <Card className={extraStyles ? extraStyles : "mt-3 border-primary"} >
            <Card.Header>
                {title ? title : " Yönlendirme ile Bildirim Yolla"}
            </Card.Header>
            <Card.Body>
                <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-3">
                            <Row>
                                <Col md="6">
                                    <Form.Label>Kategori</Form.Label>
                                    <Select
                                        options={selectOptions}
                                        placeholder="Kategori Seçiniz"
                                        defaultValue={null}
                                        value={formValues.type}

                                        onChange={(selectedOption) =>
                                        // setFormValues({ ...formValues, type: selectedOption })
                                        {
                                            setFormValues({ ...formValues, type: selectedOption })
                                            setSelectedContentData(null);
                                            getItemsForSelectedCategory(selectedOption);
                                        }
                                        }
                                    />
                                </Col>
                                <Col md="6">
                                    <Form.Label>İçerik Seçiniz</Form.Label>
                                    <Select
                                        options={contentData}

                                        placeholder="İçerik Seçiniz"
                                        defaultValue={null}
                                        isDisabled={contentData.length === 0}
                                        value={selectedContentData?.value}
                                        onChange={(selectedOption) => {
                                            // setFormValues({ ...formValues, type: selectedOption })
                                            console.log(selectedOption);
                                            setSelectedContentData(selectedOption.value)
                                        }
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="mb-3">
                            <Form.Label>Başlık <div style={{ cursor: 'pointer', color: 'blue' }} onClick={handleAutoTitle}>Oto başlık</div></Form.Label>
                            <Form.Control type="text" placeholder="Bildirim Başlığı"
                                value={formValues.title}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, title: e.target.value })
                                } />
                        </div>
                        <div className="mb-3">
                            <Form.Label >İçerik</Form.Label>
                            <Form.Control as="textarea" rows="2" placeholder="Bildirimi içeriği giriniz.."
                                value={formValues.description}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, description: e.target.value })
                                }></Form.Control>
                        </div>
                        <div className="d-flex justify-content-end">
                            {isLoading && <Button variant="light" disabled={true} className="mx-2"> Gönderiliyor..</Button>}
                            {!isLoading && <Button variant="success" onClick={_handleSubmit} className="mx-2"> Gönder</Button>}
                        </div>
                    </form>
                </div>
            </Card.Body>
        </Card >
    )
}
