import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, Card, Form, Spinner, Table } from 'react-bootstrap'
import apiService from '../../../service/api.service';
import { errorNotification, successNotification } from '../../../utils/notification';

export default function Ungrouppeds({

    currentGroup,
    callList,
    setCallList

}) {

    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [busyCard, setBusyCard] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);


    const apiCall = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.getByLimit(`user/get-ungrouped-users`, currentPage, 20);
            setData(response.data);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const hanleAddUsertoGroup = async (customer_id) => {
        try {
            setBusyCard(true);
            const response = await apiService.post(`user/set-group`, {
                customer_id: customer_id,
                group_id: currentGroup
            });
            console.log(response);
            setCallList(true);
            successNotification("\n Müşteri başarıyla gruba eklendi.")
        } catch (error) {
            console.log(error);
            errorNotification(error.message);
            setBusyCard(false)
        } finally {
            setBusyCard(false)
            console.log("final");
            apiCall();
        }
    };
    useEffect(() => {
        apiCall();
    }, [currentPage])

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPageButtons = () => {
        const buttons = [];
        const totalPages = data?.pagination?.totalPages;
        const buffer = 2; // Seçili olan sayfanın etrafında kaç sayfa göstermek istediğinizi belirtir.

        let previousWasDots = false;

        for (let i = 1; i <= totalPages; i++) {
            if (i === 1 || i === totalPages || (i >= currentPage - buffer && i <= currentPage + buffer)) {
                buttons.push(
                    <Button key={i} onClick={() => handlePageClick(i)} active={currentPage === i} size='sm' type="button" variant="light">
                        {i}
                    </Button>
                );
                previousWasDots = false;
            } else if (!previousWasDots) {
                buttons.push(
                    <Button key={i} type="button" variant="light" size='sm'>
                        ...
                    </Button>
                );
                previousWasDots = true;
            }
        }
        return buttons;
    };

    if (isLoading) return (
        <Card>
            <Card.Header className='d-block'>
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className='text-danger'> Grubu Olmayan Müşteriler <strong>{data?.pagination?.totalRecords}</strong></div>
                    <div> <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
                        <Form.Control
                            type="search"
                            placeholder="Müşteri Ara"
                            className="me-2"
                            size='sm'
                            disabled={busyCard}
                            aria-label="Müşteri Ara"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </Form>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>

                <div style={{
                    position: 'relative'
                }}>
                    {busyCard && (
                        <Spinner animation="border" role="status" style={{
                            position: 'absolute',
                            left: "45%"
                        }}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )}
                    Yükleniyor
                </div>
            </Card.Body>
            <Card.Footer>
                <ButtonGroup className="me-2" aria-label="First group">
                    {renderPageButtons()}
                </ButtonGroup>
            </Card.Footer>
        </Card >
    )
    return (
        <Card>
            <Card.Header className='d-block'>
                <div className="d-flex w-100 justify-content-between align-items-center">
                    <div className='text-danger'> Grubu Olmayan Müşteriler <strong>{data?.pagination?.totalRecords}</strong></div>
                    <div> <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
                        <Form.Control
                            type="search"
                            placeholder="Müşteri Ara"
                            className="me-2"
                            size='sm'
                            disabled={busyCard}
                            aria-label="Müşteri Ara"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </Form>
                    </div>
                </div>

            </Card.Header>
            <Card.Body>

                <div style={{
                    position: 'relative'
                }}>
                    {busyCard && (
                        <Spinner animation="border" role="status" style={{
                            position: 'absolute',
                            left: "45%"
                        }}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )}
                    <Table className="mb-0" hover size="md" responsive style={{
                        filter: busyCard && "blur(5px)"
                    }} >
                        <thead>
                            <tr>
                                <th scope="col">Müşteri</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.filter(user =>
                                user.customer_fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                user.customer_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                user.customer_phone.includes(searchTerm)
                            ).map((user, index) => (
                                <tr key={index} style={{ verticalAlign: 'middle' }}>

                                    <td>{user.customer_fullname} <br /> <small>{user.customer_email}</small><br /> <small>{user.customer_phone}</small></td>
                                    <td>
                                        <div className="text-primary" style={{ cursor: 'pointer' }} onClick={() => {
                                            if (busyCard) return;
                                            hanleAddUsertoGroup(user.customer_id)
                                        }}>Ekle</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Card.Body>
            <Card.Footer>
                <ButtonGroup className="me-2" aria-label="First group">
                    {renderPageButtons()}
                </ButtonGroup>
            </Card.Footer>
        </Card >
    )
}
