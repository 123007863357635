import axios from "axios";
import useAuthStore from "../store/AuthStore";
import { errorNotification } from "../utils/notification";

class APIService {
  constructor() {
    this.apiURL = process.env.REACT_APP_API_URL;
    axios.interceptors.request.use(
      (config) => {
        const token = useAuthStore.getState().token;
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // Oturum süresi dolmuş, kullanıcıyı oturum açma sayfasına yönlendir
          console.log("401 Catched. Waiting for actions to refresh...");
          useAuthStore.getState().logout();
          errorNotification("Oturum süreniz sona erdi lütfen, tekrar giriş yapınız.");
        }
        return Promise.reject(error);
      }
    );
  }

  // GET isteği
  async get(endpoint) {
    try {
      const response = await axios.get(`${this.apiURL}/${endpoint}`);
      return response.data;
    } catch (error) {
      console.error("GET isteği başarısız:", error);
      throw error;
    }
  }

  async getDetailById(endpoint, itemId) {
    try {
      let url = `${this.apiURL}/${endpoint}/${itemId}`;
      if (itemId) {
        url += `?${itemId}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("GET isteği başarısız:", error);
      throw error;
    }
  }

  async getByQuery(endpoint, query) {
    try {
      let url = `${this.apiURL}/${endpoint}`;
      if (query) {
        url += `?${query}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("GET isteği başarısız:", error);
      throw error;
    }
  }

  async getByLimit(endpoint, page, limit) {
    try {
      let url = `${this.apiURL}/${endpoint}`;
      if (page || limit) {
        url += `?page=${page}&limit=${limit}&all_content=true`;
      }

      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("GET isteği başarısız:", error);
      throw error;
    }
  }

  // POST isteği
  async post(endpoint, data) {
    try {
      const response = await axios.post(`${this.apiURL}/${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error("POST isteği başarısız:", error);
      throw error;
    }
  }

  // POST isteği
  async uploadImage(endpoint, data, headers) {
    try {
      const response = await axios.post(`${this.apiURL}/${endpoint}`, data, headers);
      return response.data;
    } catch (error) {
      console.error("POST isteği başarısız:", error);
      throw error;
    }
  }
  async uploadStoryImage(endpoint, data, headers) {
    try {
      const response = await axios.post(`${this.apiURL}/${endpoint}`, data, headers);
      return response.data;
    } catch (error) {
      console.error("POST isteği başarısız:", error);
      throw error;
    }
  }

  // PUT isteği
  async put(endpoint, itemId, data) {
    try {
      if (itemId) {
        const response = await axios.put(`${this.apiURL}/${endpoint}/${itemId}`, data);
        return response.data;
      } else {
        const response = await axios.put(`${this.apiURL}/${endpoint}`, data);
        return response.data;
      }
    } catch (error) {
      console.error("PUT isteği başarısız:", error);
      throw error;
    }
  }

  // PATCH isteği
  async patch(endpoint, data) {
    try {
      const response = await axios.patch(`${this.apiURL}/${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error("PATCH isteği başarısız:", error);
      throw error;
    }
  }

  async deleteItem(endpoint, itemId) {
    try {
      const response = await axios.delete(`${this.apiURL}/${endpoint}/${itemId}`);
      return response.data;
    } catch (error) {
      console.error("DELETE isteği başarısız:", error);
      throw error;
    }
  }
  // DELETE isteği
  async delete(endpoint, data) {
    try {
      const response = await axios.post(`${this.apiURL}/${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error("DELETE isteği başarısız:", error);
      throw error;
    }
  }
  async deleteRequest(endpoint, data) {
    try {
      const response = await axios.delete(`${this.apiURL}/${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error("DELETE isteği başarısız:", error);
      throw error;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new APIService();
