import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import Select, { components } from "react-select";
import apiService from "../../service/api.service";
import axios from "axios";
import { errorNotification, successNotification } from "../../utils/notification";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
// import ImageUpload from "../../components/ImageUpload";
import StoryUpload from "../../components/StoryUpload";

export default function Index() {
    const navigate = useNavigate();
    const { itemid } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [firstImageURL, setFirstImageURL] = useState('');
    const [secondImageURL, setSecondImageURL] = useState('');

    // Announcement ile ilgili bilgileri al

    const apiCall = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.get(`announcements/detail/${itemid}`);
            setData(response.data);
            console.log(response.data);
            setFirstImageURL(response.data.small_img ? response.data.small_img : "")
            setSecondImageURL(response.data.story_img ? response.data.story_img : "")
            setIsLoading(false);

        } catch (error) {
            console.log(error)
            errorNotification()
        } finally {
            setIsLoading(false);
        }
    }


    const publishStory = async () => {
        try {
            if (!data.is_active) throw new Error("İçerik aktif olmadığı için story paylaşılamıyor.")
            if (data.small_img === null) throw new Error("Ufak görsel yok")
            if (data.story_img === null) throw new Error("Story görseli yok")

            const response = await apiService.put(`announcements/update`, itemid, {
                is_promoted: !data.is_promoted,
            });
            setData(response.data);
            console.log(response);
            if (response.data.is_promoted) {
                successNotification(`\n İçerik başarıyla öne çıkarılmıştır`)
            } else {
                successNotification(`\n İçerik başarıyla öne çıkarılanlardan kaldırılmıştır. `)

            }
        } catch (error) {
            console.log(error);
            errorNotification(`\n ${error.message}`)
        } finally {
            console.log("finals")
        }
    }

    const setStatusforContent = async () => {
        try {

            if (data.is_promoted && data.is_active) throw new Error("İçerik öne çıkan içerik olduğu için deaktif edilemiyor.")
            const response = await apiService.put(`announcements/update`, itemid, {
                is_active: !data.is_active,
            });

            setData(response.data);
            console.log(response.data);
            if (response.data.is_active) {
                successNotification(`\n İçerik başarıyla aktif edilmiştir.`)
            } else {
                successNotification(`\n İçerik başarıyla deaktif edilmiştir. `)

            }
        } catch (error) {
            console.log(error);
            errorNotification(`\n ${error.message}`)
        } finally {
            console.log("finals")
        }
    }

    useEffect(() => {
        apiCall()
    }, [])



    if (isLoading) {
        return "Yükleniyor";
    }



    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Duyuru & Kampanya", link: "/announcements" },
                        { title: "Story Ayarları", link: null }
                    ]}
                    title={"Story Ayarları"}
                />

                <Form onSubmit={(e) => e.preventDefault}>
                    <Row className="g-3 justify-content-center">
                        <Col md="9">
                            <Card className="card-one">
                                <Card.Header className="d-flex align-items-center">
                                    <Card.Title as="h6">Story Ayarları</Card.Title>
                                    <Nav className="nav-icon nav-icon-sm ms-auto gap-2">
                                        <Link to={`/announcements/edit/${itemid}`} className="btn btn-primary btn-sm">
                                            İçeriği Düzenle
                                        </Link>
                                        {data.is_active ?
                                            <Button size="sm" style={{ borderRadius: 0, opacity: "0.8" }} onClick={(e) => setStatusforContent()}>İçerik Durumu: AKTİF</Button>
                                            :
                                            <Button size="sm" variant="danger" style={{ borderRadius: 0, opacity: "0.8" }} onClick={(e) => setStatusforContent()}>İçerik Durumu: PASİF</Button>
                                        }

                                        {data.is_promoted ?
                                            <Button size="sm" variant="danger" onClick={() => publishStory()}>Öne Çıkanlardan Kaldır</Button>
                                            :
                                            <Button size="sm" variant="success" onClick={() => publishStory()}>Hikaye'yi Yayına Al</Button>}
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="d-flex ">
                                        <Col md="6">
                                            <div className="d-flex align-items-center">
                                                <div style={{ marginRight: 20 }}>
                                                    <img
                                                        className="shadow-sm"
                                                        style={{
                                                            borderRadius: "50%"
                                                        }} src={firstImageURL !== "" ? firstImageURL : "https://fakeimg.pl/150x150/fafafa/969696?font=bebas"} alt="" width={75} />
                                                </div>

                                                <div>
                                                    <div className="title fw-bold">
                                                        Story ufak görsel
                                                    </div>
                                                    <div className="">

                                                        <p className="mb-0">
                                                            Buradaki resim ufak resmi temsil eder.
                                                        </p>

                                                    </div>
                                                    <div className="mt-3">
                                                        <StoryUpload targetUpload={"small_img"} storyId={itemid} onUploadSuccess={(url) => setFirstImageURL(url)} ></StoryUpload>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col md="6">
                                            <Row>
                                                <Col md="6">
                                                    <div className="card card-poster h-100 shadow-sm">
                                                        <img src={secondImageURL !== "" ? secondImageURL : "https://fakeimg.pl/1080x1920/fafafa/969696?font=bebas"} alt="" />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="title fw-bold">
                                                        Story büyük görsel
                                                    </div>
                                                    <div>
                                                        <p className="mb-0">
                                                            Buradaki resim büyük hikaye resmini temsil eder.
                                                        </p>
                                                    </div>
                                                    <div className="mt-3">
                                                        <StoryUpload targetUpload={"story_img"} storyId={itemid} onUploadSuccess={(url) => setSecondImageURL(url)} ></StoryUpload>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </Card.Body>

                            </Card>
                        </Col>

                    </Row>
                </Form>
                <Footer />
            </div>
        </React.Fragment >
    );
}
