import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import apiService from '../../service/api.service';
import { errorNotification, successNotification } from '../../utils/notification';
import { Link } from 'react-router-dom';

export default function Viewers({
    itemid
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);



    const apiCall = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.get(`notification/viewevers/${itemid}`);
            setData(response.data);
            console.log(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error)
            errorNotification()
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeleteViewer = async (id) => {
        try {
            await apiService.post(`notification/delete-viewer/${id}`);
            await apiCall();
            successNotification();
        } catch (error) {
            console.log(error)
            errorNotification()
        }
    }


    useEffect(() => {
        apiCall();
    }, [])

    if (isLoading) return "Yükleniyor";

    return (
        <Card className="card-one">
            <Card.Header>
                <Card.Title as="h6"> Görüntüleyenler ({data?.length})</Card.Title>

            </Card.Header>
            <Card.Body style={{ height: 300, overflowY: 'auto' }}>
                <Table className="mb-0" hover size="sm" >
                    <thead style={{ position: 'sticky', top: 0, background: '#f2f2f2' }}>
                        <tr>
                            <th>Müşteri</th>
                            <th>E-Posta</th>
                            <th>Telefon</th>
                            <th>Tekrar?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(user => (
                            <tr>
                                <td>
                                    <Link to={user.customer && `/customers/detail/${user.customer.id}` || `/customers`}>
                                        {user.customer && user.customer.fullname || <code>Bulunamadı</code>}
                                    </Link>
                                </td>
                                <td>
                                    {user.customer && user.customer.email || <code>Bulunamadı</code>}
                                </td>
                                <td>
                                    {user.customer && user.customer.phone || <code>Bulunamadı</code>}
                                </td>
                                <td>
                                    <div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleDeleteViewer(user.id)}>Göster</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}
