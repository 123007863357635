import React, { useEffect, useState } from 'react'
import { Form, Row, Spinner, Table } from 'react-bootstrap'
import apiService from '../../service/api.service';
import { errorNotification } from '../../utils/notification';
import { Link } from 'react-router-dom';

export default function DisallowedGroups({
    selectedGroups = [],
    setSelectedGroups
}) {

    const [isLoading, setisLoading] = useState(false);
    const [data, setData] = useState([]);

    const apiCall = async () => {
        try {
            setisLoading(true);
            const response = await apiService.get(`groups/with-users`);
            setData(response.data);
            console.log(response.data)
        } catch (error) {
            console.log(error);
            errorNotification("\n " + error.message)
        } finally {
            setisLoading(false);
        }
    }

    const handleRowClick = (group) => {
        if (selectedGroups.some(g => g.id === group.id)) {
            setSelectedGroups(prevGroups => prevGroups.filter(g => g.id !== group.id));
        } else {
            setSelectedGroups(prevGroups => [...prevGroups, group]);
        }
    }

    const handleCheckboxChange = (group, isChecked, e) => {
        e.stopPropagation();
        if (isChecked && !selectedGroups.some(g => g.id === group.id)) {
            setSelectedGroups(prevGroups => [...prevGroups, group]);
        } else if (!isChecked && selectedGroups.some(g => g.id === group.id)) {
            setSelectedGroups(prevGroups => prevGroups.filter(g => g.id !== group.id));
        }
    }

    const toggleSelectAll = (isChecked) => {
        if (isChecked) {
            setSelectedGroups(data);
        } else {
            setSelectedGroups([]);
        }
    }


    useEffect(() => {
        apiCall();
    }, [])

    if (isLoading) return <div className='mt-3 d-flex align-items-center justify-content-center gap-2'>
        <Spinner animation="border" role="status" size='sm'>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div>Yükleniyor..</div>
    </div>;

    return (
        <Row>
            <div className='d-flex justify-content-between'>
                <div className="fw-bold mb-2">
                    <div>
                        Şu grupları hariç tut
                    </div>
                </div>
                <div>
                    <Link to={"/customers-groups"}>Grupları Gör</Link>
                </div>
            </div>
            <div className='mb-1'>
                <p className='mb-0 mt-1 fw-normal text-muted'>
                    Seçtiğiniz gruplara bu sinyal gösterilmeyecektir. Ancak grubu olmayan kullanıcılar görecektir.
                </p>
            </div>

            <div style={{ height: 200, overflowY: 'auto', outline: '2px solid #fafafa', paddingTop: 5 }}>
                <Table className="mb-0" hover size="sm">
                    <thead style={{ position: 'sticky', top: 0, background: '#f2f2f2' }}>
                        <tr>
                            <th>
                                <Form.Check
                                    type="checkbox"
                                    checked={data?.length && selectedGroups?.length === data?.length}
                                    onChange={e => toggleSelectAll(e.target.checked)}
                                />
                            </th>
                            <th>Grup Adı</th>
                            <th>Grup Açıklaması</th>
                            <th>Kullanıcı</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(group => (
                            <tr key={group.id} onClick={() => handleRowClick(group)} style={{ cursor: 'pointer' }}
                                className={selectedGroups?.some(u => u.id === group.id) ? "table-success" : ""}>
                                <td>
                                    <Form.Check
                                        type="checkbox"
                                        checked={selectedGroups?.some(u => u.id === group.id)}
                                        onChange={e => handleCheckboxChange(group, e.target.checked, e)}
                                    />
                                </td>
                                <td>{group.title}</td>
                                <td>{group.meta_desc}</td>
                                <td>{group.users.length}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Row>
    )
}
