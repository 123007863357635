import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, ButtonGroup, Card, Col, Nav, Row, Spinner, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import timeHelper from "../../utils/timeHelper";
import LiveSignals from "../../components/Signals/LiveSignals";
import ResultedSignals from "../../components/Signals/ResultedSignals";
import { Link } from "react-router-dom";

export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [activeTab, setActiveTab] = useState("live");

    const breadcrumbData = [
        {
            title: "Sinyaller",
            link: "/signals",
        },
        {
            title: "Listele",
            link: null,
        },
    ];


    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={"Sinyaller"}
                >

                </Beadcrumb>
                <Row className="g-3">
                    <Col className="mb-3">
                        <Link className="btn btn-primary" style={{ marginRight: 10 }} variant="success" to={"new"}>Yeni Sinyal Oluştur</Link>
                        {activeTab == "live" ? <Button className="btn btn-primary" variant="danger" onClick={() => setActiveTab("resulted")} >Geçmiş Sinyaller'i Listele</Button> :
                            <Button className="btn btn-primary" variant="success" onClick={() => setActiveTab("live")} >Canlı Sinyaller'i Listele</Button>}
                    </Col>
                </Row>
                <Row className="g-3 ">
                    {activeTab === "live" && <LiveSignals></LiveSignals>}
                    {activeTab === "resulted" && <ResultedSignals></ResultedSignals>}
                </Row>
                <Footer />
            </div>
        </React.Fragment >
    );
}
