import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Form, Placeholder, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { errorNotification, successNotification } from '../../utils/notification';
import apiService from '../../service/api.service';
import useBannersCategoryDetailData from '../../store/BannersCategoryDetailStore';

export default function AddNewBanner({
    category
}) {
    const fileInput = useRef(null);
    const [file, setFile] = useState();
    const [loading, setIsLoading] = useState(false);
    const [uploading, setUploading] = useState(false);

    const setBannerCategoryDetailData = useBannersCategoryDetailData((state) => state.setSelectedData);

    const [formValues, setFormValues] = useState({
        title: "",
        target: "",
    });

    const handleFileInput = (e) => {
        setFile(e.target.files[0]);
    }

    const handleDeleteFile = () => {
        setFile(null);
    }

    const getCategoryDetail = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.getDetailById("banners/by-id", category.id);
            setBannerCategoryDetailData(response.data);
        } catch (error) {
            console.log(error);
            errorNotification(error.message);
        } finally {
            setIsLoading(false);
        }
    }


    const handleUploadFile = async () => {
        if (file) {
            setIsLoading(true);
            setUploading(true);
            const formData = new FormData();
            formData.append("image", file);
            try {


                const emptyFields = [];

                if (formValues.title === "") {
                    emptyFields.push("Başlık");
                }
                if (formValues.target === "") {
                    emptyFields.push("Hedef URL");
                }


                if (emptyFields.length > 0) {
                    errorNotification(
                        `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                    );
                    setUploading(false);

                    return;
                }


                const uploadCDN = await apiService.uploadImage("upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                const createdImage = uploadCDN.data.image_url;

                await apiService.post("banners/new", {
                    category_id: category.id,
                    img_url: createdImage,
                    title: formValues.title,
                    target: formValues.target,
                });

                await getCategoryDetail();
                successNotification();
                setFile(null);
                setUploading(false);

            } catch (error) {
                errorNotification(
                    `\nDosya yüklenirken bir hata oluştu.\n${error.toString()}`
                );
            }
            setUploading(false);

            setIsLoading(false);
        } else {
            setUploading(false);
            errorNotification("\nLütfen görsel seçiniz.");
        }
    }

    return (
        <Card className="card-one" onClick={(e) => {
            fileInput.current && fileInput.current.click();
        }} style={{ cursor: 'pointer' }}>
            <Card.Body>
                {file &&
                    <>
                        <div className="mb-2 d-flex justify-content-between">
                            <div>
                                <strong>Yeni görsel ekle</strong>
                            </div>
                        </div>
                        <Card.Text>
                            <div style={{
                                position: "relative",
                            }}>
                                {uploading &&
                                    <div style={{ position: 'absolute', top: '46%', left: '46%' }}> <Spinner></Spinner> </div>
                                }
                                <Link to={file} target="_blank"> <img style={{ borderRadius: 5 }} src={URL.createObjectURL(file)} className="img-fluid" alt="" /></Link>
                            </div>
                        </Card.Text>
                        <div className="mb-2">
                            <Form.Label>
                                Başlık
                            </Form.Label>
                            <Form.Control type="text" placeholder="Başlık"
                                value={formValues.title}
                                disabled={uploading}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, title: e.target.value })
                                }
                            />
                        </div>
                        <div className="mb-2">
                            <Form.Label>
                                Hedef URL
                            </Form.Label>
                            <Form.Control type="text" placeholder="Hedef"
                                value={formValues.target}
                                disabled={uploading}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, target: e.target.value })
                                }
                            />
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button variant="light" onClick={handleDeleteFile} size="sm" className="btn-icon mx-2"><i class="ri-delete-bin-line"></i></Button>
                            <Button variant="primary" onClick={handleUploadFile} disabled={uploading} size="sm" className="btn-icon">
                                {uploading ? <Spinner size='sm'></Spinner> : <i class="ri-upload-cloud-2-line"></i>}
                            </Button>
                        </div>
                    </>
                }
                {!file &&
                    <div className="d-flex justify-content-center align-items-center" style={{ width: "100%", height: "100%" }}>
                        <div className="text-center">
                            <i class="ri-image-add-line" style={{ fontSize: 50 }}></i>
                            <Form.Control type="file" onChange={handleFileInput} style={{ display: 'none' }} ref={fileInput} />
                            <div className="mt-2"><strong>Yeni Banner Oluştur</strong></div>
                        </div>
                    </div>
                }
            </Card.Body>
        </Card >
    )
}
