import React, { useEffect, useState } from 'react'
import { Button, Card, Modal, Placeholder, Spinner } from 'react-bootstrap'
import apiService from '../service/api.service'
import usePriceCategoryStore from '../store/CategoryStore';
import Swal from 'sweetalert2';
import Select from "react-select";
import { successNotification } from '../utils/notification';
import CategoriesSidebarSingleEdit from './CategoriesSidebarSingleEdit';


export default function CategoriesSidebarSingle({ item }) {
    const [editCategory, setEditCategory] = useState(false);
    const handleEditCategory = () => setEditCategory(!editCategory);




    const zs_setCategoryData = usePriceCategoryStore((state) => state.setSelectedData);

    const categories = usePriceCategoryStore((state) => state.selectedData);

    const [transferCategory, setTransferCategory] = useState(false);
    const [selectOptions, setSelectOptions] = useState([]);
    const _handleTransferCategory = () => setTransferCategory(!transferCategory);



    // Seçilmiş kategori state'i
    const [selectedCategory, setSelectedCategory] = useState(null);


    // isLoadin?
    const [isBusy, setIsBusy] = useState(false);



    const transferPriceAnotherCategory = async (itemId) => {
        try {
            setIsBusy(true);
            // Seçilen kategori Idsi
            const newCategoryId = selectedCategory.value;
            // Aktarılmak istenen kategori idsi
            const oldCategoryId = itemId;

            console.log("oldCategoryId => ", oldCategoryId, "newCategoryId => ", newCategoryId)


            // Eski kategorinin tüm ürünlerini al
            const getCategoryPrices = await apiService.getDetailById("prices/by-category", oldCategoryId);

            // Eski kategorinin tüm ürünlerini yeni kategoriye aktar
            await getCategoryPrices.data.data.map(async (item) => {
                const transferPrice = await apiService.put("prices/update", item.id, { category_id: newCategoryId });
                console.log(transferPrice);
                // her işlem için 1 saniye bekle.
                await new Promise((resolve) => setTimeout(resolve, 1000));
            });

            await apiService.deleteItem(`prices-category/delete`, oldCategoryId);
            await Swal.fire(
                'Başarılı!',
                'İlgili içerik silinmiştir.',
                'success'
            )
            const renewCategoryData = await apiService.get("prices-category");
            zs_setCategoryData(renewCategoryData.data.data);
            _handleTransferCategory();
        } catch (error) {
            console.log(error.message);
        } finally {
            setIsBusy(false);
        }

    }

    const _handleDeleteTrigger = async (itemId) => {
        try {
            setIsBusy(true);
            const getCategoryPriceData = await apiService.getDetailById("prices/by-category", itemId);

            // Eğer ürün varsa kategori aktarımına gönder
            if (getCategoryPriceData.data.data.length > 0) {
                _handleTransferCategory();
                return;
            }

            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu yapacağın işlem geri alınamaz.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Evet, silmek istiyorum!'
            });

            if (acceptModal.isConfirmed) {
                await apiService.deleteItem(`prices-category/delete`, itemId);
                const renewCategoryData = await apiService.get("prices-category");
                zs_setCategoryData(renewCategoryData.data.data);
                successNotification("\n Kategori silinmiştir.")
            } else {
                throw new Error("Kategori silinemedi.");
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsBusy(false);
        }

    };

    useEffect(() => {
        const formatSelectOptions = categories.map((category) => {
            return {
                value: category.id,
                label: category.title,
            };
        });

        const filteredOptions = formatSelectOptions.filter(option => option.value !== item.id);
        setSelectOptions(filteredOptions);
    }, [categories, item.id]);
    return (
        <Card className='mb-2' style={{
            border: "none",
            borderBottom: '2px solid #fafafa',
            position: 'relative',
            background: editCategory && "#fafafa",
            padding: 10

        }}>
            {isBusy && <Spinner animation="border" role="status" style={{ zIndex: 99, position: 'absolute', top: "30%", left: "45%" }}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>}
            <Card.Body className='px-1'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <div className="title mb-1">
                            <div> <span style={{ marginBottom: 0, fontSize: 14 }}><strong>{item.title}</strong></span></div>
                        </div>
                        <div className="description">
                            <p style={{ marginBottom: 0 }}>{item.description}</p>
                        </div>
                        <small className='text-muted'>{item.createdAt}</small>
                        {transferCategory &&
                            <>
                                <div className='mt-3 mb-2 text-danger'>
                                    <small>İçindeki ürünleri aktarmamız gerek!</small>
                                </div>
                                <div className='d-flex align-items-center '>
                                    <div className=''>
                                        <Select
                                            options={selectOptions}
                                            placeholder="Aktarılacak kategori"
                                            defaultValue={null}
                                            onChange={(e) => setSelectedCategory(e)}
                                        />
                                    </div>
                                    <div>
                                        <Button variant='success' size='sm' className='mx-1' onClick={() => transferPriceAnotherCategory(item.id)}>
                                            <i class="ri-folder-transfer-line" ></i>
                                        </Button>
                                        <Button variant='warning' size='sm' onClick={_handleTransferCategory}>
                                            Vazgeç
                                        </Button>
                                    </div>
                                </div></>
                        }
                    </div>
                    <div>
                        <Button variant='danger' size='sm' className='mx-2' onClick={() => {
                            _handleDeleteTrigger(item.id)
                        }}>
                            <i class="ri-delete-bin-7-line" ></i>
                        </Button>
                        <Button variant='light' size='sm' active={editCategory} onClick={() => handleEditCategory(item.id)}>
                            <i class="ri-tools-line" ></i>
                        </Button>
                    </div>
                </div>
                {editCategory &&
                    <div className="">
                        <CategoriesSidebarSingleEdit categories={categories} handleEditCategory={handleEditCategory} item={item} />
                    </div >
                }
            </Card.Body>
        </Card >

    );
}
