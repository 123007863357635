import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Image, Spinner, Alert, Row, Col } from 'react-bootstrap';
import AnnouncementPost from './AnnouncementPost';
import apiService from '../service/api.service';

const StoryUpload = ({ onUploadSuccess, storyId, targetUpload }) => {
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState(null);


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('image', file);
            formData.append('storyId', storyId);
            formData.append('targetUpload', targetUpload);
            setLoading(true);
            setError(null);
            try {
                const response = await apiService.uploadImage("upload/story-upload", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                onUploadSuccess(response.data.image_url);
                setLoading(false);
            } catch (err) {
                setError("Error uploading the image");
                setLoading(false);
            }
        }
    };

    const handleRemove = () => {
        setPreview(null);
        onUploadSuccess(null);
    };

    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            {loading ? (
                <Spinner animation="border" variant="primary" />
            ) : (
                <>
                    {preview ? (
                        <div className=''>
                            <Row>
                                <Col md="4">
                                    <Image src={preview} thumbnail className='mb-3' />
                                </Col>
                                <Col md="8">
                                    <div className=''>
                                        <Alert>
                                            Görsel başarıyla yüklenmiştir
                                        </Alert>
                                        <Button variant="danger" size='sm' onClick={handleRemove}>Vazgeç/Sil</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <>
                            <Button variant="primary" size='sm' as="label">
                                Görsel Yükle
                                <input type="file" hidden onChange={handleFileChange} />
                            </Button>
                        </>

                    )
                    }
                </>
            )}
        </div >
    );
};

export default StoryUpload;
