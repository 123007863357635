import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Spinner, Table } from "react-bootstrap";
import { errorNotification } from "../utils/notification";
import apiService from "../service/api.service";
import timeHelper from "../utils/timeHelper";


export default function ActivityLogsTable({
  uuid,
}) {

  const [isLoading, setIsLoading] = useState(true);
  const [priceData, setPriceData] = useState([]);
  const [activityLogs, setActivityLogs] = useState([]);

  const checkActivityLogs = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.getDetailById("user/get-user-events", uuid);
      setActivityLogs(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    checkActivityLogs();
  }, [])

  // eslint-disable-next-line default-case
  return (
    <>
      {isLoading && <Spinner animation="border" role="status" style={{ zIndex: 99, position: 'absolute', top: "45%", left: "45%" }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>}

      <Table
        striped bordered hover
      >

        <thead>
          <tr>
            <th>Oluşturulma Tarihi</th>
            <th>İsim</th>
          </tr>
        </thead>
        <tbody  >
          {activityLogs.map((item, index) => (
            <tr style={{ verticalAlign: "middle" }} key={index} >
              <td width={200}>{timeHelper(item.createdAt)}</td>
              <td >
                {item.action}
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
    </>
  );
}
