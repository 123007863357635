import React, { useEffect, useState } from 'react'
import { Badge, Button, Form, Spinner, Table } from 'react-bootstrap'
import useWaitingNewsStore from '../../store/WaitingNewsStore';
import useWaitingNewPreviewStore from '../../store/WaitingNewPreviewStore';
import { errorNotification, successNotification } from '../../utils/notification';
import apiService from '../../service/api.service';
import { Link } from 'react-router-dom';
import timeHelper from "../../utils/timeHelper"
import WaitingNewEditModal from "../../components/WaitingNews/WaitingNewEditModal";
import useStore from '../../store/ModalStore';
import useCategoryData from '../../store/CategoryStore';
import Swal from 'sweetalert2';


export default function WaitingNewsTable({
  setIsSeenAll,
  triggerNewsList
}) {

  const showModal = useStore((state) => state.showModal);
  const setShowModal = useStore((state) => state.setShowModal);

  const [renewData, setRenewData] = useState(false);

  const [categories, setCategories] = useState([]);


  const closeModal = () => {
    setShowModal(false); // Zustand üzerinden modal'ı kapatın
  };

  const zs_setCategoryData = useCategoryData((state) => state.setSelectedData);
  const zs_getCategoryData = useCategoryData((state) => state.selectedData);

  const zs_useWaitingNewsStore = useWaitingNewsStore((state) => state.setSelectedData);
  const zs_getWaitingNewsStore = useWaitingNewsStore((state) => state.selectedData);

  const zs_useWaitingNewPreviewStore = useWaitingNewPreviewStore((state) => state.setSelectedData);
  const zs_getWaitingNewPreviewStore = useWaitingNewPreviewStore((state) => state.selectedData);

  const [isLoading, setIsLoading] = useState(false);

  const fetchNews = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get("news/created-by-api");

      zs_useWaitingNewsStore(response.data.data);
    } catch (err) {
      console.log(err)
      errorNotification(
        "\n" + err.toString() + ": News could not be fetched"
      );
    } finally {
      setIsLoading(false);
    }
  }
  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get("news-category");
      setCategories(response)
      zs_setCategoryData(response.data.data);
    } catch (err) {
      errorNotification(
        "\n" + err.toString() + ": Categories could not be fetched"
      );
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchNews();
    fetchCategories();
  }, [renewData]);




  useEffect(() => {
    console.log("tetiklendi")
    fetchNews();
    fetchCategories();
  }, [triggerNewsList]);


  useEffect(() => {
    console.log("setIsSeenAll triggered")
  }, [setIsSeenAll]);

  const setIsSeen = async (itemId, status) => {
    try {
      const result = await apiService.put(`news/update`, itemId, {
        is_seen: !status
      });
      if (status === true) {
        zs_useWaitingNewPreviewStore(null);
        return;
      }
      fetchNews();
      zs_useWaitingNewPreviewStore(result.data);
    } catch (err) {
      console.log(err);
      errorNotification();
    }
  }

  const setUpdateItem = async (itemId, status) => {
    try {
      const result = await apiService.put(`news/update`, itemId, {
        is_seen: !status
      });
      zs_useWaitingNewPreviewStore(result.data);
      setShowModal(true);
    } catch (err) {
      console.log(err);
      errorNotification();
    }
  }

  const handleDelete = async (index) => {
    try {


      const acceptModal = await Swal.fire({
        title: 'Emin misin?',
        text: "Bu yapacağın işlem geri alınamaz.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet, silmek istiyorum!'
      });

      if (acceptModal.isConfirmed) {
        try {
          await apiService.deleteItem("news/delete", index);
          Swal.fire(
            'Başarılı!',
            'İlgili içerik silinmiştir.',
            'success'
          )
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata oluştu...',
            text: 'Something went wrong!',
          })
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchNews();
    }
  }


  // Eğer is loading varsa yükleniyor yazısı döndür.
  return (
    <>
      {isLoading && <Spinner animation="border" role="status" style={{ zIndex: 99, position: 'absolute', top: "30%", left: "45%" }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>}
      {showModal && (
        <WaitingNewEditModal
          data={zs_getWaitingNewPreviewStore && zs_getWaitingNewPreviewStore}
          closeModal={closeModal}
          categories={zs_getCategoryData}
          setRenewData={setRenewData}
        />
      )}
      <Table className='table-hover'>
        <thead>
          <tr>
            <th>#</th>
            <th></th>
            <th>Haber</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {zs_getWaitingNewsStore?.map((news, index) =>
            <tr key={index} className={(!news.is_seen) ? "table-warning" : ""} style={{ cursor: 'pointer', verticalAlign: 'middle' }} >
              <td onClick={() => setIsSeen(news.id, news.is_seen)}>
                {news.id}
              </td>
              <td onClick={() => setIsSeen(news.id, news.is_seen)}>
                <Form.Check color='success' type="checkbox" checked={news.is_seen && true} onClick={() => setIsSeen(news.id, news.is_seen)} />
              </td>
              <td onClick={() => setIsSeen(news.id, news.is_seen)}>
                {
                  zs_getWaitingNewPreviewStore?.id === news.id ? (
                    <strong><div>
                      {news.title} <Badge>Önizleme</Badge>
                    </div></strong>
                  ) : (
                    <div>
                      {news.title}
                    </div>
                  )
                }

                <div>
                  <small className='text-muted'>{timeHelper(news.createdAt)}</small>
                </div>

              </td>
              <td onClick={() => setIsSeen(news.id, news.is_seen)}></td>
              <td className='text-end'>

                {news.is_active ? (
                  <Link className={news.is_active ? "text-muted" : ""} >Yayınlandı</Link>
                ) : (
                  <Button size='sm' onClick={() => {
                    setUpdateItem(news.id, news.is_seen)
                  }}>Yayınla</Button>

                )}
              </td>
              <td>
                <Button size='sm' variant='danger' onClick={() => {
                  handleDelete(news.id)
                }}><i class="ri-delete-bin-line"></i></Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
