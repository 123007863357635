import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Card, Col, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import useBannersCategoryData from '../../store/BannersCategoryStore';
import APIService from "../../service/api.service";
import timeHelper from "../../utils/timeHelper";

export default function Index() {
    const [isLoading, setIsLoading] = useState(false);

    const setBannersCategoryData = useBannersCategoryData((state) => state.setSelectedData);
    const bannersCategoryData = useBannersCategoryData((state) => state.selectedData);

    const getSectionData = async () => {
        setIsLoading(true);
        try {
            const response = await APIService.getByQuery("banners-category");
            console.log(response.data);
            setBannersCategoryData(response.data);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getSectionData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleOpenCategory = (id) => {
        window.location.href = `/banners/category/${id}`;
    }

    const breadcrumbData = [
        {
            title: "Bannerlar",
            link: "/customers",
        },
        {
            title: "Kategoriler",
            link: null,
        },
    ];


    if (isLoading || !bannersCategoryData) {
        return (
            <>Yükleniyor</>
        )
    }


    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={"Banner Kategorileri"}
                >

                </Beadcrumb>
                <Row className="g-3 ">
                    {bannersCategoryData.map((item, index) => (
                        <Col md="4" lg="4" sm="12" >
                            <Card className="card-one" style={{ cursor: 'pointer' }} onClick={() => {
                                handleOpenCategory(item.id)
                            }}>
                                <Card.Header style={{ display: 'flex', alignItems: 'center' }} >
                                    <div style={{ marginRight: 5 }}>
                                        {item.is_active ? <i class="ri-checkbox-blank-circle-fill text-success"></i> : <i class="ri-checkbox-blank-circle-line"></i>}
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <strong>{item.title}</strong>
                                        </div>
                                        <div>
                                            {timeHelper(item.createdAt)}
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <p style={{ margin: 0 }}>{item.description}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}


                </Row>

                <Footer />
            </div>
        </React.Fragment >
    );
}
