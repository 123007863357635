import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import CustomerGroupsCard from "../../components/CustomerGroups/CustomerGroupsCard";
import { Link } from "react-router-dom";
import { errorNotification, successNotification } from "../../utils/notification";
import toast from "react-hot-toast";

export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [busyModal, setBusyModal] = useState(false);
    const [data, setData] = useState([]);
    const [ungroupedData, setUngroupedData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [modalShow, setModalShow] = useState(false);

    const [formValues, setFormValues] = useState({
        title: "",
        meta_desc: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const createGroup = async () => {
        if (!formValues.title.trim()) {
            errorNotification("\n Grup adı girmeniz gerekiyor.")
            return;
        }

        try {
            setBusyModal(true);
            const group = await apiService.post("groups/create", formValues);
            if (selectedUsers.length > 0) {
                const savingUsers = async () => {
                    for (const user of selectedUsers) {
                        await apiService.post("user/set-group", {
                            customer_id: user.customer_id,
                            group_id: group.data.id
                        });
                    }
                }
                toast.promise(
                    savingUsers(),
                    {
                        loading: () => {
                            return "Grup kaydedildi, müşteriler gruba ekleniyor.."
                        },
                        success: () => {
                            setSelectedUsers([]);
                            return 'Müşteriler başarıyla gruba eklendi, grup oluşturuldu!';
                        },
                        error: (err) => {
                            return err.message;
                        },
                    },
                    {
                        position: "bottom-center"
                    }
                ).then(() => {
                    fetchGroupsWithUsers();
                    setModalShow(false);
                    console.log("İşlem tamam, api call atalım..")
                }).catch((error) => {
                    console.log(error);
                });
            }
            setSelectedUsers([]);
            successNotification("\n Grup başarıyla oluşturuldu!");
            fetchGroupsWithUsers();
            setModalShow(false);
            // const promise = await toast.promise()
        } catch (error) {
            console.log(error);
            errorNotification(`\n ${error.message}`)
        } finally {
            setBusyModal(false);
        }

    };

    useEffect(() => {
        fetchGroupsWithUsers();
    }, []);

    useEffect(() => {
        console.log(selectedUsers);
    }, [selectedUsers]);

    const fetchGroupsWithUsers = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.get(`groups/with-users`);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching groups with users:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const getUngroupedUsers = async () => {
        try {
            const response = await apiService.getByLimit(`user/get-ungrouped-users`, 1, 999999999);
            setUngroupedData(response.data);
        } catch (error) {
            console.error("Error fetching ungrouped users:", error);
        }
    }

    const handleCheckboxChange = (user, isChecked, e) => {
        e.stopPropagation();
        if (isChecked && !selectedUsers.some(u => u.customer_id === user.customer_id)) {
            setSelectedUsers(prevSelected => [...prevSelected, user]);
        } else if (!isChecked && selectedUsers.some(u => u.customer_id === user.customer_id)) {
            setSelectedUsers(prevSelected => prevSelected.filter(u => u.customer_id !== user.customer_id));
        }
    }

    const handleRowClick = (user) => {
        if (selectedUsers.some(u => u.customer_id === user.customer_id)) {
            setSelectedUsers(prevSelected => prevSelected.filter(u => u.customer_id !== user.customer_id));
        } else {
            setSelectedUsers(prevSelected => [...prevSelected, user]);
        }
    }

    const toggleSelectAll = (isChecked) => {
        if (isChecked) {
            setSelectedUsers(ungroupedData.data);
        } else {
            setSelectedUsers([]);
        }
    }

    const handleModalShow = async () => {
        await getUngroupedUsers();
        setModalShow(true);
    };

    const sortedData = ungroupedData?.data?.sort((a, b) => {
        const aIsSelected = selectedUsers.some(u => u.customer_id === a.customer_id);
        const bIsSelected = selectedUsers.some(u => u.customer_id === b.customer_id);

        if (aIsSelected && !bIsSelected) return -1;
        if (!aIsSelected && bIsSelected) return 1;
        return 0;
    });

    if (isLoading) return "Yükleniyor";

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Müşteriler", link: "/customers" },
                        { title: "Müşteri Grupları", link: null }
                    ]}
                    title={"Müşteri Grupları"}
                />
                <Row className="mb-3">
                    <Col>
                        <Button variant="success" onClick={handleModalShow}>Yeni Grup Oluştur</Button>
                    </Col>
                </Row>
                <Row className="g-3">
                    {data.map(item => (
                        <Col md="4" sm="6" lg="4" key={item.id}>
                            <CustomerGroupsCard data={item} />
                        </Col>
                    ))}
                </Row>
                <Footer />
            </div>
            <Modal className="modal-event" show={modalShow} onHide={() => setModalShow(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Yeni Grup Oluştur</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <Form.Label >Grup Adı</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Grubun adı"
                            name="title"
                            value={formValues.title}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <Form.Label >Grup Açıklaması</Form.Label>
                        <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="Kısa açıklama"
                            name="meta_desc"
                            value={formValues.meta_desc}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="groups-header d-flex justify-content-between px-2 mb-2">
                        <div className="title text-dark">
                            Grup'a atamak istediğiniz kullanıcılar

                        </div>
                        <div className="buttons d-flex align-items-center">
                            <div>
                                <Form className="d-flex" onSubmit={(e) => e.preventDefault()}>
                                    <Form.Control
                                        type="search"
                                        placeholder="Müşteri Ara"
                                        className="me-2"
                                        size='sm'
                                        aria-label="Müşteri Ara"
                                        value={searchTerm}
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </Form>
                            </div>
                            {selectedUsers.length > 0 && (
                                <div className="text-danger" style={{ marginRight: 10 }} onClick={() => toggleSelectAll(false)} >Seçimleri Kaldır ({selectedUsers.length})</div>
                            )}
                            {ungroupedData?.data?.length && selectedUsers.length === ungroupedData.data.length ? (
                                <div className="text-primary" onClick={() => toggleSelectAll(false)} >Seçimleri Kaldır</div>
                            ) :
                                <div className="text-primary" onClick={() => toggleSelectAll(true)}>Hepsini Seç</div>
                            }
                        </div>
                    </div>
                    <div style={{ height: 400, overflowY: 'auto', outline: '2px solid #fafafa' }}>
                        <Table className="mb-0" hover size="sm">
                            <thead style={{ position: 'sticky', top: 0, background: '#f2f2f2' }}>
                                <tr>
                                    <th>
                                        <Form.Check
                                            type="checkbox"
                                            checked={ungroupedData?.data?.length && selectedUsers.length === ungroupedData.data.length}
                                            onChange={e => toggleSelectAll(e.target.checked)}
                                        />
                                    </th>
                                    <th>Müşteri Adı ve Soyadı</th>
                                    <th>E-Posta Adresi</th>
                                    <th>Telefon Numarası</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData?.filter(user =>
                                    user.customer_fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    user.customer_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    user.customer_phone.includes(searchTerm)
                                ).map(user => (
                                    <tr key={user.customer_id} onClick={() => handleRowClick(user)} style={{ cursor: 'pointer' }}
                                        className={selectedUsers.some(u => u.customer_id === user.customer_id) ? "table-success" : ""}>
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                checked={selectedUsers.some(u => u.customer_id === user.customer_id)}
                                            />
                                        </td>
                                        <td>{user.customer_fullname}</td>
                                        <td>{user.customer_email}</td>
                                        <td>{user.customer_phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>Vazgeç</Button>
                    <Button variant="success" onClick={createGroup}>Grubu Oluştur</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
}
