import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ImageUpload from "../../components/ImageUpload";


export default function Index() {
    const navigate = useNavigate();
    const { itemid } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formValues, setFormValues] = useState({
        title: "",
        short_description: "",
        url: "",
    });

    const [firstImageURL, setFirstImageURL] = useState('');


    const apiCall = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.get(`bulletins/detail/${itemid}`);
            console.log(response.data);

            setData(response.data);
            setFirstImageURL(response.data.url ? response.data.url : "")
            setIsLoading(false);

            setFormValues({
                title: response.data.title,
                short_description: response.data.short_desc,
                firstImageURL: response.data.url,
            });

        } catch (error) {
            console.log(error)
            errorNotification()
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        console.log("api call handled")
        apiCall();
    }, []);



    if (isLoading) {
        return "Yükleniyor";
    }



    const handleSubmit = async () => {
        try {

            const emptyFields = [];
            if (firstImageURL === null || firstImageURL === "") {
                emptyFields.push("Lütfen bülten seçiniz.");
            }
            if (formValues.title === "") {
                emptyFields.push("Lütfen başlık giriniz.");
            }
            if (formValues.short_description === "") {
                emptyFields.push("Lütfen kısa açıklama giriniz.");
            }
            if (formValues.short_description.length < 10) {
                emptyFields.push("Açıklama en az 10 karakter olmalıdır.");
            }


            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")}.`
                );
                return;
            }


            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu duyuru düzenlenecektir.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Yayınla!'
            });
            if (acceptModal.isConfirmed) {
                const generatedData = {
                    title: formValues.title,
                    url: firstImageURL,
                    short_description: formValues.short_description,
                }
                try {
                    await apiService.put(`bulletins/update`, itemid, generatedData);
                    successNotification(`\n Bülten güncellendi`);
                } catch (e) {
                    console.log(e);
                    errorNotification(`Bülten güncellenirken bir hata oluştu!`)
                }



            }
        } catch (err) {
            console.log(err);

        } finally {
            console.log("form datası hazır.")
        }
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Bülten Yönetimi", link: "/bulletins" },
                        { title: `Bülteni Düzenle`, link: null }
                    ]}
                    title={`Bülteni Düzenle`}
                />

                <Form onSubmit={(e) => e.preventDefault}>
                    <Row className="g-3 d-flex ">
                        <Col md="7">
                            <Card className="card-one">
                                <Card.Header>
                                    <Card.Title as="h6"> Bülten düzenle/detayları</Card.Title>
                                    <Nav className="nav-icon nav-icon-sm ms-auto">
                                        <Nav.Link><i className="ri-refresh-line"></i></Nav.Link>
                                        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            <div className="mb-3 card">
                                                <div className="card-header fw-bold">Bülten Yükle</div>
                                                <div className="card-body">
                                                    <p>PDF formatında olmak zorundadır.</p>
                                                    <ImageUpload
                                                        onUploadSuccess={(url) => setFirstImageURL(url)}
                                                        imgUrl={data.img_url}
                                                        isPdf={true}
                                                    />
                                                    {firstImageURL && (
                                                        <div className="mt-3">
                                                            <Form.Label >Bülten URL <Link style={{ fontWeight: 'bold' }} target="_blank" to={firstImageURL}>Görüntüle</Link> </Form.Label>
                                                            <Form.Control readOnly={true} disabled value={firstImageURL} />

                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="mb-3">
                                                <Form.Label >Başlık</Form.Label>
                                                <Form.Control placeholder="Başlık giriniz" value={formValues.title} onChange={(e) => {
                                                    setFormValues({ ...formValues, title: e.target.value })
                                                }} />

                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="mb-3">
                                                <Form.Label >Kısa Açıklama ( <strong>{formValues.short_description.length}</strong> / 254 )</Form.Label>
                                                <Form.Control as="textarea" rows={2} maxLength={254} placeholder="Kısa Açıklama" value={formValues.short_description} onChange={(e) => {
                                                    setFormValues({ ...formValues, short_description: e.target.value })
                                                }} />

                                            </div>
                                        </Col>

                                    </Row>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-end">
                                    <Button variant="success" onClick={handleSubmit}>Bülteni Düzenle</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col md="5">
                            <Card className="card-one">
                                <Card.Header>
                                    <Card.Title as="h6"> Önizleme</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <iframe width="100%" height="100%" src={firstImageURL} frameborder="0"></iframe>
                                </Card.Body>

                            </Card>
                        </Col>



                    </Row>
                </Form>
                <Footer />
            </div>
        </React.Fragment >
    );
}
