import React, { useEffect, useState } from 'react'
import { Alert, Badge, Button, Card, Col, Form, Row, } from 'react-bootstrap'
import Select from "react-select";

import apiService from '../../service/api.service';
import { errorNotification, successNotification } from '../../utils/notification';


export default function WaitingNewSettingsCard() {

  const [isLoading, setIsLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [settings, setSettings] = useState([]);
  const [open, setOpen] = useState(false);
  const [autoPost, setAutoPost] = useState(false);


  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get("news-category");
      setSelectOptions(response.data.data)
      setSelectOptions(
        response.data.data.map((category) => {
          return {
            value: category.id,
            label: category.title,
          };
        })
      );
    } catch (err) {
      errorNotification(
        "\n" + err.toString() + ": Categories could not be fetched"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const getSettings = async () => {
    try {
      setIsLoading(true);
      const response = await apiService.get("settings/detail");
      setAutoPostCategory(response.data.auto_post_category_id)
      setAutoPost(response.data.auto_post);
      setSettings(response.data);
    } catch (err) {
      console.log(err)
      errorNotification(
        "\n" + err.toString() + ": Categories could not be fetched"
      );
    } finally {
      setIsLoading(false);
    }
  };
  const [autoPostCategory, setAutoPostCategory] = useState(null);

  const _handleAutoPost = async () => {
    try {
      if (autoPostCategory == null || autoPostCategory == 0) {
        errorNotification("Lütfen bir kategori seçiniz");
        return;
      }

      setIsLoading(true);
      const response = await apiService.put("settings/update", 1, {
        auto_post: !autoPost ? 1 : 0,
      });
      setSettings(response.data);
      setAutoPost(!autoPost); // Güncellenmiş autoPost değerini kullan
      successNotification();
    } catch (error) {
      setIsLoading(false);
      errorNotification();
    } finally {
      setIsLoading(false);
    }
  };

  const getCategory = (categoryId) => {
    return selectOptions.find((item) => item.value === categoryId) || "-";
  };


  const handleCategoryChange = async (selectedOption) => {
    try {
      setIsLoading(true);
      const response = await apiService.put("settings/update", 1, {
        auto_post_category_id: autoPostCategory,
      });
      setAutoPostCategory(autoPostCategory);
      setSettings(response.data);

      successNotification()
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      errorNotification()
    } finally {
      setIsLoading(false);

    }
  }


  useEffect(() => {
    fetchCategories()
    getSettings();
  }, []);


  if (isLoading) {
    return "Yükleniyor."
  }



  return (
    <>
      <Card className="mb-3" >
        <Card.Header>
          <div style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
            {autoPost ? <Badge bg='success'>Açık</Badge> : <Badge bg='danger'>Kapalı</Badge>}
            Otomatik Paylaşım </div>
        </Card.Header>
        <Card.Body style={{
          display: open ? "block" : "none",
        }}>
          <Row>
            <Col md="12">
              Otomatik paylaşım   {autoPost ? "Açık" : "Kapalı"}
              {
                autoPost ? (
                  <>
                    . Atanan kategori <strong>{getCategory(settings.auto_post_category_id).label}</strong>
                  </>
                ) : (
                  ""
                )
              }
              <hr />
            </Col>
            <Col md="12">
              <div className="">
                <Form.Label>Oto Post</Form.Label>
                <Form.Check
                  checked={autoPost}
                  onChange={_handleAutoPost}
                  value={autoPost}
                  type="switch"
                  label="Otomatik Paylaşım" />
                <br />
                <div className='d-flex align-items-center '>
                  <div className=''>
                    <Select
                      options={selectOptions}
                      placeholder="Otomatik Post Kategorisi"
                      defaultValue={null}
                      style={{ width: 200 }}
                      value={selectOptions.find(
                        (option) => option.value === autoPostCategory
                      )}
                      onChange={(selectedOption) => {
                        setAutoPostCategory(selectedOption.value);
                      }
                      }
                    />
                  </div>
                  <div>
                    <Button variant='success' size='sm' className='mx-1' onClick={() => handleCategoryChange()}>
                      <i class="ri-check-line"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card >
    </>
  )
}
