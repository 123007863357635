import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Spinner, Table } from "react-bootstrap";
import { errorNotification } from "../utils/notification";
import apiService from "../service/api.service";
import timeHelper from "../utils/timeHelper";


export default function WatchlistTable({
  rows,
  options,
  onDeleteTrigger,
  isRenewData,
}) {

  const [isLoading, setIsLoading] = useState(true);

  const [priceData, setPriceData] = useState([]);

  const getPriceData = async () => {
    try {
      const response = await apiService.getByQuery("prices");
      setPriceData(response.data.data);
    } catch (error) {
      console.log(error);
      errorNotification("Fiyat datası yüklenirken bir hata oluştu")
    } finally {
      setIsLoading(false);
    }

  }


  // Price data içerisinde gelen arrayi bekledikten sonra bu kodun çalışması gerek

  const findWatchlistPrices = (product) => {
    try {

      console.log(priceData);
      const result = priceData.find((item) => item.product_name === product);
      return result;

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    console.log("Fiyat datası yükleniyor")
    getPriceData();
  }, [])

  // eslint-disable-next-line default-case
  return (
    <>
      {isLoading && <Spinner animation="border" role="status" style={{ zIndex: 99, position: 'absolute', top: "45%", left: "45%" }}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>}

      <Table
        bordered={options && options.bordered}
        borderless={false}
        size={options && options.size}
        hover={options && options.hover}
        className="mb-0"
        style={isRenewData ? { filter: "blur(5px)", pointerEvents: "none", position: 'relative', transition: '0.2s all', zIndex: 10 } : { filter: "blur(0px)", transition: '0.2s all' }}
      >

        <thead>
          <tr>
            <th>İsim</th>
            <th>Eklenme Tarihi</th>
          </tr>
        </thead>
        <tbody  >
          {rows.map((item, index) => (

            <tr style={{ verticalAlign: "middle" }} key={index} >
              <td >
                {item.item}
              </td>
              <td>{timeHelper(item.createdAt)}</td>
              <td style={{ textAlign: "end" }}>
                <ButtonGroup aria-label="Basic example">
                  <Button
                    variant="danger"
                    className="btn-icon"
                    size="sm"
                    onClick={() => onDeleteTrigger(index)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
    </>
  );
}
