import React, { useEffect, useState } from "react";
import apiService from "../../../service/api.service";
import { errorNotification } from "../../../utils/notification";
import Beadcrumb from "../../../components/Beadcrumb";
import Footer from "../../../layouts/Footer";
import Header from "../../../layouts/Header";
import { Card, Col, Row } from "react-bootstrap";
import WaitingNewsList from "../../../components/WaitingNews/WaitingNewsList";
import WaitingNewPreviewCard from "../../../components/WaitingNews/WaitingNewPreviewCard";
import WaitingNewSettingsCard from "../../../components/WaitingNews/WaitingNewSettingsCard";

export default function Index() {
  const [data, setData] = useState(null);
  const breadcrumbData = [
    {
      title: "Haberler",
      link: "/news",
    },
    {
      title: "Bekleyen Haberler",
      link: null,
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Beadcrumb
          data={breadcrumbData}
          title={"Bekleyen Haberler"}
        ></Beadcrumb>
        <Row className="g-3 ">
          <WaitingNewsList></WaitingNewsList>
          <WaitingNewPreviewCard></WaitingNewPreviewCard>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
