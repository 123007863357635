import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import Select, { components } from "react-select";
import apiService from "../../service/api.service";
import axios from "axios";
import { errorNotification, successNotification } from "../../utils/notification";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import ImageUpload from "../../components/ImageUpload";

export default function Index() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [sendNotification, setSendNotification] = useState(true);
    const [formValues, setFormValues] = useState({
        title: "",
        short_title: "",
        type: "",
        content: "",
    });

    const [firstImageURL, setFirstImageURL] = useState('');


    useEffect(() => {
    }, []);
    const selectOptions = [
        {
            value: "DUYURU",
            label: "DUYURU"
        },
        {
            value: "KAMPANYA",
            label: "KAMPANYA"
        }

    ]


    if (isLoading) {
        return "Yükleniyor";
    }



    const handleSubmit = async () => {
        try {

            const emptyFields = [];
            if (formValues.title === "") {
                emptyFields.push("Başlık");
            }
            if (formValues.short_title === "") {
                emptyFields.push("Kısa Başlık");
            }
            if (formValues.type === "") {
                emptyFields.push("Tip");
            }
            if (formValues.content === "") {
                emptyFields.push("İçerik");
            }

            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
                );
                return;
            }


            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu duyuru yayınlanacaktır.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Yayınla!'
            });
            if (acceptModal.isConfirmed) {
                const generatedData = {
                    title: formValues.title,
                    short_title: formValues.short_title,
                    type: formValues.type.value,
                    content: formValues.content,
                    img: firstImageURL
                }
                try {
                    console.log(generatedData);
                    await apiService.post("announcements/new", generatedData);
                    navigate('/announcements', { replace: true });
                    successNotification(`\n Başarıyla duyuru yayınlandı`);
                } catch (e) {
                    console.log(e);
                    errorNotification(`Duyuru paylaşılırken bir hata oluştu!`)
                }



            }
        } catch (err) {
            console.log(err);

        } finally {
            console.log("form datası hazır.")
        }
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Duyuru & Kampanya", link: "/announcements" },
                        { title: "Yeni Oluştur", link: null }
                    ]}
                    title={"Yeni Oluştur"}
                />

                <Form onSubmit={(e) => e.preventDefault}>
                    <Row className="g-3 d-flex ">
                        <Col md="12">
                            <Card className="card-one">
                                <Card.Header>
                                    <Card.Title as="h6">Yeni Oluştur</Card.Title>
                                    <Nav className="nav-icon nav-icon-sm ms-auto">
                                        <Nav.Link><i className="ri-refresh-line"></i></Nav.Link>
                                        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            <div className="mb-3 card">
                                                <div className="card-header fw-bold">Ana görsel</div>
                                                <div className="card-body">
                                                    <p>Bu ana görsel için geçerlidir.</p>
                                                    <ImageUpload onUploadSuccess={(url) => setFirstImageURL(url)} ></ImageUpload>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md="4">
                                            <div className="mb-3">
                                                <Form.Label >Kısa Başlık</Form.Label>
                                                <Form.Control placeholder="Kısa Başlık giriniz" value={formValues.short_title} onChange={(e) => {
                                                    setFormValues({ ...formValues, short_title: e.target.value })
                                                }} />

                                            </div>
                                        </Col>

                                        <Col md="4">
                                            <div className="mb-3">
                                                <Form.Label >Başlık</Form.Label>
                                                <Form.Control placeholder="Başlık giriniz" value={formValues.title} onChange={(e) => {
                                                    setFormValues({ ...formValues, title: e.target.value })
                                                }} />

                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Form.Label >Tip Seçiniz</Form.Label>
                                                <Select
                                                    options={selectOptions}
                                                    placeholder="Tip Seçiniz"
                                                    defaultValue={null}
                                                    value={formValues.type}
                                                    onChange={(selectedOption) =>
                                                        setFormValues({ ...formValues, type: selectedOption })
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <Form.Label >İçerik</Form.Label>
                                            <ReactQuill
                                                theme="snow"
                                                value={formValues.content}
                                                onChange={(e) =>
                                                    setFormValues({ ...formValues, content: e })
                                                }
                                            />
                                        </Col>

                                    </Row>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-end">
                                    <Button variant="success" onClick={handleSubmit}>Duyuruyu Yayınla</Button>
                                </Card.Footer>
                            </Card>
                        </Col>

                    </Row>
                </Form>
                <Footer />
            </div>
        </React.Fragment >
    );
}
