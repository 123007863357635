import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import useAuthStore from "./store/AuthStore";

import "./assets/css/remixicon.css";
import "./scss/style.scss";
import apiService from "./service/api.service";

const checkAuthToken = async (token) => {
  try {
    const data = await apiService.post("admin/check-auth-token", {
      token: token,
    });
    if (data) {
      console.log(data);
    } else {
      useAuthStore.getState().logout();
    }
  } catch (error) {
    console.error("Token kontrolü sırasında bir hata oluştu:", error);
  }
};

export default function App() {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const token = useAuthStore((state) => state.token);
  useEffect(() => {
    checkAuthToken(token);
  }, [token, isLoggedIn]);

  const requireLogin = (component) => {
    return isLoggedIn ? component : <Navigate to="/login" />;
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          {publicRoutes.map((route, index) => (
            <Route path={route.path} element={route.element} key={index} />
          ))}
          {protectedRoutes.map((route, index) => (
            <React.Fragment>
              <Route path="/" element={requireLogin(<Main />)}>
                <Route path={route.path} element={requireLogin(route.element)} key={index} />
              </Route>
            </React.Fragment>
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}
