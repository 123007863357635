import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Card, Col, Form, Modal, Nav, Row, Spinner, Table } from "react-bootstrap";
import apiService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import timeHelper from "../../utils/timeHelper";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function ResultedSignals() {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [resultData, setResultData] = useState(false);
    const [resultSendNotification, setResultSendNotification] = useState(false);
    const [notificationDetail, setNotificationDetail] = useState([]);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const getLiveSignals = async () => {
        try {
            setIsLoading(true);
            const response = await apiService.get('signals/get-resulted?limit=99999');
            setData(response.data.data);
            successNotification("\nVeriler yüklendi.");
        } catch (err) {
            errorNotification(`\n${err.message}`);
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDelete = async (id) => {
        try {

            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu yapacağın işlem geri alınamaz.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Evet, silmek istiyorum!'
            });

            if (acceptModal.isConfirmed) {
                try {
                    await apiService.deleteItem("signals/delete", id);
                    successNotification();
                    await getLiveSignals();
                    return;
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Bir hata oluştu...',
                        text: 'Something went wrong!',
                    })
                }
            }
        } catch (error) {
            console.log(error);
            errorNotification("\nKonsolu kontrol edin.")
        }
    }

    const handleResultItem = async (resultType) => {

        console.log(resultData);

        const generatedData = {
            result: resultType,
            result_type: resultType === "SUCCESS" ? "TP" : "SL",
        };

        // Bildirimsiz gönderilecek bunun için öncelikle bir onay
        if (!resultSendNotification) {
            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu sinyal sonuçlandırması için kullanıcılara bildirim gönderilmeyecek!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Dur, bildirim göndereceğim!',
                confirmButtonText: 'Evet, devam edelim!'
            });
            if (acceptModal.isConfirmed) {
                try {
                    setLoadingModal(true);
                    await apiService.put("signals/update-to-resulted", resultData.id, generatedData);
                    handleClose();
                    successNotification();
                    await getLiveSignals();
                    return;
                } catch (e) {
                    console.log(e);
                    errorNotification("Konsolu kontrol edin.");
                    return;
                } finally {
                    setLoadingModal(false);
                }
            }
            if (!acceptModal.isConfirmed) {
                console.log("Bildirim gönderileceği için onay reddedildi.");
                setResultSendNotification(true);
                setLoadingModal(false);
                return;
            }
        }
        try {
            setLoadingModal(true);
            const generatedDataWithNotification = {
                ...generatedData,
                notificate: resultSendNotification,
                notification_title: notificationDetail.title,
                notification_body: notificationDetail.body
            }
            await apiService.put("signals/update-to-resulted", resultData.id, generatedDataWithNotification);
            handleClose();
            successNotification();
            await getLiveSignals();
            setLoadingModal(false);
            return;
        } catch (e) {
            console.log(e);
            errorNotification("Konsolu kontrol edin.");
            setLoadingModal(false);
            return;
        }
        finally {
            setLoadingModal(false);
        }

    }


    useEffect(() => {
        getLiveSignals();
    }, []);

    if (isLoading) {
        return (
            <Col xs="12">
                <Card className="card-one">
                    <Card.Header>
                        <Card.Title as="h6">Geçmiş Sinyaller</Card.Title>
                        <Nav className="nav-icon nav-icon-sm ms-auto">
                            <Nav.Link onClick={getLiveSignals}><i className="ri-refresh-line"></i></Nav.Link>
                            <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                        </Nav>
                    </Card.Header>
                    <Card.Body className="px-3 pt-2">
                        <Spinner animation="border" variant="dark" />
                    </Card.Body>
                </Card>
            </Col>

        )
    }
    return (
        <Col xs="12">
            <Card className="card-one border border-info">
                <Card.Header>
                    <Card.Title as="h6">Geçmiş Sinyaller</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link onClick={getLiveSignals}><i className="ri-refresh-line"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                    </Nav>
                </Card.Header>
                <Card.Body className="px-3 pt-2">
                    <Table className=" mb-0" striped responsive style={{ verticalAlign: 'middle' }}>
                        <thead>
                            <tr>
                                <th>Ürün</th>
                                <th>Ürün Adı <br />
                                    <small>
                                        Detaylı Adı
                                    </small>
                                </th>
                                <th>Gruplar Hariç</th>
                                <th>Giriş Fiyatı</th>
                                <th>Take Profit <br /> <small>Kar Al</small></th>
                                <th>Stop Loss <br /> <small>Zarar Durdur</small></th>
                                <th>Tarih</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} className="bg-light">
                                    <td width={100}>
                                        <div className="d-flex">
                                            <img src={item.priceDetail.img_url} alt="" width={30} />
                                            {item.priceDetail.cross_img_url && (
                                                <img src={item.priceDetail.cross_img_url} alt="" width={30} />
                                            )} {item.id}
                                        </div>
                                    </td>
                                    <td width={220}>
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <h6 className="mb-0">{item.priceDetail.product_name} <small className="text-danger">{item.priceDetail.meta_currency_name}</small></h6>
                                                <span className="fs-xs text-secondary">{item.priceDetail.title}</span>
                                            </div>
                                            <div>
                                                {item.type == "BUY" ? <Badge bg="success" pill>{item.type}</Badge> : <Badge bg="danger" pill>{item.type}</Badge>}
                                            </div>
                                        </div>
                                    </td>
                                    <td> {item.disallowedGroups.map(group => (
                                        <Badge key={group.id} variant="dark" className="mr-2">
                                            {group.group_title}
                                        </Badge>
                                    ))}</td>
                                    <td width={150}><span className="ff-numerals" >{item.entry_price}</span></td>
                                    <td width={150}><span className="ff-numerals" >{item.tp}</span></td>
                                    <td width={150}><span className="ff-numerals" >{item.sl}</span></td>
                                    <td><span className="text-muted">{timeHelper(item.createdAt)}</span></td>
                                    <td>
                                        <ButtonGroup aria-label="Basic example">
                                            <Link to={`edit/${item.uuid}`} className="btn btn-primary btn-sm" variant="light" size="sm">Düzenle</Link>
                                            <Button variant="danger" size="sm" onClick={() => handleDelete(item.id)}>Sil</Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {resultData && <Modal show={showModal} onHide={handleClose} centered>
                        <Modal.Header  >
                            <Modal.Title>Canlı Sinyal Sonuçlandırma Ekranı</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: 20, position: 'relative', filter: loadingModal ? "blur(1px)" : "none" }}>
                            {loadingModal && <div style={{
                                position: 'absolute',
                                top: "47%",
                                left: "47%",
                            }}>
                                <Spinner animation="border" variant="dark" />

                            </div>}
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="item-detail-area d-flex align-items-center">
                                    <div className="image-area d-flex align-items-center" style={{ marginRight: 10 }}>
                                        <div>
                                            <img src={resultData.priceDetail.img_url} style={{ borderRadius: "50%", marginRight: 5 }} className="img-fluid" alt="" width={40} />
                                        </div>
                                        {resultData.priceDetail.cross_img_url &&
                                            <div>
                                                <img src={resultData.priceDetail.cross_img_url} style={{ borderRadius: "50%" }} className="img-fluid" alt="" width={40} />
                                            </div>
                                        }
                                    </div>
                                    <div className="product-detail" >
                                        <div className="title" style={{ fontSize: 18 }}>
                                            <strong>{resultData.priceDetail.product_name}</strong> <small>{resultData.priceDetail.meta_currency_name}</small>
                                        </div>
                                        <div className="sub text-muted">
                                            {resultData.priceDetail.title}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <strong style={{ fontSize: 18 }}>
                                        {resultData.type === "BUY" ? <><Spinner style={{ marginRight: 10 }} animation="grow" variant="success" size="sm" /><span className="text-success">BUY</span></> : <><Spinner style={{ marginRight: 10 }} animation="grow" variant="danger" size="sm" /><span className="text-danger">SELL</span></>}
                                    </strong>
                                </div>
                            </div>
                            <Row className="d-flex justify-content-center text-center mt-3">
                                <Col md="4">
                                    <div className="single">
                                        <div className="title" style={{ fontWeight: 'bold', marginBottom: 10 }}>
                                            Giriş Fiyatı
                                        </div>
                                        <div style={{ fontSize: 18 }}>
                                            {resultData.entry_price}
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="single">
                                        <div className="title" style={{ fontWeight: 'bold', marginBottom: 10 }}>
                                            Take Profit
                                        </div>
                                        <div style={{ fontSize: 18 }}>
                                            {resultData.tp}
                                        </div>
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="single">
                                        <div className="title" style={{ fontWeight: 'bold', marginBottom: 10 }}>
                                            Stop Loss
                                        </div>
                                        <div style={{ fontSize: 18 }}>
                                            {resultData.sl}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="12">
                                    <Form.Check type="switch"
                                        checked={resultSendNotification}
                                        onChange={() => setResultSendNotification(!resultSendNotification)}
                                        label="Kullanıcılara bildirim göndermek istiyorum" />
                                </Col>
                                {resultSendNotification &&
                                    <>
                                        <Col md="12 mt-2">
                                            <Form.Label>Bildirim Başlığı</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={notificationDetail.title}
                                                onChange={(e) => setNotificationDetail({ ...notificationDetail, title: e.target.value })} />
                                        </Col>
                                        <Col md="12 mt-2">
                                            <Form.Label>Bildirim İçeriği</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="2"
                                                type="text"
                                                value={notificationDetail.body}
                                                onChange={(e) => setNotificationDetail({ ...notificationDetail, body: e.target.value })}
                                            />
                                        </Col>
                                    </>
                                }

                            </Row>
                            <Row className="mt-3">
                                <Col md="6">
                                    <Button variant="success" disabled={loadingModal} onClick={() => handleResultItem("SUCCESS")} className="w-100">Sinyali Başarıyla Sonlandır</Button>
                                </Col>
                                <Col md="6">
                                    <Button variant="danger" disabled={loadingModal} onClick={() => handleResultItem("FAILED")} className="w-100">Sinyali Zarar ile Sonlandır</Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="warning" disabled={loadingModal} onClick={handleClose}>
                                Vazgeç
                            </Button>
                        </Modal.Footer>
                    </Modal>}
                </Card.Body>
            </Card>
        </Col>
    )
}
