import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import APIService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import Swal from 'sweetalert2'
import NewsModal from "../../components/NewsModal.jsx";
import useStore from '../../store/ModalStore';
import usePriceCategoryStore from '../../store/CategoryStore';
import PricesTable from "../../components/PricesTable";
import Select from "react-select";
import EditPriceModal from "../../components/EditPriceModal";
import NewPriceSidebar from "../../components/NewPriceSidebar";
import CategoriesSidebar from "../../components/CategoriesSidebar";

export default function Index() {
  const [isLoading, setIsLoading] = useState(true);
  const [isRenewData, setIsRenewData] = useState(true);
  const [data, setData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [currentLimit, setCurrentLimit] = useState(99999);
  const [show, setShow] = useState(false);
  const [renewData, setRenewData] = useState(false);
  const [categoriesData, setCategoriesData] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const handleClose = () => setShow(false);
  const handleRenewData = () => setRenewData(true);
  const showModal = useStore((state) => state.showModal);
  const selectedData = useStore((state) => state.selectedData);
  const setShowModal = useStore((state) => state.setShowModal);

  const zs_setCategoryData = usePriceCategoryStore((state) => state.setSelectedData);
  const zs_getCategoryData = usePriceCategoryStore((state) => state.selectedData);


  const [showCategories, setShowCategories] = useState(false);
  const handleCategories = () => setShowCategories(!showCategories);

  const _handleFilter = async () => {
    setIsRenewData(true)
    const queryParams = new URLSearchParams();
    for (const [key, value] of Object.entries(formValues)) {
      if (value || value === 0) {
        if (key === "category") {
          queryParams.append(key, value.value);
        } else {
          queryParams.append(key, value);
        }
      }
    }
    const query = queryParams.toString();

    try {
      // const leads = await getLeadsByParameters(query);
      const response = await APIService.getByQuery("prices/by-query", query);
      setContentData(response.data);
      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsRenewData(false);
    }
  }

  const resetForm = () => {
    setFormValues({
      product_name: "",
      title: "",
      meta_currency_name: "",
      category: null
    });
    setRenewData(true);
  };


  const closeModal = () => {
    setShowModal(false); // Zustand üzerinden modal'ı kapatın
  };

  const [formValues, setFormValues] = useState({
    product_name: "",
    title: "",
    meta_currency_name: "",
    category: null
  });


  const fetchCategories = async () => {
    try {
      const response = await APIService.getByQuery("prices-category/by-query");
      zs_setCategoryData(response.data);
    } catch (err) {
      console.log(err);
      errorNotification(
        "\n" + err.toString() + ": Categories could not be fetched"
      );
    }
  };

  useEffect(() => {
    _handleFilter();
    fetchCategories();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (zs_getCategoryData) {
      setSelectOptions(
        zs_getCategoryData.map((category) => {
          return {
            value: category.id,
            label: category.title,
          };
        })
      );
    }

  }, [zs_getCategoryData])





  useEffect(() => {
    _handleFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLimit]);

  useEffect(() => {
    _handleFilter();
    setRenewData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewData]);

  if (!data) {
    return "Yükleniyor.";
  }
  const breadcrumbData = [
    {
      title: "Fiyatlar",
      link: "/prices",
    },
    {
      title: "Listele",
      link: null,
    },
  ];



  const _handleNewModal = () => {
    setShow(true);
  }

  const _handleStatusChange = async (index) => {

    try {
      const updatedData = [...contentData];
      const findItem = updatedData[index];
      updatedData[index].is_active = !updatedData[index].is_active;
      const response = await APIService.put("prices/update", findItem.id, { is_active: updatedData[index].is_active });
      console.log(response);
      _handleFilter();
      successNotification()
    } catch (err) {
      errorNotification(err.toString());
      console.log(err);
    }
  };

  const _handlePromotedChange = async (index) => {

    try {
      const updatedData = [...contentData];
      const findItem = updatedData[index];

      updatedData[index].is_promoted = !updatedData[index].is_promoted;
      await APIService.put("prices/update", findItem.id, { is_promoted: updatedData[index].is_promoted });
      await _handleFilter();
      successNotification()
    } catch (err) {
      console.log(err);
    }
  };



  const _handleDeleteTrigger = async (index) => {
    try {
      const updatedData = [...contentData];
      const findItem = updatedData[index];

      const acceptModal = await Swal.fire({
        title: 'Emin misin?',
        text: "Bu yapacağın işlem geri alınamaz.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet, silmek istiyorum!'
      });

      if (acceptModal.isConfirmed) {
        try {
          await APIService.deleteItem("prices/delete", findItem.id);
          // burada isteği at ve tamamla
          Swal.fire(
            'Başarılı!',
            'İlgili içerik silinmiştir.',
            'success'
          )
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata oluştu...',
            text: 'Something went wrong!',
          })
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      await _handleFilter();
    }
  }

  if (isLoading || !zs_getCategoryData) {
    return (
      <>Yükleniyor</>
    )
  }


  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        {showModal && (
          <EditPriceModal
            data={selectedData}
            closeModal={closeModal}
            categories={categoriesData}
            handleRenewData={handleRenewData}
          />
        )}
        {/* <NewsModal show={show} handleClose={handleClose} handleRenewData={handleRenewData} categories={categoriesData} /> */}
        <Beadcrumb
          data={breadcrumbData}
          title={"Fiyatlar"}
        ></Beadcrumb>
        <Row className="g-3 ">
          <Col md="12" xl="8">
            <Card className=" mb-3">
              <Card.Body>
                <h5>Filtreleme</h5>
                <hr />
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col md="2">
                      <Form.Label >Çapraz</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Çapraz"
                        value={formValues.product_name}
                        onChange={(e) =>
                          setFormValues({ ...formValues, product_name: e.target.value })
                        }
                      />
                    </Col>
                    <Col md="2">
                      <Form.Label >Ürün Adı</Form.Label>
                      <Form.Control
                        value={formValues.title}
                        onChange={(e) =>
                          setFormValues({ ...formValues, title: e.target.value })
                        }
                        type="text" placeholder="Ürün Adı" />
                    </Col>
                    <Col md="2">
                      <Form.Label >Meta Adı</Form.Label>
                      <Form.Control type="text" placeholder="Meta Adı"
                        value={formValues.meta_currency_name}
                        onChange={(e) =>
                          setFormValues({ ...formValues, meta_currency_name: e.target.value })
                        } />
                    </Col>
                    <Col md="3">
                      <Form.Label >Kategori</Form.Label>
                      <Select options={selectOptions}
                        value={
                          formValues.category ? (
                            selectOptions.find(
                              (option) => option.value === formValues.category
                            )
                          ) : null
                        }
                        onChange={(selectedOption) =>
                          setFormValues((prevFormValues) => ({
                            ...prevFormValues,
                            category: selectedOption
                          }))
                        }
                        isSearchable={false} placeholder="Kategori" />
                    </Col>

                    <Col md="12" className="mt-3">
                      <div className="d-flex justify-content-end">
                        <div className="mx-2">
                          <Button variant="light" onClick={resetForm} disabled={false} >
                            Temizle
                          </Button>
                        </div>
                        <Button variant="primary" onClick={_handleFilter} disabled={false}>
                          Filtrele
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
            <Card className="">
              <Card.Body>
                <PricesTable
                  rows={contentData}
                  options={{ hover: true, size: "sm", bordered: false }}
                  onStatusChange={_handleStatusChange}
                  onPromotedChange={_handlePromotedChange}
                  onDeleteTrigger={_handleDeleteTrigger}
                  handleStatusChange={_handleStatusChange}
                  isRenewData={isRenewData}
                ></PricesTable>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" xl="4">
            <div className="mb-3">
              <NewPriceSidebar handleCategories={handleCategories} showCategories={showCategories} _handleFilter={_handleFilter}></NewPriceSidebar>
            </div>
            <div className="mb-3">
              <CategoriesSidebar handleCategories={handleCategories} />
            </div>
          </Col>


        </Row>

        <Footer />
      </div>
    </React.Fragment >
  );
}
