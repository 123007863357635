import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Offcanvas, Table } from "react-bootstrap";
import timeHelper from "../utils/timeHelper"
import useCategoryStore from '../store/CategoryStore';
import useStore from '../store/ModalStore';


export default function AnalysisTable({
  rows,
  options,
  onStatusChange,
  onPromotedChange,
  onDeleteTrigger,
}) {

  const categories = useCategoryStore((state) => state.selectedData);

  const setShowModal = useStore((state) => state.setShowModal);
  const setSelectedData = useStore((state) => state.setSelectedData);

  const [offCanvasData, setOffCanvasData] = useState(false);

  const [showBottom, setShowBottom] = useState(false);
  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = () => setShowBottom(true);
  const handleEditClick = (rowData) => {
    setSelectedData(rowData);
    setShowModal(true);
  };






  const getCategory = (categoryId) => {
    return categories.find((item) => item.id === categoryId) || "-";
  };
  return (
    <>
      <Table
        bordered={options && options.bordered}
        borderless={true}
        size={options && options.size}
        hover={options && options.hover}
        className="mb-0"
      >
        <thead>
          <tr>
            <th></th>

            <th>İçerik</th>
            <th>Kategori</th>
            <th>Durum</th>
            <th>Öne Çıkan</th>
            <th>Tür</th>
            <th>Destek / Direnç</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => (
            <tr
              className={!item.is_active ? "table-warning" : ""}
              style={{ verticalAlign: "middle" }}
              key={index}
            >
              <td width={150}>
                {item.img_url && (
                  <img width={100} src={item.img_url} alt="Analysis" style={{ display: 'flex', margin: "auto" }} />
                )}
              </td>

              <td width={300}>
                <small className="text-muted">{timeHelper(item.createdAt)}</small> <br /><strong>{item.title}</strong> <br />
                <div className="text-truncate mt-1">
                  {item.meta_desc.substr(0, 70)}
                </div>
              </td>
              <td width={100}>
                <u style={{ textDecoration: '2px solid underline green' }}>
                  {getCategory(item.category_id).title}
                </u>
              </td>
              <td width={25}>
                {item.is_active ? (
                  <Badge bg="success">AKTİF </Badge>
                ) : (
                  <Badge bg="warning">PASİF</Badge>
                )}
              </td>
              <td width={100}>
                {item.is_promoted ? (
                  <Badge bg="primary">ÖNERİLEN <i className="ri-rocket-fill text-warning"></i></Badge>
                ) : (
                  <Badge bg="light">NORMAL</Badge>
                )}
              </td>
              <td>{item.type ? item.type : "-"}</td>
              <td width={200} className="text-center">
                {item.tp ? item.tp : "-"}
                <i className="ri-arrow-up-line text-success" style={{ verticalAlign: 'middle' }}></i>
                /
                {item.sl ? item.sl : "-"}
                <i className="ri-arrow-down-line text-danger" style={{ verticalAlign: 'middle' }}></i>
              </td>
              <td width={200} style={{ textAlign: "end" }}>
                <ButtonGroup aria-label="Basic example">
                  <Button variant="light" className="btn-icon" size="md" onClick={() => handleEditClick(item)}>
                    <i className="ri-edit-circle-line"></i>
                  </Button>
                  <Button variant="light" className="btn-icon" size="md" onClick={handleShowBottom} >
                    <i class="ri-bar-chart-line"></i>
                  </Button>
                  <Button
                    variant={item.is_active ? "success" : "warning"}
                    className="btn-icon"
                    size="md"
                    onClick={() => onStatusChange(index)}
                  >
                    <i
                      className={
                        item.is_active ? "ri-eye-off-line" : "ri-eye-line"
                      }
                    ></i>
                  </Button>
                  <Button
                    variant={item.is_promoted ? "primary" : "outline-warning"}
                    className="btn-icon"
                    size="md"
                    onClick={() => onPromotedChange(index)}
                  >
                    <i
                      className={
                        item.is_promoted ? "ri-rocket-line" : "ri-rocket-line"
                      }
                    ></i>
                  </Button>

                  <Button
                    variant="danger"
                    className="btn-icon"
                    size="md"
                    onClick={() => onDeleteTrigger(index)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
      <Offcanvas style={{ animation: '0.2s all' }} show={showBottom} onHide={handleCloseBottom} placement="bottom">
        <div className="container">
          <Offcanvas.Body>
            <div className="row">
              <div className="col-md-4">
                <h3>Analiz İstatistikleri</h3>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Eos voluptatum asperiores in architecto deserunt eligendi cupiditate similique.
                Ab et provident temporibus fugit minima consequatur commodi amet magni facilis, nulla harum!
              </div>
              <div className="col-md-4">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Eos voluptatum asperiores in architecto deserunt eligendi cupiditate similique.
                Ab et provident temporibus fugit minima consequatur commodi amet magni facilis, nulla harum!
              </div>
              <div className="col-md-4">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Eos voluptatum asperiores in architecto deserunt eligendi cupiditate similique.
                Ab et provident temporibus fugit minima consequatur commodi amet magni facilis, nulla harum!
              </div>
            </div>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </>
  );
}
