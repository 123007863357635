import React, { useEffect, useState } from 'react'
import { Card, Col, Placeholder, Row } from 'react-bootstrap'
import apiService from '../../service/api.service';

export default function RegisteredUserTotal() {

    const [data, setData] = useState(false);

    const getData = async () => {
        try {
            const result = await apiService.get("reports/total-users");
            console.log(result.data);
            setData(result.data);
        } catch (error) {
            console.log(error);
            setData(false);
        }
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <Card className="card-one">
            <Card.Body>
                <Row>
                    {!data ? (
                        <Col xs="12">
                            <Placeholder xs="6" animation="glow"></Placeholder>
                            <div>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs="7" />
                                    <Placeholder xs="4" />
                                    <Placeholder xs="6" />
                                </Placeholder>
                            </div>
                        </Col>
                    ) :
                        <>
                            <label class="fs-sm fw-medium mb-1 card-title">Toplam Kullanıcı</label>
                            <h3 class="card-value mb-1"><i class="ri-user-line"></i> {data}</h3>
                        </>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}
