import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import useUsersData from '../../store/UsersStore';
import useEditUserData from '../../store/EditUserStore';
import APIService from "../../service/api.service";
import UsersTable from "../../components/UsersTable"
import AddNewUser from "../../components/AddNewUser";
import UserLogs from "../../components/UserLogs";
import EditNewUser from "../../components/EditNewUser";

export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [isHandleNewUser, setIsHandleNewUser] = useState(false);
    const [logsData, setLogsData] = useState([]);


    const haveEditUser = useEditUserData((state) => state.selectedData);


    const zs_setUsersData = useUsersData((state) => state.setSelectedData);
    const zs_getUsersData = useUsersData((state) => state.selectedData);

    const handleNewUser = () => setIsHandleNewUser(!isHandleNewUser);

    const getUsersData = async () => {
        setIsLoading(true);
        try {
            const response = await APIService.getByQuery("admin");
            zs_setUsersData(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    const getUserLogs = async (id) => {
        try {
            const response = await APIService.getDetailById("admin/detail/logs", id);
            setLogsData(response.data);
        } catch (error) {
            console.log(error);
        }
    }



    useEffect(() => {
        getUsersData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const breadcrumbData = [
        {
            title: "Kullanıcılar",
            link: "/users",
        },
        {
            title: "Listele",
            link: null,
        },
    ];


    if (isLoading || !zs_getUsersData) {
        return (
            <>Yükleniyor</>
        )
    }


    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={"Kullanıcılar"}
                >

                </Beadcrumb>
                <Row className="g-3 ">
                    <Col md="12" lg="7" >
                        <Card>
                            <Card.Header>
                                Kullanıcılar
                            </Card.Header>
                            <Card.Body>
                                <UsersTable getUserLogs={getUserLogs}></UsersTable>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3">
                            <UserLogs logsData={logsData}></UserLogs>

                        </Card>
                    </Col>
                    <Col md="12" lg="5" >
                        <Card>
                            <div className="p-3 d-flex align-items-center justify-content-between">
                                <div>
                                    <h5 onClick={handleNewUser} style={{ marginBottom: 0, cursor: 'pointer' }}>Yeni Kullanıcı Oluştur</h5>
                                </div>
                                <div>
                                    <Button size="sm" variant={
                                        isHandleNewUser ? "primary" : "light"
                                    } onClick={handleNewUser}>
                                        {isHandleNewUser ? "Kapat" : "Aç"}
                                    </Button>
                                </div>
                            </div>
                            <Card.Body style={{
                                display: isHandleNewUser ? "block" : "none"
                            }}>
                                <AddNewUser></AddNewUser>
                            </Card.Body>
                        </Card>


                        {haveEditUser && <Card className="mt-3">
                            <div className="p-3 d-flex align-items-center justify-content-between">
                                <div>
                                    <h5 onClick={handleNewUser} style={{ marginBottom: 0, cursor: 'pointer' }}>Kullanıcıyı Düzenle</h5>
                                </div>

                            </div>
                            <Card.Body>
                                <EditNewUser></EditNewUser>
                            </Card.Body>
                        </Card>}
                    </Col>

                </Row>

                <Footer />
            </div>
        </React.Fragment >
    );
}
