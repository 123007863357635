import React, { useState } from "react";
import { Alert, Button, Card, Modal, Table } from "react-bootstrap";
import timeHelper from "../utils/timeHelper"

import ReactJson from 'react-json-view'

export default function UserLogs({ logsData }) {
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState([])


  const handleClose = () => {
    setModalData([]);
    setShow(false);
  };
  const handleShow = (data) => {
    setModalData(data);
    setShow(true);
  }

  if (logsData.length === 0) {
    return (
      <>
        <Card.Body style={{ padding: 15 }}>
          <Alert variant="primary" className="alert-solid d-flex align-items-center mb-0">
            <i className="ri-information-line"></i> Veri bulunamadı.
          </Alert>
        </Card.Body>
      </>
    )
  }




  return (
    <>
      <div className="p-3 d-flex align-items-center justify-content-between">
        <div>
          <h6 style={{ marginBottom: 0, cursor: 'pointer' }}>Kullanıcı Hareketleri</h6>
        </div>
      </div>
      <Card.Body style={{ padding: 15 }}>
        <Table className="mb-0">
          <thead>
            <tr>
              <th >Kullanıcı</th>
              <th >İşlem</th>
              <th >Tarih</th>
            </tr>
          </thead>
          <tbody>
            {logsData.map((item, index) => (
              <>
                <tr key={index}>
                  <td style={{ verticalAlign: 'middle' }}>{item.user}</td>
                  <td style={{ verticalAlign: 'middle' }}><code>{item.model}</code> - <code>{item.action}</code> işlemini gerçekleştirdi.</td>
                  <td style={{ verticalAlign: 'middle' }}>{timeHelper(item.created_at)}</td>
                  <td style={{ verticalAlign: 'middle' }}><Button onClick={() => { handleShow(item.data) }} size="sm" variant="light"><i class="ri-add-circle-line"></i></Button></td>
                </tr>
              </>

            )
            )}
          </tbody>
        </Table>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Aktivite Kayıtları</Modal.Title>
          </Modal.Header>
          <Modal.Body> <div>
            <ReactJson src={modalData[0]} theme="monokai" displayArrayKey={false} displayDataTypes={false} />
          </div>
          </Modal.Body>
        </Modal>

      </Card.Body>
    </>
  );
}
