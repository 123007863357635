import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, Card, Col, Form, Nav, Row, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import Select, { components } from "react-select";
import apiService from "../../service/api.service";
import axios from "axios";
import { errorNotification, successNotification } from "../../utils/notification";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import ImageUpload from "../../components/ImageUpload";
import Viewers from "./viewers";

export default function Index() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formValues, setFormValues] = useState({
        target: "",
    });

    const [firstImageURL, setFirstImageURL] = useState('');

    useEffect(() => {
    }, []);



    if (isLoading) {
        return "Yükleniyor";
    }



    const handleSubmit = async () => {
        try {

            const emptyFields = [];
            if (firstImageURL === null || firstImageURL === "") {
                emptyFields.push("Lütfen görsel seçiniz.");
            }
            if (formValues.target === "") {
                emptyFields.push("Lütfen yönlendirme seçiniz.");
            }


            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")}.`
                );
                return;
            }


            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu duyuru düzenlenecektir.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Yayınla!'
            });
            if (acceptModal.isConfirmed) {
                const generatedData = {
                    target: formValues.target,
                    img_url: firstImageURL
                }

                try {
                    console.log(generatedData);
                    const add = await apiService.post(`notification/new`, generatedData);
                    console.log(add);
                    navigate(`/popup`, { replace: true });
                } catch (e) {
                    console.log(e);
                    errorNotification(`Duyuru paylaşılırken bir hata oluştu!`)
                }



            }
        } catch (err) {
            console.log(err);

        } finally {
            console.log("form datası hazır.")
        }
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Pop-up Yönetimi", link: "/popup" },
                        { title: `Popup Düzenle`, link: null }
                    ]}
                    title={`Popup Düzenle`}
                />

                <Form onSubmit={(e) => e.preventDefault}>
                    <Row className="g-3 d-flex ">
                        <Col md="8">
                            <Card className="card-one">
                                <Card.Header>
                                    <Card.Title as="h6"> Yeni popup ekle</Card.Title>
                                    <Nav className="nav-icon nav-icon-sm ms-auto">
                                        <Nav.Link><i className="ri-refresh-line"></i></Nav.Link>
                                        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="justify-content-center">
                                        <Col md="12">
                                            <div className="mb-3 card">
                                                <div className="card-header fw-bold">Ana görsel</div>
                                                <div className="card-body">
                                                    <p>Bu ana görsel için geçerlidir.</p>
                                                    <ImageUpload
                                                        onUploadSuccess={(url) => setFirstImageURL(url)}
                                                        imgUrl={data.img_url}
                                                    />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md="12">
                                            <div className="mb-3">
                                                <Form.Label >Yönlendirme</Form.Label>
                                                <Form.Control placeholder="Yönlendirme adresi giriniz" value={formValues.target} onChange={(e) => {
                                                    setFormValues({ ...formValues, target: e.target.value })
                                                }} />

                                            </div>
                                        </Col>


                                    </Row>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-end">
                                    <Button variant="success" onClick={handleSubmit}>Pop-up'ı Düzenle</Button>
                                </Card.Footer>
                            </Card>
                        </Col>


                    </Row>
                </Form>
                <Footer />
            </div>
        </React.Fragment >
    );
}
