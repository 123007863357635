import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import useBannersCategoryDetailData from '../../store/BannersCategoryDetailStore';
import APIService from "../../service/api.service";
import { Link, useParams } from "react-router-dom";
import timeHelper from "../../utils/timeHelper";
import AddNewBanner from "../../components/Banners/AddNewBanner";
import Swal from "sweetalert2";
import apiService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";

export default function Index() {
    const { itemid } = useParams();
    const [isLoading, setIsLoading] = useState(false);


    const [category, setCategory] = useState(null);
    const [editItem, setEditItem] = useState(false);

    const setBannerCategoryDetailData = useBannersCategoryDetailData((state) => state.setSelectedData);
    const bannerCategoryDetailData = useBannersCategoryDetailData((state) => state.selectedData);


    const getCategoryData = async () => {
        setIsLoading(true);
        try {
            const response = await APIService.getDetailById("banners-category/detail", itemid);
            setCategory(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleDelete = async (item) => {
        try {
            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu yapacağın işlem geri alınamaz.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Evet, silmek istiyorum!'
            });

            if (acceptModal.isConfirmed === true) {
                try {
                    await apiService.deleteItem("banners/delete", item.id);
                    // burada isteği at ve tamamla
                    Swal.fire(
                        'Başarılı!',
                        'İlgili içerik silinmiştir.',
                        'success'
                    )
                    const response = await APIService.getDetailById("banners/by-id", itemid);
                    setBannerCategoryDetailData(response.data);
                    successNotification("İlgili içerik silinmiştir.");
                } catch (e) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Bir hata oluştu...',
                        text: 'Something went wrong!',
                    })
                }
                const response = await APIService.getDetailById("banners/by-id", itemid);
                setBannerCategoryDetailData(response.data);
            }

        } catch (err) {
            errorNotification("Bir hata oluştu.");
        }
    }
    const handleChange = async (index, status) => {
        try {
            const newData = [...bannerCategoryDetailData];
            await apiService.put("banners/update", newData[index].id, { is_active: !status });
            newData[index].is_active = !status;
            setBannerCategoryDetailData(newData);
            console.log(newData[index].is_active)
            const response = await APIService.getDetailById("banners/by-id", itemid);
            setBannerCategoryDetailData(response.data);
            successNotification("Banner durumu güncellendi.")
        } catch (e) {
            console.log(e);
            errorNotification("Bir hata oluştu.");
        }
    }

    const getCategoryDetail = async () => {
        setIsLoading(true);
        try {
            const response = await APIService.getDetailById("banners/by-id", itemid);
            setBannerCategoryDetailData(response.data);
        } catch (error) {
            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        getCategoryDetail();
        getCategoryData();
    }, []);



    const breadcrumbData = [
        {
            title: "Banners",
            link: "/banners",
        },
        {
            title: "Kategoriler",
            link: "/banners",
        },
        {
            title: `Detay`,
            link: null,
        },
    ];


    if (isLoading || category === null) {
        return (
            <>Yükleniyor</>
        )
    }



    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={breadcrumbData}
                    title={`${category.title} bannerları`}
                >

                </Beadcrumb>
                <Row className="mb-4">
                    <Col md="8" xl="8" sm="12">
                        <Card className="card-one">
                            <Card.Header style={{ display: 'flex', alignItems: 'center' }} >
                                <div style={{ marginRight: 5 }}>
                                    {category.is_active ? <i class="ri-checkbox-blank-circle-fill text-success"></i> : <i class="ri-checkbox-blank-circle-line"></i>}
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <strong>{category.title}</strong>
                                    </div>
                                    <div>
                                        {timeHelper(category.createdAt)}
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <p style={{ margin: 0 }}>{category.description}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" xl="12" sm="12">
                        <Row>
                            <Col md="3" xl="3" sm="12" className="mb-3">
                                <AddNewBanner category={category}></AddNewBanner>
                            </Col>
                            {bannerCategoryDetailData?.map((item, index) => (
                                <Col md="3" xl="3" sm="12" className="mb-3">
                                    <Card className="card-one ">
                                        <Card.Body>
                                            <div className="mb-2 d-flex justify-content-between">
                                                <div>
                                                    <strong>{item.title}</strong>
                                                </div>
                                                <div>
                                                    <Form.Check type="switch"
                                                        checked={item.is_active}
                                                        onChange={() => handleChange(index, item.is_active)}
                                                    />
                                                </div>
                                            </div>
                                            <Card.Text>
                                                <Link to={item.img_url} target="_blank"> <img style={{ borderRadius: 5 }} src={item.img_url} className="img-fluid" alt="" /></Link>
                                            </Card.Text>
                                            <div className="d-flex justify-content-end">
                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="btn-icon mx-2"
                                                    onClick={() => {
                                                        handleDelete(item);
                                                    }}>
                                                    <i class="ri-delete-bin-line"></i>
                                                </Button>
                                                <Button variant="light" size="sm" className="btn-icon"><i className="ri-pencil-fill"></i></Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}

                        </Row>
                    </Col>
                </Row>
                <Footer />
            </div >
        </React.Fragment >
    );
}
