import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import { errorNotification } from "../../utils/notification";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ImageUpload from "../../components/ImageUpload";

export default function Index() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [formValues, setFormValues] = useState({
        title: "",
        short_description: "",
        url: "",
    });

    const [firstImageURL, setFirstImageURL] = useState('');
    const [sendNotification, setSendNotification] = useState(false);

    useEffect(() => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const locale = 'tr-TR';
        const currentDate = new Date().toLocaleDateString(locale, options);

        setFormValues({ ...formValues, title: `${currentDate} - Günlük Bülten` })
    }, []);



    if (isLoading) {
        return "Yükleniyor";
    }



    const handleSubmit = async () => {
        try {

            const emptyFields = [];
            if (firstImageURL === null || firstImageURL === "") {
                emptyFields.push("Lütfen bülten seçiniz.");
            }
            if (formValues.title === "") {
                emptyFields.push("Lütfen başlık giriniz.");
            }
            if (formValues.short_description === "") {
                emptyFields.push("Lütfen kısa açıklama giriniz.");
            }
            if (formValues.short_description.length < 10) {
                emptyFields.push("Açıklama en az 10 karakter olmalıdır.");
            }



            if (emptyFields.length > 0) {
                errorNotification(
                    `\n${emptyFields.join("\n")}.`
                );
                return;
            }


            const acceptModal = await Swal.fire({
                title: 'Emin misin?',
                text: "Bu bülten yayınlanacaktır.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Vazgeç',
                confirmButtonText: 'Yayınla!'
            });
            if (acceptModal.isConfirmed) {
                const generatedData = {
                    title: formValues.title,
                    url: firstImageURL,
                    short_description: formValues.short_description,
                    notificate: sendNotification
                }

                try {
                    await apiService.post(`bulletins/new`, generatedData);
                    navigate(`/bulletins`, { replace: true });
                } catch (e) {
                    console.log(e);
                    errorNotification(`Bülten paylaşılırken bir hata oluştu!`)
                }



            }
        } catch (err) {
            console.log(err);

        } finally {
            console.log("form datası hazır.")
        }
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Bülten Yönetimi", link: "/popup" },
                        { title: `Yeni Bülten Oluştur`, link: null }
                    ]}
                    title={`Yeni Bülten Oluştur`}
                />

                <Form onSubmit={(e) => e.preventDefault}>
                    <Row className="g-3 d-flex ">
                        <Col md="7">
                            <Card className="card-one">
                                <Card.Header>
                                    <Card.Title as="h6"> Yeni bülten ekle</Card.Title>
                                    <Nav className="nav-icon nav-icon-sm ms-auto">
                                        <Nav.Link><i className="ri-refresh-line"></i></Nav.Link>
                                        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                    </Nav>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="justify-content-center">
                                        <Col md="6">
                                            <div className="mb-3 card">
                                                <div className="card-header fw-bold">Bülten Yükle</div>
                                                <div className="card-body">
                                                    <p>PDF formatında olmak zorundadır.</p>
                                                    <ImageUpload
                                                        onUploadSuccess={(url) => setFirstImageURL(url)}
                                                        imgUrl={data.img_url}
                                                        isPdf={true}
                                                    />
                                                    {firstImageURL && (
                                                        <div className="mt-3">
                                                            <Form.Label >Bülten URL <Link style={{ fontWeight: 'bold' }} target="_blank" to={firstImageURL}>Görüntüle</Link> </Form.Label>
                                                            <Form.Control readOnly={true} disabled value={firstImageURL} />

                                                        </div>
                                                    )}

                                                </div>

                                            </div>
                                        </Col>


                                        <Col>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md="6">
                                            <div className="mb-3">
                                                <Form.Label >Başlık</Form.Label>
                                                <Form.Control placeholder="Başlık giriniz" value={formValues.title} onChange={(e) => {
                                                    setFormValues({ ...formValues, title: e.target.value })
                                                }} />

                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="mb-3">
                                                <Form.Label >Kısa Açıklama ( <strong>{formValues.short_description.length}</strong> / 254 )</Form.Label>
                                                <Form.Control as="textarea" rows={2} maxLength={254} placeholder="Kısa Açıklama" value={formValues.short_description} onChange={(e) => {
                                                    setFormValues({ ...formValues, short_description: e.target.value })
                                                }} />

                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <Form.Check type="switch"
                                                className="mb-2"
                                                checked={sendNotification}
                                                onChange={(e) => {
                                                    setSendNotification(!sendNotification)
                                                }}
                                                label="Bildirim göndermek istiyorum" />
                                        </Col>



                                    </Row>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-end">
                                    <Button variant="success" onClick={handleSubmit} disabled={!firstImageURL}>Bülteni Yükle</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col md="5">
                            <Card className="card-one">
                                <Card.Header>
                                    <Card.Title as="h6"> Önizleme</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <iframe width="100%" height="100%" src={firstImageURL} frameborder="0"></iframe>
                                </Card.Body>

                            </Card>
                        </Col>


                    </Row>
                </Form>
                <Footer />
            </div>
        </React.Fragment >
    );
}
