import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, ButtonGroup, Card, Col, Placeholder, Row, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import APIService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import Swal from 'sweetalert2'
import NewsModal from "../../components/NewsModal.jsx";
import EditNewsModal from "../../components/EditNewsModal";
import useStore from '../../store/ModalStore';
import NewsTable from "../../components/NewsTable";
import NewsCategorySidebar from "../../components/NewsCategorySidebar";
import useCategoryData from "../../store/CategoryStore";


export default function Index() {
  const [data, setData] = useState(null);
  const [contentData, setContentData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [renewData, setRenewData] = useState(false);
  const [categoriesData, setCategoriesData] = useState(false);
  const handleClose = () => setShow(false);
  const handleRenewData = () => setRenewData(true);
  const showModal = useStore((state) => state.showModal);
  const selectedData = useStore((state) => state.selectedData);
  const setShowModal = useStore((state) => state.setShowModal);

  const zs_setCategoryData = useCategoryData((state) => state.setSelectedData);
  const zs_getCategoryData = useCategoryData((state) => state.selectedData);


  const [isLoading, setIsLoading] = useState(true);


  // Kategori Yönetimi
  const [categoryPage, setCategoryPage] = useState(false);
  const [isAllDone, setIsAllDone] = useState(false);

  const closeModal = () => {
    setShowModal(false); // Zustand üzerinden modal'ı kapatın
  };


  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await APIService.getByLimit("news", currentPage, null); // endpoint'i burada belirtin
      setData(response);
      setContentData(response.data.data)
      setIsLoading(false);


    } catch (error) {
      console.log(error);
      setIsLoading(true);
      errorNotification("\n Bir hata oluştu")

    } finally {
      setIsLoading(false);
    }
  };


  const fetchCategories = async () => {
    try {
      const response = await APIService.get("news-category");
      setCategoriesData(response)
      zs_setCategoryData(response.data.data);
    } catch (err) {
      errorNotification(
        "\n" + err.toString() + ": Categories could not be fetched"
      );
    }
  };


  useEffect(() => {
    setIsAllDone(false);
    fetchData();
    fetchCategories();
    setIsAllDone(true);

    return () => {
      console.log("data loaded successfully")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    fetchData();
    setRenewData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewData]);

  if (!data || isAllDone === false) {
    return "Yükleniyor.";
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageButtons = () => {
    const buttons = [];
    const totalPages = data.data.totalPages;
    const buffer = 2; // Seçili olan sayfanın etrafında kaç sayfa göstermek istediğinizi belirtir.

    let previousWasDots = false;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= currentPage - buffer && i <= currentPage + buffer)) {
        buttons.push(
          <Button key={i} onClick={() => handlePageClick(i)} active={currentPage === i} type="button" variant="primary">
            {i}
          </Button>
        );
        previousWasDots = false;
      } else if (!previousWasDots) {
        buttons.push(
          <Button key={i} type="button" variant="primary">
            ...
          </Button>
        );
        previousWasDots = true;
      }
    }
    return buttons;
  };
  const breadcrumbData = [
    {
      title: "Haberler",
      link: "/news",
    },
    {
      title: "Listele",
      link: null,
    },
  ];


  const _handleNewModal = () => {
    setShow(true);
  }

  const _handleStatusChange = async (index) => {

    try {
      const updatedData = [...contentData];
      const findItem = updatedData[index];
      updatedData[index].is_active = !updatedData[index].is_active;
      const response = await APIService.put("analyses/update", findItem.id, { is_active: updatedData[index].is_active });
      console.log(response);
      setContentData(updatedData);
      successNotification()
    } catch (err) {
      errorNotification(err.toString());
      console.log(err);
    }
  };

  const _handleBannerShowChange = async (index) => {

    try {
      const updatedData = [...contentData];
      const findItem = updatedData[index];
      updatedData[index].show_banner = !updatedData[index].show_banner;
      await APIService.put("news/update", findItem.id, { show_banner: updatedData[index].show_banner });
      await fetchData();
      successNotification()
    } catch (err) {
      console.log(err);
    }
  };

  const _handleIsActiveNews = async (index) => {

    try {
      const updatedData = [...contentData];
      const findItem = updatedData[index];
      updatedData[index].is_active = !updatedData[index].is_active;
      await APIService.put("news/update", findItem.id, { is_active: updatedData[index].is_active });
      await fetchData();
      successNotification()
    } catch (err) {
      console.log(err);
    }
  };


  const _handleDeleteTrigger = async (index) => {
    try {
      const updatedData = [...contentData];
      const findItem = updatedData[index];

      const acceptModal = await Swal.fire({
        title: 'Emin misin?',
        text: "Bu yapacağın işlem geri alınamaz.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Vazgeç',
        confirmButtonText: 'Evet, silmek istiyorum!'
      });

      if (acceptModal.isConfirmed) {
        try {
          await APIService.deleteItem("news/delete", findItem.id);
          // burada isteği at ve tamamla
          Swal.fire(
            'Başarılı!',
            'İlgili içerik silinmiştir.',
            'success'
          )
        } catch (e) {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata oluştu...',
            text: 'Something went wrong!',
          })
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchData();
    }
  }

  if (isLoading) {
    return (
      <React.Fragment>
        <Header />
        <div className="main main-app p-3 p-lg-4">

          <Beadcrumb
            data={breadcrumbData}
            title={"Analizleri Listele"}
          ></Beadcrumb>
          <Row className="g-3 ">
            <Col md="12" xl="12">
              <Card className="">
                <Row className="p-4 g-3 ">
                  <Col md="6" style={{ textAlign: 'left', alignItems: 'center' }} >

                  </Col>
                  <Col md="6" style={{ textAlign: 'end', alignItems: 'center' }} >
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs="4" />
                    </Placeholder>

                  </Col>
                </Row>
                <Card.Body>
                  <Table

                    className="mb-0"
                  >
                    <thead>
                      <tr>
                        <th>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </th>
                        <th>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="12" />
                          </Placeholder>
                        </th>
                        <th>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="12" />
                          </Placeholder>
                        </th>
                        <th>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="12" />
                          </Placeholder>
                        </th>
                        <th>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </th>
                        <th>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </th>
                        <th>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{ verticalAlign: "middle" }}
                      >
                        <td>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </td>

                        <td >
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </td>
                        <td >
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </td>
                        <td >
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>

                        </td>
                        <td >
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>

                        </td>
                        <td>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </td>
                        <td>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>

                        </td>
                        <td style={{ textAlign: "end" }}>
                          <Placeholder as="div" animation="glow">
                            <Placeholder xs="4" />
                          </Placeholder>
                        </td>
                      </tr>
                    </tbody>
                  </Table >
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Footer />
        </div>
      </React.Fragment >
    )
  }


  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        {showModal && (
          <EditNewsModal
            data={selectedData}
            closeModal={closeModal}
            categories={zs_getCategoryData}
            handleRenewData={handleRenewData}
          />
        )}
        <NewsModal show={show} handleClose={handleClose} handleRenewData={handleRenewData} categories={zs_getCategoryData} />
        <Beadcrumb
          data={breadcrumbData}
          title={"Haberleri Listele"}
        ></Beadcrumb>
        <Row className="g-3 ">
          <Col md="12" xl="12">
            <Card className="card-one">
              <Row className="p-4 g-3 ">
                <Col md="6" style={{ textAlign: 'left', alignItems: 'center' }} >
                  <Button variant="primary" onClick={() => _handleNewModal()}>Yeni Haber Ekle</Button>
                </Col>
                <Col md="6" style={{ textAlign: 'end', alignItems: 'center' }} >
                  <ButtonGroup className="me-2" aria-label="First group">
                    {renderPageButtons()}
                  </ButtonGroup>

                </Col>
              </Row>
              <Card.Body>
                <NewsTable
                  rows={contentData}
                  options={{ hover: true, size: "xs", bordered: false }}
                  onStatusChange={_handleStatusChange}
                  showBannerChange={_handleBannerShowChange}
                  onDeleteTrigger={_handleDeleteTrigger}
                  _handleIsActiveNews={_handleIsActiveNews}
                  categories={zs_getCategoryData}
                ></NewsTable>
              </Card.Body>
            </Card>
          </Col>
          <Col md="12" xl="12">
            <NewsCategorySidebar fetchData={fetchData} categories={zs_getCategoryData} ></NewsCategorySidebar>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment >
  );
}
