import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import CategoriesSidebarSingle from './CategoriesSidebarSingle';
import CategoriesSidebarSingleNew from './CategoriesSidebarSingleNew';
import usePriceCategoryStore from '../store/CategoryStore';

export default function CategoriesSidebar() {

    const categories = usePriceCategoryStore((state) => state.selectedData);
    // Yeni oluştur sidebar ? 
    const [createNew, setCreateNew] = useState(false);
    const [editCategory, setEditCategory] = useState(false);

    const [showAll, setShowAll] = useState(false);

    const handleCreateNew = () => setCreateNew(!createNew);
    const handleEditCategory = () => setEditCategory(!editCategory);
    // const handleCancelNew = () => setCreateNew(false);
    const handleShowAll = () => setShowAll(!showAll);


    return (
        <>
            <Card className=" mb-3 p-3" >
                <div className='d-flex justify-content-between align-items-center'>
                    <h5 style={{ marginBottom: 0 }}>Kategoriler</h5>
                    <div>
                        {!showAll ? <Button variant='primary' style={{ marginRight: 10 }} size='sm' onClick={handleShowAll}>
                            <i className="ri-eye-fill"></i>
                        </Button> : <Button variant='danger' style={{ marginRight: 10 }} size='sm' onClick={handleShowAll}>
                            <i className="ri-eye-off-fill"></i>
                        </Button>}

                        {!createNew ? <Button variant='success' size='sm' onClick={handleCreateNew}>
                            <i className="ri-add-fill"></i>
                        </Button>
                            : <Button variant='danger' size='sm' onClick={handleCreateNew}>
                                Vazgeç
                            </Button>
                        }

                    </div>
                </div>

                <Card.Body className='mt-3' style={!showAll ? { display: 'none', transition: '.2s all' } : { display: "inline", transition: '.2s all', padding: 0, marginTop: 10 }} >
                    {createNew &&
                        <div className="">
                            <CategoriesSidebarSingleNew categories={categories} handleCreateNew={handleCreateNew} />
                        </div >
                    }

                    {categories.map((item, index) => (
                        <CategoriesSidebarSingle item={item} key={index} handleEditCategory={handleEditCategory} />
                    ))}
                </Card.Body>


            </Card>

        </>
    )
}
