import React, { useEffect } from 'react'
import { Card, Nav } from 'react-bootstrap';
import Avatar from '../../Avatar';
import timeHelper from "../../../utils/timeHelper";
import { useNavigate } from 'react-router-dom';


export default function CustomerGroupsCard({
    data
}) {
    const navigate = useNavigate();

    useEffect(() => {
        console.log(data);
    }, []);

    const navigateToEdit = () => navigate(`/customers-groups/edit/${data.id}`)
    return (
        <Card className="card-task" key={"index"} onClick={navigateToEdit}>
            <Card.Body className="p-3 pb-1">
                <div className="d-flex flex-row-reverse align-items-center justify-content-between mt-2 mb-1">
                    <span className="card-date">{timeHelper(data.createdAt)}</span>
                    <Card.Title as="h6">{data.title}</Card.Title>
                </div>
                <div className="mb-3"></div>
                {data.meta_desc && <p className="fs-sm">{data.meta_desc}</p>}

            </Card.Body>
            <Card.Footer className="p-3 pt-0">
                <div className="mutual-badge text-truncate" style={{ width: "45%" }}>
                    {data.users.length > 0 && <small>{data?.users[0]?.fullname},{data?.users[1]?.fullname}</small>}
                </div>
                <div className="mutual-badge">
                    <small>Toplam <strong>{data.users.length}</strong> kişi</small>
                </div>
            </Card.Footer>
        </Card>
    )
}
