export default function timeHelper(date) {
  const dateFormat = new Date(date);
  return dateFormat.toLocaleString("tr-TR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    timeZone: "Europe/Istanbul",
  });
}
