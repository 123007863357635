import React, { useState } from 'react'
import useCustomerData from '../../store/CustomerStore';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { errorNotification, successNotification } from '../../utils/notification';
import apiService from '../../service/api.service';



export default function UserSettings() {

    const zs_setCustomerData = useCustomerData((state) => state.setSelectedData);
    const zs_getCustomerData = useCustomerData((state) => state.selectedData);

    const [isActive, setIsActive] = useState(false);


    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        password: "",
        repassword: ""
    });


    const _handleNewPassword = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {

            const emptyFields = [];

            if (formValues.password === "") {
                emptyFields.push("Şifre alanını doldurunuz");
            }

            if (formValues.repassword === "") {
                emptyFields.push("Şifre Tekrar alanını doldurunuz");
            }

            if (formValues.repassword !== formValues.password) {
                emptyFields.push("Şifreler aynı değil.");
            }

            if (emptyFields.length > 0) {
                return errorNotification(
                    `\n${emptyFields.join("\n")}`
                );
            }

            await apiService.put("user/update-password-by-admin", zs_getCustomerData.uuid, formValues);
            setFormValues({
                password: "",
                repassword: ""
            });
            successNotification("Kullanıcı şifresi başarıyla değiştirildi.");
        } catch (err) {
            console.log(err);
            errorNotification(err.response.data.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Card className="mt-3">
            <Card.Header className='d-flex justify-content-between align-items-center'>
                <div>
                    Şifre Güncelleme
                </div>
                <div className="">
                    <Button variant="light" size='sm' onClick={() => setIsActive(!isActive)}>
                        {isActive ? "Gizle" : "Göster"}
                    </Button>
                </div>
            </Card.Header>
            <Card.Body
                style={{
                    display: isActive ? "block" : "none"
                }}
            >
                <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-3">
                            <Form.Label>Şifre</Form.Label>
                            <Form.Control type="text" placeholder="Şifre giriniz"
                                value={formValues.password}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, password: e.target.value })
                                } />
                        </div>
                        <div className="mb-3">
                            <Form.Label>Şifre Tekrarı</Form.Label>
                            <Form.Control type="text" placeholder="Yeni şifreyi tekrar giriniz"
                                value={formValues.repassword}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, repassword: e.target.value })
                                } />
                        </div>
                        <div className="d-flex justify-content-end">
                            {isLoading && <Button variant="light" disabled={true} className="mx-2"> Gönderiliyor..</Button>}
                            {!isLoading && <Button variant="primary" onClick={_handleNewPassword} className="mx-2"> Şifreyi Güncelle</Button>}
                        </div>
                    </form>
                </div>
            </Card.Body>
        </Card >
    )
}
