import React, { useState } from 'react'
import useCustomerData from '../../store/CustomerStore';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { errorNotification, successNotification } from '../../utils/notification';
import apiService from '../../service/api.service';



export default function UserDetailSettings() {

    const zs_setCustomerData = useCustomerData((state) => state.setSelectedData);
    const zs_getCustomerData = useCustomerData((state) => state.selectedData);

    const [isLoading, setIsLoading] = useState(false);
    const [handleEdit, setHandleEdit] = useState(false);

    const [formValues, setFormValues] = useState({
        fullname: zs_getCustomerData.fullname,
        email: zs_getCustomerData.email,
        phone: zs_getCustomerData.phone,
        country_code: zs_getCustomerData.country_code,
    });

    const _handleEditUserDetails = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {

            const emptyFields = [];

            if (formValues.fullname === "") {
                emptyFields.push("Adı ve Soyadı Doldurulmalıdır");
            }

            if (formValues.email === "") {
                emptyFields.push("E-Posta alanı doldurulmalıdır.");
            }

            if (formValues.phone === "") {
                emptyFields.push("Telefon numarası alanı doldurulmalıdır.");
            }

            if (formValues.country_code === "") {
                emptyFields.push("Alan kodu alanı doldurulmalıdır.");
            }


            if (emptyFields.length > 0) {
                return errorNotification(
                    `\n${emptyFields.join("\n")}`
                );
            }
            const userDetails = await apiService.put("user/update", zs_getCustomerData.uuid, formValues);

            setFormValues({
                fullname: userDetails.data.fullname,
                email: userDetails.data.email,
                phone: userDetails.data.phone,
                country_code: userDetails.data.country_code,
            });
            zs_setCustomerData(userDetails.data);
            successNotification("Kullanıcı bilgileri başarıyla güncellendi.");
        } catch (err) {
            console.log(err);
            errorNotification(err.response.data.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Card className="mt-3">
            <Card.Header className='d-flex justify-content-between align-items-center'>
                <div>
                    Kullanıcı Bilgileri
                </div>
                <div className="">
                    <Button variant="light" size='sm' onClick={() => {
                        if (handleEdit === true) {
                            setFormValues({
                                fullname: zs_getCustomerData.fullname,
                                email: zs_getCustomerData.email,
                                phone: zs_getCustomerData.phone,
                                country_code: zs_getCustomerData.country_code,
                            })
                        }
                        setHandleEdit(!handleEdit)
                    }}>
                        {handleEdit ? "Vazgeç" : "Düzenle"}
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>
                <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="mb-3">
                            <Form.Label>Adı Soyadı</Form.Label>
                            <Form.Control type="text" placeholder="Adı Soyadı"
                                value={formValues.fullname}
                                disabled={!handleEdit}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, fullname: e.target.value })
                                } />
                        </div>
                        <div className="mb-3">
                            <Form.Label>E-Posta Adresi</Form.Label>
                            <Form.Control type="text" placeholder="E-Posta Adresi"
                                value={formValues.email}
                                disabled={!handleEdit}
                                onChange={(e) =>
                                    setFormValues({ ...formValues, email: e.target.value })
                                } />
                        </div>
                        <div className="mb-3 ">
                            <Row>
                                <Col md="3">
                                    <Form.Label>Alan Kodu</Form.Label>
                                    <Form.Control type="text" placeholder="Ülke Kodu"
                                        value={formValues.country_code}
                                        disabled={!handleEdit}
                                        onChange={(e) =>
                                            setFormValues({ ...formValues, country_code: e.target.value })
                                        } />
                                </Col>
                                <Col md="9">
                                    <Form.Label>Telefon Numarası</Form.Label>
                                    <Form.Control type="text" placeholder="Telefon Numarası"
                                        value={formValues.phone}
                                        disabled={!handleEdit}
                                        onChange={(e) => {
                                            setFormValues({ ...formValues, phone: e.target.value })
                                        }
                                        } />
                                </Col>
                            </Row>
                        </div>
                        <div className="d-flex justify-content-end">
                            {isLoading && <Button variant="light" disabled={true} className="mx-2"> Gönderiliyor..</Button>}
                            {!isLoading && <Button variant="primary" disabled={!handleEdit} onClick={_handleEditUserDetails} className="mx-2"> Bilgileri Güncelle</Button>}
                        </div>
                    </form>
                </div>
            </Card.Body>
        </Card >
    )
}
