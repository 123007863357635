import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import useUsersData from '../store/UsersStore';
import { errorNotification, successNotification } from "../utils/notification";
import apiService from "../service/api.service";


export default function AddNewUser() {

  const zs_setUsersData = useUsersData((state) => state.setSelectedData);
  // const zs_getUsersData = useUsersData((state) => state.selectedData);


  const [formValues, setFormValues] = useState({
    fullname: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const _handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const emptyFields = [];
      const passwordError = [];

      if (formValues.fullname === "") {
        emptyFields.push("Adı ve Soyadı");
      }
      if (formValues.email === "") {
        emptyFields.push("E-Posta Adresi");
      }
      if (formValues.password === "") {
        emptyFields.push("Şifre alanı");
      }
      if (formValues.password_confirmation === "") {
        emptyFields.push("Şifre tekrar alanı");
      }


      if (formValues.password.length < 6 || formValues.password.length >= 24) {
        passwordError.push("Şifre Minimum 6, maksimum 25 karakter olmalıdır.");
      }
      if (formValues.password_confirmation !== formValues.password) {
        passwordError.push("Şifreler aynı değil.");
      }


      if (emptyFields.length > 0) {
        errorNotification(
          `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
        );
        return;
      }

      if (passwordError.length > 0) {
        errorNotification(
          `\n${passwordError.join("\n")}`
        );
        return;
      }

      const generatedData = {
        fullname: formValues.fullname,
        email: formValues.email,
        password: formValues.password,
      };


      await apiService.post("admin/register", generatedData);

      const getData = await apiService.getByQuery("admin");

      zs_setUsersData(getData.data);
      successNotification("Kullanıcı başarıyla eklendi.");
    } catch (err) {
      console.log(err.response.data.message);
      errorNotification(err.response.data.message)
    }
  }

  return (
    <>
      <Card className='mb-2' style={{ border: "none", borderBottom: '2px solid #fafafa' }}>
        <Card.Body style={{ padding: 5 }}>
          <Form className='mt-3 mb-3' onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col md="6" className="mb-3">
                <Form.Label >Adı Soyadı</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Adı ve Soyadı "
                  value={formValues.fullname}
                  onChange={(e) =>
                    setFormValues({ ...formValues, fullname: e.target.value })
                  }
                />
              </Col>
              <Col md="6" className="mb-3">
                <Form.Label >E-Posta Adresi</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="E-Posta Adresi "
                  value={formValues.email}
                  required
                  onChange={(e) =>
                    setFormValues({ ...formValues, email: e.target.value })
                  }
                />
              </Col>
              <Col md="6" className="mb-3">
                <Form.Label >Şifre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Şifre "
                  value={formValues.password}
                  onChange={(e) =>
                    setFormValues({ ...formValues, password: e.target.value })
                  }
                />
              </Col>
              <Col md="6" className="mb-3">
                <Form.Label >Şifre Tekrar</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Şifre tekrar "
                  value={formValues.password_confirmation}
                  onChange={(e) =>
                    setFormValues({ ...formValues, password_confirmation: e.target.value })
                  }
                />
              </Col>

              <Col md="12" className="mt-3">
                <div className="d-flex justify-content-end">
                  <Button variant="success" onClick={_handleSubmit} disabled={false}>
                    Kullanıcı Oluştur
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
