import { create } from "zustand";

const useCustomerData = create((set) => ({
  selectedData: null,
  renewData: false,
  setSelectedData: (data) => set(() => ({ selectedData: data })),
  setRenewData: (bool) => set(() => ({ renewData: bool })),
}));

export default useCustomerData;
