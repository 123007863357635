import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Badge, Button, ButtonGroup, Card, Col, Form, Modal, Row, Nav, Spinner, Table } from "react-bootstrap";
import Beadcrumb from "../../components/Beadcrumb";
import apiService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import Swal from "sweetalert2";
import CreateScheduledNotification from "./new";
import RenderTables from "./renderTables";

export default function Index() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);


    const groupByDay = (data) => {
        const groups = {};

        data.forEach(item => {
            // Tarihi okunabilir formata çevir
            const date = new Date(item.scheduledTime._seconds * 1000);
            const day = date.toLocaleDateString("tr-TR");

            // Eğer bu tarih için bir grup yoksa, oluştur
            if (!groups[day]) {
                groups[day] = [];
            }

            // Bu tarihe ait gruba öğeyi ekle
            groups[day].push(item);
        });

        return groups;
    };


    const fetchApi = async () => {
        setIsLoading(true);
        console.log("triggered fetch api");
        try {
            const response = await apiService.get(`firebase/scheduled-events`);
            const sortedData = response.data.sort((a, b) => {
                return a.scheduledTime._seconds - b.scheduledTime._seconds;
            });

            const groupedData = groupByDay(sortedData);
            console.log("Grouped data:", groupedData);
            setData(groupedData);
            console.log(groupedData, " grouped data");
        } catch (error) {
            console.error("Error fetching popups:", error);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchApi();
    }, []);



    // Tüm gönderilenleri sil
    const deleteAllSent = async () => {
        Swal.fire({
            title: 'Tüm gönderilen bildirimleri silmek istediğinize emin misiniz?',
            text: "Bu işlem geri alınamaz!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Evet, sil!',
            cancelButtonText: 'Hayır'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await apiService.deleteRequest(`firebase/delete-all-sent`);
                    console.log(response)
                    successNotification("Tüm gönderilen bildirimler başarıyla silindi");
                    await fetchApi();
                }
                catch (error) {
                    console.log(error)
                    errorNotification("Tüm gönderilen bildirimler silinirken bir hata oluştu");
                }
            }
        });
    }

    const deleteEventTrigger = async (id) => {

        Swal.fire({
            title: 'Etkinliği iptal etmek istediğinize emin misiniz?',
            text: "Bu işlem geri alınamaz!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Evet, iptal et!',
            cancelButtonText: 'Hayır'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await apiService.deleteRequest(`firebase/delete-event/${id}`);
                    console.log(response)
                    successNotification("Etkinlik başarıyla iptal edildi");
                    fetchApi();
                }
                catch (error) {
                    console.log(error)
                    errorNotification("Etkinlik iptal edilirken bir hata oluştu");
                }
            }
        })
    }

    if (isLoading) return "Yükleniyor";




    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Beadcrumb
                    data={[
                        { title: "Planlı Bildirimler", link: "/scheduled-notifications" },
                        { title: "Listele", link: null }
                    ]}
                    title={"Listele"}
                />

                <Row>
                    <Col md="8">
                        <Card className="p-0 card-one">
                            <Card.Header>
                                <Card.Title as="h6">Zamanlanmış Bildirimler</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link onClick={() => fetchApi()} className="text-primary px-2">Yenile</Nav.Link>
                                    <Nav.Link onClick={() => deleteAllSent()} className="text-danger">Tüm Gönderilenleri Sil</Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <RenderTables data={data} fetchApi={fetchApi} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="4">
                        <CreateScheduledNotification fetchApi={fetchApi}></CreateScheduledNotification >
                    </Col>
                </Row>


                <Footer />
            </div>
        </React.Fragment>
    );
}
