import React, { useEffect } from "react";
import { Badge, Button, ButtonGroup, Table } from "react-bootstrap";
import timeHelper from "../utils/timeHelper"
import useStore from '../store/ModalStore';
import useCategoryData from "../store/CategoryStore";


export default function NewsTable({
  rows,
  options,
  showBannerChange,
  onDeleteTrigger,
  _handleIsActiveNews
}) {

  const setShowModal = useStore((state) => state.setShowModal);
  const setSelectedData = useStore((state) => state.setSelectedData);

  const categories = useCategoryData((state) => state.selectedData);


  const handleEditClick = (rowData) => {
    setSelectedData(rowData); // Seçilen veriyi Zustand üzerinden güncelleyin
    setShowModal(true); // Zustand üzerinden modal'ı açın
  };

  const getCategory = (categoryId) => {
    return categories.find((item) => item.id === categoryId);
  };
  // eslint-disable-next-line default-case
  return (
    <>
      <Table
        bordered={options && options.bordered}
        borderless={true}
        size={options && options.size}
        hover={options && options.hover}
        className="mb-0"
      >
        <thead>
          <tr>
            <th></th>

            <th>İçerik</th>
            <th>Kategori</th>
            <th>Etiketler</th>
            <th>Banner?</th>
            <th>Oluşturma Tarihi</th>
            <th>Güncelleme Tarihi</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => (
            <tr
              style={{ verticalAlign: "middle" }}
              key={index}
            >
              <td>
                {item.img_url && (
                  <img width={45} src={item.img_url} alt="Analysis" />
                )}
              </td>

              <td>
                <strong>{item.title}</strong> <br /> {item.meta_desc ? item.meta_desc : "İçerik bulunamadı."}
              </td>
              <td>
                <u style={{ textDecoration: '2px solid underline green' }}>

                  {(item.category_id.title !== "undefined") ? getCategory(item.category_id).title : "-"}
                </u>
              </td>

              <td>{item.tags}</td>
              <td width={100}>
                {item.show_banner ? (
                  <Badge bg="primary">BANNER <i className="ri-rocket-fill text-warning"></i></Badge>
                ) : (
                  <Badge bg="light">NORMAL</Badge>
                )}
              </td>

              <td>{timeHelper(item.createdAt)}</td>
              <td>{timeHelper(item.updatedAt)}</td>
              <td style={{ textAlign: "end" }}>
                <ButtonGroup aria-label="Basic example">
                  <Button variant="light" className="btn-icon" size="sm" onClick={() => handleEditClick(item)}>
                    <i className="ri-edit-circle-line"></i>
                  </Button>
                  <Button
                    variant={item.is_active ? "primary" : "outline-primary"}
                    className="btn-icon"
                    size="sm"
                    onClick={() => _handleIsActiveNews(index)}
                  >
                    <i
                      className={
                        item.is_active ? "ri-eye-line" : "ri-eye-off-line"
                      }
                    ></i>
                  </Button>
                  <Button
                    variant={item.show_banner ? "primary" : "outline-primary"}
                    className="btn-icon"
                    size="sm"
                    onClick={() => showBannerChange(index)}
                  >
                    <i
                      className={
                        item.show_banner ? "ri-rocket-line" : "ri-rocket-line"
                      }
                    ></i>
                  </Button>

                  <Button
                    variant="danger"
                    className="btn-icon"
                    size="sm"
                    onClick={() => onDeleteTrigger(index)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >
    </>
  );
}
